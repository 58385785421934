import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Events from './Events';

const TOOLBAR_MIN_HEIGHT = 50;

class ToolbarControl extends React.Component {
  constructor(props) {
    super(props);
    this.toggleToolbar = this.toggleToolbar.bind(this);
    this.resizeToolbar = this.resizeToolbar.bind(this);
    this.onResizeEvents = this.onResizeEvents.bind(this);
    this.iconUp =
      '/lib/ckeditor/ckeditor_4.6.0_custom_plugins/fastformatHideButtons/icons/uparrow.png';
    this.iconDown =
      '/lib/ckeditor/ckeditor_4.6.0_custom_plugins/fastformatHideButtons/icons/downarrow.png';
    this.btnSelector = '.cke_button__fastformathidebuttons';
    this.editorDiv = null;
    this.topElement = null;
  }

  componentDidMount() {
    const { editor } = this.props;
    this.editorDiv = editor.editable().$.closest('.cke_inner');

    if (!this.editorDiv) {
      return;
    }

    this.topElement = this.editorDiv.querySelector('.cke_top');

    if (!this.topElement) {
      return;
    }

    editor.on(Events.HIDE_TOOLBAR_BUTTONS, this.toggleToolbar);
    editor.on('resize', this.onResizeEvents);

    // Start hidden.
    this.toggleToolbar();
  }

  onResizeEvents() {
    var hidden = this.topElement.getAttribute('data-hidden') === 'true';
    if (hidden) {
      this.resizeToolbar(true);
    } else {
      // When toolbar is not hidden, we do not need to care because
      // CKEditor handles it.
    }
  }

  toggleToolbar() {
    var hide = this.topElement.getAttribute('data-hidden') !== 'true';
    this.resizeToolbar(hide);
  }

  resizeToolbar(hide = true) {
    const { i18n } = this.props;
    const oldTopHeight = this.topElement.offsetHeight;
    const nodes = this.editorDiv.querySelectorAll('.cke_toolbar');

    if (!nodes) {
      return;
    }

    if (hide) {
      // Hide all first, then show one by one until the height limit is reached..
      for (let i = 0; i < nodes.length - 1; i++) {
        nodes[i].style.display = 'none';
      }
      // Skip the hide button itself (-1)
      for (let i = 0; i < nodes.length - 1; i++) {
        // Show one by one until the height limit is reached.
        nodes[i].style.display = '';
        // Revert the last if necessary
        if (this.topElement.offsetHeight > TOOLBAR_MIN_HEIGHT) {
          nodes[i].style.display = 'none';
          break;
        }
      }
    } else {
      for (let i = 0; i < nodes.length - 1; i++) {
        nodes[i].style.display = '';
      }
    }

    // Update the editor height.
    const newTopHeight = this.topElement.offsetHeight;
    var delta = oldTopHeight - newTopHeight;
    if (hide) {
      delta = Math.abs(delta);
    }
    const contentsElement = this.editorDiv.querySelector('.cke_contents');
    const currentHeight = parseFloat(contentsElement.style.height);
    contentsElement.style.height = currentHeight + delta + 'px';
    // End.

    // Update icon and label.
    const button = this.editorDiv.querySelector(this.btnSelector);
    if (button) {
      if (!hide) {
        button.setAttribute('title', i18n.t('Mostrar menos'));
        button.firstChild.style.backgroundImage = `url(${this.iconUp})`;
      } else {
        button.setAttribute('title', i18n.t('Mostrar mais'));
        button.firstChild.style.backgroundImage = `url(${this.iconDown})`;
      }
    }

    //Change hidden flag.
    this.topElement.setAttribute('data-hidden', hide);
  }

  render() {
    return null;
  }
}

ToolbarControl.propTypes = {
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(ToolbarControl);
