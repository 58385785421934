import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from "../../common/containers/Modal";
import URLS from "../../urls";
import Button from "../../common/components/Button";
import {withNamespaces} from "react-i18next";
import {AppContext} from "../../context/global";

class ModalPitch extends Component {
    render() {
        const {i18n} = this.props;
        return (
            <Modal
                show={this.props.show}
                onCancel={this.props.onClose}
                title={i18n.t('Adquira seu plano agora!')}
            >
                <div className="text-center">
                    <h3 className="text-info">
                        {this.context.page_limit === 0 && (
                            <span>
                                {i18n.t('Adquira um plano e aproveite todos os recursos!')}
                            </span>
                        )}
                    </h3>

                    <div className="row">
                        <div style={{textAlign: 'center'}} className="col-md-6">
                            <h5 style={{fontWeight: 'bold'}}>
                                <span className="fas fa-dollar-sign"/> {i18n.t('Economize dinheiro')}
                            </h5>
                            <p>
                                {i18n.t('Formatar seu documento com FastFormat é muito mais barato.')}
                            </p>
                        </div>
                        <div style={{textAlign: 'center'}} className="col-md-6">
                            <h5 style={{fontWeight: 'bold'}}>
                                <span className="fas fa-clock"/> {i18n.t('Ganhe tempo')}
                            </h5>
                            <p>
                                {i18n.t('Você economizará várias horas que seriam gastas com formatação de texto.')}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{textAlign: 'center'}} className="col-md-6">
                            <h5 style={{fontWeight: 'bold'}}>
                                <span className="fas fa-bullseye"/> {i18n.t('Foco no conteúdo')}
                            </h5>
                            <p>
                                {i18n.t('Tempo e energia que seria gasto na formatação são bem utilizados no conteúdo.')}
                            </p>
                        </div>
                        <div style={{textAlign: 'center'}} className="col-md-6">
                            <h5 style={{fontWeight: 'bold'}}>
                                <span className="fas fa-star"/> {i18n.t('Mais qualidade')}
                            </h5>
                            <p>
                                {i18n.t('Impressione pela a alta qualidade dos seus documentos.')}
                            </p>
                        </div>
                    </div>
                    <br/>
                    <h4>
                        {i18n.t('É muito mais barato do que perder tempo com normas de formatação complicadas!')}
                    </h4>
                    <br/>
                    <br/>
                    <a className="btn btn-lg btn-success"
                       href={URLS.plans} role="button">
                        {i18n.t('Escolher plano')}
                    </a>
                    &nbsp;
                    <Button type="default" size="lg" onClick={this.props.onClose}>
                        {i18n.t('Agora não')}
                    </Button>
                </div>
            </Modal>
        );
    }
}

ModalPitch.contextType = AppContext;

ModalPitch.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withNamespaces()(ModalPitch);
