import React from 'react';
import PropTypes from 'prop-types';
import truncate from "underscore.string/truncate";
import {withNamespaces} from "react-i18next";

class SelectedReferences extends React.Component {
    constructor(props) {
        super(props);
        this.onRemoveCitation = this.onRemoveCitation.bind(this);
    }

    onRemoveCitation(reference) {
        this.props.onRemoveCitation(reference);
    }

    renderCitations() {
        const {i18n} = this.props;
        const refs = Object.values(this.props.selectedReferences);
        function sortRefs(a, b) {
            if (a.author && b.author) {
                return a.author.localeCompare(b.author);
            }
            return 0;
        }
        return refs.sort(sortRefs).map(ref => {
            return (
                <div key={ref.id}
                     className="btn-group btn-group-sm"
                     style={{paddingBottom: '5px'}}>
                    <button type="button"
                            className="btn btn-default"
                            title={i18n.t('Remover essa citação')}
                            onClick={() => this.onRemoveCitation(ref)}>
                        <i className="icon mdi mdi-close" />
                    </button>
                    <button type="button"
                            className="btn btn-muted"
                            title={`${ref.author_etal}, (${ref.year}). ${ref.title}`}>
                        {truncate(ref.author_etal, 13)} ({ref.year})
                    </button>
                </div>
            );
        });
    }

    render() {
        const rowStyle = {
            // maxHeight: '70px',
            // overflowY: 'auto'
        };

        return (
            <div className="row ff-scrollbar" style={rowStyle}>
                <div className="col-md-12">
                    {this.renderCitations()}
                </div>
            </div>
        );
    }
}

SelectedReferences.propTypes = {
    document: PropTypes.object.isRequired,
    selectedReferences: PropTypes.object.isRequired,
    onRemoveCitation: PropTypes.func.isRequired,
};

export default withNamespaces()(SelectedReferences);