import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Waiting from "../../../common/containers/Waiting";
import Config from "../../../config";
import Ajax from "../../../common/ajax";
import LocaleUtils from "../../../common/LocaleUtils";
import Button from "../../../common/components/Button";
import {withNamespaces} from "react-i18next";
import Events from "../Events";
import truncate from "underscore.string/truncate";
import {REMOVE_MESSAGE} from "./Comment";

class ScrollableViewport extends Component {
    render() {
        return (
            <div
                style={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 92px)',
                    padding: '5px',
                }}
                className="ff-scrollbar"
            >
                {this.props.children}
            </div>
        );
    }
}

class CommentsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: true,
            comments: [],
            notFoundInTextID: null,
        };
        this.fetchComments = this.fetchComments.bind(this);
        this.revealCommentInEditor = this.revealCommentInEditor.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.remove = this.remove.bind(this);
        this.toggleResolved = this.toggleResolved.bind(this);
    }

    componentDidMount() {
        this.fetchComments();
        this.props.editor.on(Events.UPDATE_COMMENT_PANEL, this.fetchComments)
    }

    fetchComments() {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}comments/?document=${this.props.document.id}`;
        Ajax.get(url).done(comments => {
            this.setState(state => ({
                ...state,
                processing: false,
                comments: comments,
            }));
        });
    }

    /**
     * This method scrolls the editor content until this comment is visible in
     * the viewport.
     * @param comment A comment object.
     */
    revealCommentInEditor(comment) {
        const el = this.getCommentMarkElement(comment.id);
        if (el) {
            el.scrollIntoView();
        } else {
            this.setState(state => ({...state, notFoundInTextID: comment.id}));
        }
    }

    /**
     * This opens the modal to edit the comment content. In fact, the editing
     * operation is delegated to Comment.jsx component.
     * @param comment A comment object.
     */
    openEditModal(comment) {
        // Fires the editing event with the correspond comment widget as the
        // event data.
        Object.values(this.props.editor.widgets.instances).forEach(widget => {
            if (widget.definition.name === 'commentWidget' &&
                widget.element.getAttribute('id') == comment.id) {
                this.props.editor.fire(Events.EDIT_COMMENT, widget);
            }
        });
    }

    /**
     * This method mark a comment as resolved if it is not resolved, otherwise
     * it marks it as unresolved.
     * @param comment A comment object.
     */
    toggleResolved(comment) {
        this.setState(state => ({...state, processing: true}));
        const newValue = !comment.is_resolved;
        const url = `${Config.apiHost}comments/${comment.id}/`;
        Ajax.patch(url, {is_resolved: newValue}).done(() => {
            comment.is_resolved = newValue;
            this.setState(state => ({...state}));
        }).always(() => {
            this.setState(state => ({...state, processing: false}));
        });
    }

    /**
     * This method removes the comment from the serve, from the panel and from
     * the editor content.
     * @param comment A comment object.
     */
    remove(comment) {
        const {i18n} = this.props;
        const msg = i18n.t(REMOVE_MESSAGE);
        if (confirm(msg)) {
            this.setState(state => ({...state, processing: true}));
            const url = `${Config.apiHost}comments/${comment.id}`;
            Ajax.delete_(url).done(() => {
                const comments = this.state.comments
                    .filter(c => c.id !== comment.id);
                this.setState(state => ({...state, comments}));
                // Removes the comment Widget in the editor content.
                Object.values(this.props.editor.widgets.instances).forEach(w => {
                    if (w.definition.name === 'commentWidget' &&
                        w.element.getAttribute('id') == comment.id) {
                        this.props.editor.widgets.del(w);
                    }
                });
            }).always(() => {
                this.setState(state => ({...state, processing: false}));
            });
        }
    }

    getCommentMarkElement(id) {
        return document.querySelector('.ff-comment-mark[id="' + id + '"]');
    }

    render() {
        const {i18n, editor} = this.props;
        const panelStyle = {marginBottom: '10px'};
        const panelHeadStyle = {fontSize: '12px', margin: '5px', padding: '5px'};
        const panelBodyStyle = {padding: '5px', overflowX: 'auto'};
        const toolButtonStyle = {marginLeft: '7px'};
        const defaultPanelClass = 'panel panel-default';
        const resolvedPanelClass = 'panel panel-border-color panel-border-color-success';

        return (
            <Waiting isProcessing={this.state.processing}>
                <Button
                    block={true}
                    type="default"
                    onClick={() => editor.fire('fastformatToggleCommentPanel')}
                >
                    {i18n.t('Comentários')} <small className="text-muted">({i18n.t('clique para fechar')})</small>
                </Button>

                <ScrollableViewport>
                    {this.state.comments.map(comment => {
                        return (
                            <div
                                className={comment.is_resolved ?
                                    resolvedPanelClass : defaultPanelClass}
                                style={panelStyle}
                            >
                                <div
                                    className="panel-heading panel-heading-divider"
                                    style={panelHeadStyle}
                                >
                                    <i className="mdi mdi-account-o"/>
                                    {' '}
                                    {truncate(comment.user.email, 20)}

                                    <div className="tools dropdown">
                                        <span
                                            title={i18n.t('Exibir comentário no texto')}
                                            role="button"
                                            className="icon mdi mdi-pin-drop"
                                            onClick={() => this.revealCommentInEditor(comment)}
                                        />
                                        <span
                                            title={comment.is_resolved ?
                                                i18n.t('Marcar como não resolvido') :
                                                i18n.t('Marcar como resolvido')}
                                            role="button"
                                            className={`icon mdi ${comment.is_resolved ? 'mdi-alert-circle-o' : 'mdi-check'}`}
                                            onClick={() => this.toggleResolved(comment)}
                                            style={toolButtonStyle}
                                        />
                                        <span
                                            title={i18n.t('Editar comentário')}
                                            role="button"
                                            className="icon mdi mdi-edit"
                                            onClick={() => this.openEditModal(comment)}
                                            style={toolButtonStyle}
                                        />
                                        <span
                                            title={i18n.t('Remover comentário')}
                                            role="button"
                                            className="icon mdi mdi-delete"
                                            onClick={() => this.remove(comment)}
                                            style={toolButtonStyle}
                                        />
                                    </div>
                                    <span className="panel-subtitle">
                                        <i className="mdi mdi-time"/>
                                        {' '}
                                        {LocaleUtils.calendar(comment.last_modification_date)}
                                    </span>
                                </div>
                                <div className="panel-body" style={panelBodyStyle}>
                                    {comment.comment}
                                    {this.state.notFoundInTextID === comment.id && (
                                        <small className="text-danger">
                                            <br/>
                                            <i className="mdi mdi-alert-triangle"/>
                                            {' '}
                                            <i>
                                                {i18n.t('Não foi possível encontrar o local do comentário no texto.')}
                                            </i>
                                        </small>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </ScrollableViewport>
            </Waiting>
        );
    }
}

CommentsPanel.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
};

export default withNamespaces()(CommentsPanel);
