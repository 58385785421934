export default function configureToolbars(debug) {
  CKEDITOR.config.toolbar_FullV2 = [
    { name: 'document', items: ['FastFormatSave'] }, //,'Templates' ] },

    {
      name: 'basicstyles',
      items: ['FastformatFormat', 'Bold', 'Italic', 'Underline', 'Strike'],
    },

    {
      name: 'ImageTable',
      items: ['Image', 'Table', 'NumberedList', 'BulletedList'],
    },

    {
      name: 'citation',
      items: ['FastFormatCitation', 'Blockquote', 'FastFormatFootnote'],
    },

    {
      name: 'proofreading',
      items: ['FastFormatProofread'],
    },

    {
      name: 'commenting',
      items: ['fastformatCommentsMenuButton'],
    },

    {
      name: 'formula',
      items: [
        // 'Mathjax',
        'FastFormatFormula',
      ],
    },

    {
      name: 'code',
      items: [
        // 'CodeSnippet',
        'FastFormatCodeSnippet',
      ],
    },

    { name: 'pdf', items: ['FastFormatIncludePDF'] },

    {
      name: 'SpecialChar',
      items: ['SpecialChar'],
    },

    {
      name: 'indexes',
      items: ['Subscript', 'Superscript'],
    },

    {
      name: 'align',
      items: ['JustifyBlock', 'JustifyLeft', 'JustifyCenter', 'JustifyRight'],
    },

    { name: 'noindent', items: ['FastFormatNoIndent'] },

    { name: 'colors', items: ['TextColor'] },

    { name: 'fastformatOther', items: ['FastFormatModels'] },

    // {
    //   name: 'clipboard',
    //   items: ['Cut', 'Copy', 'Paste', 'PasteFromWord'],
    // },

    {
      name: 'undoredo',
      items: ['Undo', 'Redo'],
    },

    {
      name: 'finding',
      items: ['Find', 'Replace', 'RemoveFormat'], // 'SelectAll',
    },

    // {
    //   name: 'tools',
    //   items: ['Zoom', 'Maximize'],
    // },

    {
      name: 'hide',
      items: ['FastFormatHideButtons'],
    },
  ];

  CKEDITOR.config.toolbar_Full = [
    { name: 'document', items: ['FastFormatSave'] }, //,'Templates' ] },

    {
      name: 'clipboard',
      items: ['Cut', 'Copy', 'Paste', /*'PasteText',*/ 'PasteFromWord', 'Undo', 'Redo'],
    },
    {
      name: 'editing',
      items: ['Find', 'Replace', 'SelectAll', 'RemoveFormat'],
    },

    { name: 'fastformatOther', items: ['FastFormatModels'] },

    {
      name: 'proofreading',
      items: ['FastFormatProofread', 'fastformatCommentsMenuButton'],
    },

    {
      name: 'basicstyles',
      items: ['FastformatFormat', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'],
    },

    {
      name: 'align',
      items: ['JustifyBlock', 'JustifyLeft', 'JustifyCenter', 'JustifyRight'],
    },
    //{ name: 'links', items : [ 'Link','Unlink' ] },
    //'/',

    { name: 'noindent', items: ['FastFormatNoIndent'] },

    { name: 'colors', items: ['TextColor'] }, //,'BGColor'

    { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },

    { name: 'ImageTable', items: ['Image', 'Table', 'FastFormatIncludePDF'] },

    {
      name: 'citation',
      items: ['FastFormatCitation', 'Blockquote', 'FastFormatFootnote'],
    },

    {
      name: 'formula',
      items: [
        'SpecialChar',
        // 'Mathjax',
        'FastFormatFormula',
        'FastFormatCodeSnippet',
      ],
    },

    {
      name: 'tools',
      items: ['Zoom', 'Maximize'],
    },

    {
      name: 'hide',
      items: ['FastFormatHideButtons'],
    },
  ];

  CKEDITOR.config.toolbar_Basic = [
    {
      name: 'editing',
      items: ['Find', 'Replace', 'SelectAll', 'FastFormatProofread'],
    },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
    { name: 'specialchar', items: ['SpecialChar'] },
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'],
    },
    { name: 'align', items: ['JustifyCenter', 'JustifyRight'] },
    //{ name: 'links', items : [ 'Link','Unlink' ] },
    { name: 'colors', items: ['TextColor'] }, //,'BGColor'
    { name: 'citation', items: ['Blockquote'] },
    { name: 'table', items: ['Table'] },
  ];

  CKEDITOR.config.toolbar_VeryBasic = [
    {
      name: 'editing',
      items: ['Find', 'Replace', 'SelectAll', 'FastFormatProofread'],
    },
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'],
    },
    {
      name: 'insert',
      items: ['SpecialChar'],
    },
  ];

  CKEDITOR.config.toolbar_FootnoteToolbar = [
    {
      name: 'editing',
      items: ['Find', 'Replace', 'SelectAll', 'SpellChecker', 'FastFormatProofread'],
    },
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat'],
    },
    { name: 'citation', items: ['FastFormatCitation'] },
    { name: 'specialchar', items: ['SpecialChar', 'FastFormatFormula'] },
  ];

  CKEDITOR.config.toolbar_Symbol = [
    {
      name: 'insert',
      items: ['FastFormatFormula', 'SpecialChar', 'Subscript', 'Superscript'],
    },
  ];

  // if (debug) {
  //     CKEDITOR.config.toolbar_Full.push({name: 'debug', items: ['Source']});
  //     CKEDITOR.config.toolbar_Basic.push({name: 'debug', items: ['Source']});
  //     CKEDITOR.config.toolbar_VeryBasic.push({name: 'debug', items: ['Source']});
  //     CKEDITOR.config.toolbar_FootnoteToolbar.push({name: 'debug', items: ['Source']});
  // }
}
