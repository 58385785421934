import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import VisibilitySensor from 'react-visibility-sensor';
import debounce from 'lodash.debounce';

import './editor.previewer.css';
import a4paper from '../../../common/a4paper.png';

import PreviewerToolbar from './PreviewerToolbar';
import Waiting from '../../../common/containers/Waiting';

import { withNamespaces } from 'react-i18next';
import Events from '../Events';

class Previewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnails: [],
      tableOfContents: [],
      lastVisibleThumb: null,
      progress: 0,
      previewerCount: 0,
    };
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.fetchThumbs = this.fetchThumbs.bind(this);
    this.fetchTableOfContents = this.fetchTableOfContents.bind(this);
    this.scrollToLastVisibleThumb = this.scrollToLastVisibleThumb.bind(this);
    this.setCurrentPageBasedOnScrollPosition = debounce(this.setCurrentPageBasedOnScrollPosition.bind(this), 0);
    this.changePage = this.changePage.bind(this);
    this.scrollContainer = null;
    this.lastVisibleThumb = null;
    this.lastScrollTop = null;
    this.setPagesToolbar = null;
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchThumbs();
    this.fetchTableOfContents();

    this.props.editor.on(Events.PREVIEW, () => {
      this.fetchThumbs(true);
      this.fetchTableOfContents();
      this.setStateIfMounted((state) => ({ ...state, progress: 0 }));
    });

    this.props.editor.on(Events.COMPILATION_PROGRESS, (evt) => {
      if (evt.data && evt.data.progress && evt.data.progress > this.state.progress) {
        this.setStateIfMounted((s) => ({ ...s, progress: evt.data.progress }));
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setStateIfMounted(state, callback) {
    if (this._mounted) {
      this.setState(state, callback);
    }
  }

  fetchThumbs(incrementPreview = false) {
    if (!this._mounted) {
      return;
    }

    this.state.thumbnails.forEach((thumb) => {
      const element = document.getElementById(thumb.filename);
      element.setAttribute('src', a4paper);
    });

    const url = `${Config.apiHost}documents/${this.props.document.id}/thumbnails/`;
    Ajax.get(url).done((thumbnails) => {
      const isFirstPreview = this.state.thumbnails.length === 0;

      this.setStateIfMounted(
        (state) => ({
          ...state,
          thumbnails,
          lastVisibleThumb: this.lastVisibleThumb,
          previewerCount: incrementPreview ? state.previewerCount + 1 : state.previewerCount,
        }),
        () => {
          if (isFirstPreview) {
            this.changePage(0);
          }

          if (this.props.document.is_presentation && thumbnails && thumbnails.length > 1) {
            // Fix a bug with wide screen that do not load correctly the second slide.
            this.onVisibilityChange(true, thumbnails[1]);
          }

          this.scrollToLastVisibleThumb();
        },
      );
    });
  }

  fetchTableOfContents() {
    if (!this._mounted) {
      return;
    }

    const url = `${Config.apiHost}documents/${this.props.document.id}/toc/`;
    Ajax.get(url).done((items) => {
      this.setStateIfMounted((state) => ({
        ...state,
        tableOfContents: items,
      }));
    });
  }

  scrollToLastVisibleThumb() {
    const { thumbnails = [], lastVisibleThumb } = this.state;

    if (!lastVisibleThumb) {
      return;
    }

    // Get the index from the thumbs returned after the fetch, becouse this.state.lastVisibleThumb is always outdated.
    const currentThumbIndex = thumbnails.findIndex((t) => t.filename === lastVisibleThumb.filename);

    if (currentThumbIndex === -1) {
      // The last visible thumb does not exist anymore. Probably the corresponding text content of this thumb/page
      // was remove by  the user in the editor. This particularly happens when removing content at the end of the
      // document.
      if (thumbnails.length && thumbnails.length > 0) {
        this.loadThumbnailImage(thumbnails[thumbnails.length - 1]);
      }
      return;
    }

    // Ensures that the last visible thumb image will be loaded.
    const scrollToIt = !this.lastScrollTop; // lastScrollTop has precedence.
    this.loadThumbnailImage(thumbnails[currentThumbIndex], scrollToIt);

    if (this.lastScrollTop) {
      // Ensures that the previous thumb image will be loaded.
      if (currentThumbIndex > 0) {
        this.loadThumbnailImage(thumbnails[currentThumbIndex - 1]);
      }

      // Ensures that the next thumb imageswill be loaded.
      if (currentThumbIndex < thumbnails.length - 1) {
        this.loadThumbnailImage(thumbnails[currentThumbIndex + 1]);
      }

      // Scrolls to the exactly previous scroll position.
      this.scrollContainer.scrollTop = this.lastScrollTop;
    }
  }

  loadThumbnailImage(thumbnail, scrollToIt = false) {
    const element = document.getElementById(thumbnail.filename);
    if (element) {
      element.setAttribute('src', Config.djangoHost + thumbnail.url.substring(1));
      if (scrollToIt) {
        element.scrollIntoView();
        this.scrollContainer.scrollTop -= 8;
      }
    }
  }

  onVisibilityChange(isVisible, thumb) {
    if (isVisible) {
      this.loadThumbnailImage(thumb);
    }
  }

  setCurrentPageBasedOnScrollPosition(evt) {
    this.lastScrollTop = this.scrollContainer.scrollTop;

    const viewportHeight = this.scrollContainer.offsetHeight;
    for (let i = 0; i < this.scrollContainer.children.length; i++) {
      const pageDistanceToViewportTop = this.scrollContainer.children[i].getBoundingClientRect().top;
      // Include only elements that has not passed up the viewport top
      const pageIsStillBelow = pageDistanceToViewportTop > 0;
      if (pageIsStillBelow) {
        // The distance is smaller then 50% of the viewport area
        const pageEnteredTheUpperHalfOfViewportArea = pageDistanceToViewportTop / viewportHeight < 0.5;
        if (pageEnteredTheUpperHalfOfViewportArea) {
          this.changePage(i);
        }
      }
    }
  }

  changePage(thumbIndex) {
    const { thumbnails } = this.state;
    this.lastVisibleThumb = thumbnails[thumbIndex];

    if (this.props.onPageChange) {
      this.props.onPageChange(thumbIndex + 1, thumbnails.length);
    }

    if (this.setPagesToolbar) {
      this.setPagesToolbar(thumbIndex + 1, thumbnails.length);
    }
  }

  renderThumbnails() {
    const { i18n, document } = this.props;
    const { thumbnails, progress, previewerCount } = this.state;

    if (thumbnails.length) {
      return thumbnails.map((thumb, idx) => {
        return (
          <VisibilitySensor
            key={`${thumb.page_hash}-${idx}`}
            onChange={(isVisible) => this.onVisibilityChange(isVisible, thumb)}
            containment={this.scrollContainer}
            partialVisibility="top"
            minTopValue={1}
            scrollCheck={true}
            scrollThrottle={300}
          >
            <div className="pdf-thumb-page" style={{ minHeight: document.is_presentation ? '450px' : '780px' }}>
              <img
                id={thumb.filename}
                className="pdf-thumb"
                src={a4paper}
                style={{ maxHeight: document.is_presentation ? '599px' : null }}
              />
            </div>
          </VisibilitySensor>
        );
      });
    }

    // O preview já foi chamado pelo editor, após compilar, mas não retornou thumbnails.
    if (previewerCount > 0) {
      return (
        <div
          style={{
            top: '40%',
            margin: 'auto',
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <h1>
            <span className="icon mdi mdi-alert-triangle" />
          </h1>
          <h4>{i18n.t('Não existe conteúdo para exibição.')}</h4>
        </div>
      );
    }

    return (
      <Waiting
        isProcessing={true}
        style={{ minHeight: 'calc(100vh - 63px)', color: 'black' }}
        message={<h4>{i18n.t('Carregando pré-visualização') + '...'}</h4>}
      />
    );
  }

  render() {
    return (
      <>
        {this.scrollContainer && (
          <PreviewerToolbar
            document={this.props.document}
            editor={this.props.editor}
            setPagesFuncRef={(func) => (this.setPagesToolbar = func)}
            scrollContainer={this.scrollContainer}
            thumbs={this.state.thumbnails}
            tableOfContents={this.state.tableOfContents}
            progress={this.state.progress}
          />
        )}

        <div
          id="pdf-viewer"
          ref={(el) => (this.scrollContainer = el)}
          className="ff-scrollbar"
          onScroll={this.setCurrentPageBasedOnScrollPosition}
        >
          {this.renderThumbnails()}
        </div>
      </>
    );
  }
}

Previewer.propTypes = {
  /**
   * Instance of the CKEditor.
   */
  editor: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onPageChange: PropTypes.func,
};

export default withNamespaces()(Previewer);
