import React from 'react';
import PropTypes from 'prop-types';
import {withNamespaces} from "react-i18next";

class PagesInput extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {i18n} = this.props;
        return (
            <input id="reference-pages"
                   name="pages"
                   size="20"
                   placeholder={i18n.t('Página, capítulo, etc.')}
                   className="form-control"
                   value={this.props.value}
                   onChange={this.props.onInputChange}
                   style={{height: '40px'}}/>
        );
    }
}

PagesInput.propTypes = {
    document: PropTypes.object.isRequired,
    onInputChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

PagesInput.defaultProps = {
    value: ''
};

export default withNamespaces()(PagesInput);