import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Modal from '../../common/containers/Modal';
import { AlertError, AlertInfo } from '../../common/components/Alert';
import { AppContext } from '../../context/global';
import AnnotationPanel from '../../common/components/PDFViewer/AnnotationPanel';
import AnnotatorEditor from '../../common/components/PDFViewer/AnnotatorEditor';

class ReferenceAnnotationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      annotations: null,
      total: 0,
      loading: false,
      errorMessage: null,
      annotationToEdit: null,
    };
    this.openModal = this.openModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  openModal() {
    this.setState((state) => ({ ...state, showModal: true, loading: true }));
    const url = `${Config.apiHost}reference-annotations/?reference=${this.props.reference.id}`;
    Ajax.get(url)
      .done((annotations) => {
        this.setState((state) => ({
          ...state,
          loading: false,
          annotations: annotations,
          errorMessage: null,
        }));
      })
      .fail((jqXHR) => {
        this.setState((state) => ({
          ...state,
          loading: false,
          annotations: null,
          errorMessage: jqXHR.responseJSON ? jqXHR.responseJSON.detail : '',
        }));
        console.log(jqXHR);
      });
  }

  onRemove(annotation) {
    const url = `${Config.apiHost}reference-annotations/${annotation.id}`;
    Ajax.delete_(url).done(() => this.openModal());
  }

  render() {
    if (!this.props.reference.user) {
      return null;
    }

    const { i18n } = this.props;
    return (
      <>
        <a
          role="button"
          onClick={this.openModal}
          className="btn btn-default btn-xs">
          {i18n.t('Anotações')} ({this.props.reference.annotations_total})
        </a>
        <Modal
          show={this.state.showModal}
          title={
            <>
              {i18n.t('Anotações')} <br />{' '}
              <small>
                {i18n.t('Referência')}: <i>{this.props.reference.title}</i>
              </small>
            </>
          }
          style={{ zIndex: 20000 }}
          isProcessing={this.state.loading}
          onCancel={() =>
            this.setState((state) => ({ ...state, showModal: false }))
          }
          width="large">
          {this.state.annotations &&
            this.state.annotations.map((annotation) => {
              return (
                <AnnotationPanel
                  annotation={annotation}
                  key={annotation.id}
                  onEdit={() =>
                    this.setState((state) => ({
                      ...state,
                      annotationToEdit: { ...annotation },
                    }))
                  }
                  onRemove={() => this.onRemove(annotation)}
                />
              );
            })}

          {this.state.annotations && this.state.annotations.length === 0 && (
            <>
              <p>
                {i18n.t('Não foram encontradas anotações para esse documento.')}
              </p>
              <AlertInfo>
                {i18n.t(
                  'Para criar anotações, primeiro faça upload do arquivo PDF da referência e, na tela de visualização do PDF, crie marcações no texto.'
                )}
              </AlertInfo>
            </>
          )}

          {this.state.errorMessage && (
            <AlertError>{this.state.errorMessage}</AlertError>
          )}
        </Modal>

        {this.state.annotationToEdit && (
          <AnnotatorEditor
            annotation={this.state.annotationToEdit}
            createURL={`${Config.apiHost}reference-annotations/`}
            fetchURL={`${Config.apiHost}reference-annotations/{id}/`}
            updateURL={`${Config.apiHost}reference-annotations/{id}/`}
            onSave={() => {
              this.setState((state) => ({ ...state, annotationToEdit: null }));
              this.openModal();
            }}
            onCancel={() =>
              this.setState((state) => ({ ...state, annotationToEdit: null }))
            }
          />
        )}
      </>
    );
  }
}

ReferenceAnnotationButton.contextType = AppContext;

ReferenceAnnotationButton.propTypes = {
  reference: PropTypes.object.isRequired,
};

export default withNamespaces()(ReferenceAnnotationButton);
