import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Waiting from '../../common/containers/Waiting';
import { withNamespaces } from 'react-i18next';

class FilterByFolderButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      selectedFolder: null,
      processing: false,
    };
    this.fetchFolders = this.fetchFolders.bind(this);
    this.onSelectFolder = this.onSelectFolder.bind(this);
  }

  fetchFolders() {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}folders/`;
    Ajax.get(url).done((folders) => {
      this.setState((state) => ({
        ...state,
        folders,
        processing: false,
      }));
    });
  }

  onSelectFolder(folder) {
    this.setState((state) => ({ ...state, selectedFolder: folder }));
    this.props.onSelectFolder(folder);
  }

  getCheckIcon(selected) {
    return (
      <i className={selected ? 'mdi mdi-check-circle' : 'mdi mdi-circle-o'} />
    );
  }

  render() {
    const { i18n, disabled } = this.props;
    const { processing, folders, selectedFolder } = this.state;
    return (
      <div className="btn-group" role="group">
        <button
          type="button"
          className={`btn btn-default btn-md dropdown-toggle ${disabled} ${
            selectedFolder ? 'active' : ''
          }`}
          data-toggle="dropdown"
          aria-expanded="false"
          onClick={this.fetchFolders}>
          <span className="hidden-md hidden-sm">{i18n.t('Marcador')}</span>{' '}
          <i
            className="mdi mdi-filter-list"
            title={i18n.t('Filtrar por marcador')}
            data-toggle="tooltip"
            data-placement="bottom"
          />
        </button>
        <ul
          className="dropdown-menu ff-scrollbar"
          role="menu"
          style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {(processing && <Waiting isProcessing={processing} />) || (
            <>
              <li>
                <a role="button" onClick={() => this.onSelectFolder(null)}>
                  {this.getCheckIcon(!selectedFolder)} {i18n.t('Todos')}
                </a>
              </li>
              {folders.map((folder) => (
                <li key={folder.id}>
                  <a role="button" onClick={() => this.onSelectFolder(folder)}>
                    {this.getCheckIcon(
                      selectedFolder && folder.id === selectedFolder.id
                    )}{' '}
                    {folder.name}
                  </a>
                </li>
              ))}
            </>
          )}
        </ul>
      </div>
    );
  }
}

FilterByFolderButton.propTypes = {
  disabled: PropTypes.bool,
  onSelectFolder: PropTypes.func.isRequired,
};

export default withNamespaces()(FilterByFolderButton);
