import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withNamespaces} from "react-i18next";
import Modal from "../../common/containers/Modal";
import Config from "../../config";
import Ajax from "../../common/ajax";
import Select from "../../common/components/form/Select";
import Input from "../../common/components/form/Input";
import Button from "../../common/components/Button";
import {showDjangoValidationMessages} from "../../common/validation";
import {AlertError, AlertSuccess} from "../../common/components/Alert";

class ImportFromDatabase extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            showModal: false,
            processing: false,
            importFinished: false,
            error: false,
            importers: [],
            folders: [],
            form: {
                importer_name: '',
                url: '',
                folder_id: undefined,
            },
        };
        this.state = Object.assign({}, this.initialState);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.import = this.import.bind(this);
    }

    openModal() {
        this.setState(state => ({
            ...state,
            ...Object.assign({}, this.initialState),
            showModal: true
        }));
        this.fetchImporters();
        this.fetchFolders();
    }

    closeModal() {
        this.setState(state => ({
            ...state,
            ...Object.assign({}, this.initialState),
        }));
    }

    fetchImporters() {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}references/list_database_importers/`;
        Ajax.get(url).done(importers => {
            this.setState(state => ({...state, importers}));
        }).always(() => {
            this.setState(state => ({...state, processing: false}));
        });
    }

    fetchFolders() {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}folders/`;
        Ajax.get(url).done(folders => {
            this.setState(state => ({
                ...state,
                processing: false,
                folders,
            }));
        }).always(() => {
            this.setState(state => ({...state, processing: false}));
        });
    }

    onChange(e) {
        e.persist();
        let value = e.target.value;

        if (e.target.name === 'folder_id' && !value) {
            value = undefined;
        }

        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [e.target.name]: value,
            },
        }));
    }

    getURLSample() {
        const {importer_name} = this.state.form;
        const importer = this.state.importers.find(i => i.name === importer_name);
        if (importer) {
            return importer.sampleUrl;
        }
        return null;
    }

    import() {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}references/import_from_database/`;
        console.log(this.state.form);
        Ajax.post(url, this.state.form).done(response => {
            this.setState(state => ({
                ...state,
                importFinished: true,
                processing: false,
                error: false
            }));
            this.props.onImport();
        }).fail(jqXHR => {
            showDjangoValidationMessages(jqXHR.responseJSON);
            this.setState(state => ({
                ...state,
                processing: false,
                error: true
            }));
        });
    }

    getModalFooter() {
        const {i18n} = this.props;
        return (
            <React.Fragment>
                {this.state.importFinished &&
                <Button type="default" onClick={this.closeModal} size="lg">
                    {i18n.t('Fechar')}
                </Button>}
                {!this.state.importFinished &&
                <Button type="primary" onClick={this.import} size="lg">
                    {i18n.t('Importar')}
                </Button>}
            </React.Fragment>
        );
    }

    render() {
        const {i18n} = this.props;
        const helpText = this.state.form.importer_name ?
            `${i18n.t('Exemplo do formato da URL')}: ${this.getURLSample()}` : '';
        return (
            <React.Fragment>
                <a role="button" onClick={this.openModal}>
                    {i18n.t('Importar de base bibliográfica')}
                </a>

                <Modal
                    show={this.state.showModal}
                    title={i18n.t('Importar de base bibliográfica')}
                    isProcessing={this.state.processing}
                    onCancel={this.closeModal}
                    footer={this.getModalFooter()}
                >
                    {this.state.importFinished &&
                    <React.Fragment>
                        <AlertSuccess>
                            {i18n.t('Referência importada com sucesso.')}
                        </AlertSuccess>
                        <Button size="lg" type="link" onClick={this.openModal}>
                            {i18n.t('Importar outra referência')}
                        </Button>
                    </React.Fragment>}

                    {this.state.error &&
                    <AlertError>
                        {i18n.t('Não foi possível importar a referência.')}
                    </AlertError>}

                    {!this.state.importFinished &&
                    <React.Fragment>
                        <Select
                            label={i18n.t('Selecione uma base bibliográfica')}
                            options={this.state.importers.map(i =>
                                ({label: i.name, value: i.name}))}
                            name="importer_name"
                            value={this.state.form.importer_name}
                            onChange={this.onChange}
                        />

                        {this.state.form.importer_name &&
                        <Input
                            label="URL"
                            name="url"
                            value={this.state.form.url}
                            onChange={this.onChange}
                            help={helpText}
                            helpAsText
                        />}

                        {this.state.form.importer_name && this.state.folders.length > 0 &&
                        <Select
                            label={i18n.t('Marcador')}
                            options={this.state.folders.map(f =>
                                ({label: f.name, value: f.id}))}
                            name="folder_id"
                            value={this.state.form.folder_id}
                            onChange={this.onChange}
                        />}
                    </React.Fragment>
                    }
                </Modal>
            </React.Fragment>
        );
    }
}

ImportFromDatabase.propTypes = {
    onImport: PropTypes.func,
};

export default withNamespaces()(ImportFromDatabase);