import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from '../../common/containers/Modal';
import { isUserFirstDay } from '../../context/global';

class FirstStepsVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    console.log(this.state);
    this.setState((state) => ({
      ...state,
      showModal: true,
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  }

  render() {
    if (!isUserFirstDay()) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <>
        <a
          role="button"
          onClick={() => this.setState((state) => ({ ...state, showModal: true }))}
          title="Como utilizar o FastFormat"
          data-toggle="tooltip"
          data-placement="bottom"
        >
          <i className="icon mdi mdi-collection-video" />
          <span className="hidden-md hidden-sm hidden-xs">
            &nbsp;
            {i18n.t('Primeiros passos')}
          </span>
        </a>

        <Modal
          title={i18n.t('Primeiros passos')}
          show={this.state.showModal}
          onCancel={this.closeModal}
          isProcessing={this.state.processing}
          customWidth="85vw"
          fullScreen={true}
          closeOnClickOutside={true}
        >
          <iframe
            width="100%"
            style={{ height: '75vh' }}
            src="https://www.youtube.com/embed/PDQR0sD4VUw?modestbranding=1&rel=0&showinfo=0"
            title={i18n.t('Primeiros passos no FastFormat')}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          />
        </Modal>
      </>
    );
  }
}

FirstStepsVideo.propTypes = {
  document: PropTypes.object.isRequired,
};

export default withNamespaces()(FirstStepsVideo);
