import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../common/ajax';
import Alert from '../../common/components/Alert';
import Button from '../../common/components/Button';
import SpinnerIcon from '../../common/components/SpinnerIcon';
import Modal from '../../common/containers/Modal';
import Config from '../../config';
import { AppContext } from '../../context/global';
import Events from './Events';
import ModalPitch from './ModalPitch';

class SaveButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unsaved: false,
      saving: false,
      compiling: false,
      taskURL: null,
      lastSavedDate: null,
      showSaveError: false,
      showCompileError: false,
      showPitch: false,
      compileCount: 0,
    };
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.save = this.save.bind(this);
    this.saveAndCompile = this.saveAndCompile.bind(this);
    this.saveSynchronously = this.saveSynchronously.bind(this);
    this.compile = this.compile.bind(this);
    this.checkSaveStatus = debounce(this.checkSaveStatus.bind(this), 1000);
    this.debouncedSave = debounce(this.save, 5000);
    this.compilingMonitor = debounce(this.compilingMonitor.bind(this), 500);
    this.lastSavedEditorData = null;
    this.monitorInterval = null;
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;

    this.lastSavedEditorData = this.props.editor.getData();

    this.props.editor.on(Events.SAVE_AND_COMPILE, (evt) => {
      this.saveAndCompile();
    });

    this.props.editor.on(Events.COMPILE, (evt) => {
      this.compile();
    });

    this.props.editor.on(Events.SAVE, () => this.save());

    this.props.editor.on('change', () => {
      this.checkSaveStatus();
      this.debouncedSave(false);
    });

    this.ensureFirstCompile();

    $(window)
      .unbind('beforeunload.ffSave')
      .on('beforeunload.ffSave', () => {
        if (this.isUnsaved()) {
          return 'Existem mudanças não salvas no documento. Deseja realmente sair?';
        }
      });
  }

  componentWillUnmount() {
    clearInterval(this.monitorInterval);
    this.checkSaveStatus.cancel();
    this.debouncedSave.cancel();
    this.saveOnLeave();
    this._mounted = false;
  }

  saveOnLeave() {
    this.saveSynchronously();
  }

  setStateIfMounted(state, callback) {
    if (this._mounted) {
      this.setState(state, callback);
    }
  }

  ensureFirstCompile() {
    this.compile(true);
  }

  checkSaveStatus() {
    const unsaved = this.isUnsaved();
    if (unsaved !== this.state.unsaved) {
      this.setStateIfMounted((state) => ({
        ...state,
        unsaved,
      }));
    }
  }

  saveAndCompile() {
    this.save(true, false);
  }

  saveSynchronously() {
    this.save(false, true);
  }

  save(compile = false, sync = false) {
    if (this.isUnsaved()) {
      this.props.editor.fire(Events.BEFORE_SAVE, { will_compile: compile });

      const editorData = this.props.editor.getData();

      if (this.lastSavedEditorData === editorData) {
        if (compile) {
          this.compile();
        }
        return;
      }

      this.setStateIfMounted((state) => ({
        ...state,
        saving: true,
        showSaveError: false,
      }));

      this.props.editor.getCommand('fastformatSave').disable();

      let xhr;

      if (this.props.isCustomAttribute) {
        xhr = this.saveCustomAttribute(editorData, sync);
      } else {
        xhr = this.saveDocumentAttribute(editorData, sync);
      }

      xhr
        .done(() => {
          if (!this._mounted) {
            return;
          }
          this.lastSavedEditorData = editorData;
          this.props.editor.resetDirty();
          this.checkSaveStatus();
          this.props.editor.fire(Events.AFTER_SAVE, { will_compile: compile });
          if (compile) {
            this.compile();
          } else {
            this.props.editor.getCommand('fastformatSave').enable();
          }
          this.setStateIfMounted((state) => ({
            ...state,
            saving: false,
            lastSavedDate: new Date(),
          }));
        })
        .fail(() => {
          this.setStateIfMounted((state) => ({
            ...state,
            saving: false,
            showSaveError: true,
          }));
          this.props.editor.getCommand('fastformatSave').enable();
          this.props.editor.fire(Events.AFTER_SAVE);
        });
    } else if (compile) {
      this.compile();
    }
  }

  isUnsaved() {
    return !this.state.saving && this.props.editor.checkDirty();
  }

  saveDocumentAttribute(data, sync = false) {
    const url = `${Config.apiHost}documents/${this.props.document.id}/`;
    console.log('Saving attribute', this.props.attribute);
    if (sync) {
      return Ajax.patchSync(url, { [this.props.attribute]: data });
    } else {
      return Ajax.patch(url, { [this.props.attribute]: data });
    }
  }

  saveCustomAttribute(data, sync = false) {
    const url = `${Config.apiHost}documents/${this.props.document.id}/custom_attribute/?attr=${this.props.attribute}`;
    console.log('Saving custom attribute', this.props.attribute);
    if (sync) {
      return Ajax.patchSync(url, { value: data });
    } else {
      return Ajax.patch(url, { value: data });
    }
  }

  compile(initial = false) {
    this.props.editor.fire(Events.BEFORE_COMPILATION_START);

    if (!this._mounted || this.state.compiling) {
      return;
    }

    this.setStateIfMounted((state) => ({
      ...state,
      compiling: true,
      showCompileError: false,
    }));

    this.props.editor.getCommand('fastformatSave').disable();
    const url = `${Config.apiHost}documents/${this.props.document.id}/compile_async/?force=1&initial=${
      initial ? 1 : 0
    }`;
    Ajax.get(url)
      .done((response) => {
        this.setStateIfMounted(
          (state) => ({
            ...state,
            taskURL: response.task_url,
          }),
          this.compilingMonitor,
        );
        this.props.editor.fire(Events.AFTER_COMPILE);
      })
      .fail(() => {
        this.setStateIfMounted((state) => ({
          ...state,
          compiling: false,
        }));
      });
  }

  compilingMonitor() {
    if (!this._mounted) {
      return;
    }

    Ajax.get(this.state.taskURL)
      .done((response) => {
        this.setStateIfMounted((state) => ({
          ...state,
          compiling: !response.is_finished,
          showCompileError: response.has_error,
        }));

        if (response.progress_by_mean) {
          this.props.editor.fire(Events.COMPILATION_PROGRESS, {
            // progress: response.cached_progress,
            progress: response.progress_by_mean,
          });
        }

        if (response.is_finished) {
          this.props.editor.fire(Events.AFTER_COMPILATION_COMPLETE);
          this.props.editor.fire(Events.PREVIEW);
          this.props.editor.getCommand('fastformatSave').enable();
          const count = this.state.compileCount + 1;
          this.setState((state) => ({
            ...state,
            showPitch: !this.context.has_valid_paid_plan && count > 3 && count % 3 === 0,
            compileCount: count,
          }));
        } else {
          this.compilingMonitor();
        }
      })
      .fail((jqXHR) => {
        if (jqXHR.readyState === 0) {
          setTimeout(this.compilingMonitor, 5000);
        }
      });
  }

  closeErrorModal() {
    this.setStateIfMounted((state) => ({
      ...state,
      showSaveError: false,
      showCompileError: false,
    }));
  }

  render() {
    if (!this.props.editor) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <>
        {this.state.saving && (
          <a role="button">
            <span className="text-primary">
              <SpinnerIcon /> {i18n.t('Salvando') + '...'}
            </span>
          </a>
        )}

        {this.state.showSaveError && (
          <Modal
            title={i18n.t('Erro ao salvar documento')}
            show={this.state.showSaveError}
            onCancel={this.closeErrorModal}
            footer={<Button onClick={this.closeErrorModal}>{i18n.t('Fechar')}</Button>}
          >
            <Alert type="danger">
              {i18n.t('Não foi possível salvar o documento.')}{' '}
              {i18n.t('Se o problema persistir, contate nosso suporte.')}
            </Alert>
          </Modal>
        )}

        {this.state.showCompileError && (
          <Modal
            title={i18n.t('Erro ao gerar PDF do documento')}
            show={this.state.showCompileError}
            onCancel={this.closeErrorModal}
            footer={<Button onClick={this.closeErrorModal}>{i18n.t('Fechar')}</Button>}
          >
            <Alert type="danger">
              {i18n.t('Não foi possível gerar o PDF do documento.')}{' '}
              {i18n.t('Se o problema persistir, contate nosso suporte.')}
            </Alert>
          </Modal>
        )}

        {this.state.showPitch && (
          <ModalPitch
            show={this.state.showPitch}
            onClose={() => this.setState((state) => ({ ...state, showPitch: false }))}
          />
        )}
      </>
    );
  }
}

SaveButton.propTypes = {
  /**
   * Instance of the CKEditor.
   */
  editor: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  attribute: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCustomAttribute: PropTypes.bool.isRequired,
};

SaveButton.contextType = AppContext;

export default withNamespaces()(SaveButton);
