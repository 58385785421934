export function getPrismLanguage(language) {
  const entry = LANGUAGES.find((l) => l[0] === language);
  return (entry && entry[1]) || 'clike';
}

export function getCodeAttributes(codeElement) {
  const clazz = codeElement.getAttribute('class') || 'language-JavaScript';
  return {
    caption: codeElement.getAttribute('caption'),
    codeSnippet: codeElement.innerText,
    language: clazz.split('-')[1],
    fontSize: codeElement.getAttribute('fontsize'),
    showColors: codeElement.getAttribute('showcolors') === 'yes',
    showNumbers: codeElement.getAttribute('shownumbers') || 'left',
    firstNumber: codeElement.getAttribute('firstnumber') || 1,
    numbersSpacing: codeElement.getAttribute('numberspacing'),
    numbersStep: codeElement.getAttribute('numbersstep'),
    showFrame: codeElement.getAttribute('showframe') === 'yes',
  };
}

// Primeiro parametro: lstlistings language
// Segundo parametro: prismjs language
export const LANGUAGES = [
  ['ABAP', 'abap'],
  ['ACSL', null],
  ['Ada', 'ada'],
  ['Algol', null],
  ['Ant', null],
  ['Assembler', 'asm6502'],
  ['Awk', null],
  ['bash', 'bash'],
  ['Basic', 'basic'],
  ['C#', 'csharp'],
  ['C++', 'cpp'],
  ['C', 'c'],
  ['Caml', null],
  ['Clean', null],
  ['Cobol', 'cobol'],
  ['Comal', null],
  ['csh', null],
  ['Delphi', 'pascal'],
  ['Eiffel', 'eiffel'],
  ['Elan', null],
  ['erlang', 'erlang'],
  ['Euphoria', null],
  ['Fortran', 'fortran'],
  ['GCL', null],
  ['Gnuplot', null],
  ['Haskell', 'haskell'],
  ['HTML', 'html'],
  ['IDL', 'avro-idl'],
  ['inform', 'inform7'],
  ['Java', 'java'],
  ['JavaScript', 'javascript'],
  ['JSON', 'json'],
  ['JVMIS', null],
  ['ksh', null],
  ['Lisp', 'lisp'],
  ['Logo', null],
  ['Lua', 'lua'],
  ['make', 'makefile'],
  ['Mathematica', null],
  ['Matlab', 'matlab'],
  ['Mercury', null],
  ['MetaPost', null],
  ['Miranda', null],
  ['Mizar', 'mizar'],
  ['ML', null],
  ['Modelica', null],
  ['Modula-2', null],
  ['MuPAD', null],
  ['NASTRAN', null],
  ['Oberon-2', null],
  ['ObjectiveC', 'objectivec'],
  ['OCL', 'opencl'],
  ['Octave', null],
  ['Oz', 'oz'],
  ['Pascal', 'pascal'],
  ['Perl', 'perl'],
  ['PHP', 'php'],
  ['PL/I', null],
  ['Plasm', null],
  ['POV', null],
  ['Prolog', 'prolog'],
  ['Promela', null],
  ['Python', 'python'],
  ['R', 'r'],
  ['Reduce', null],
  ['Rexx', null],
  ['RSL', null],
  ['Ruby', 'ruby'],
  ['S', null],
  ['SAS', 'sas'],
  ['Scilab', null],
  ['sh', 'bash'],
  ['SHELXL', null],
  ['Simula', null],
  ['SQL', 'sql'],
  ['tcl', 'tcl'],
  ['TeX', 'latex'],
  ['VBScript', 'vbnet'],
  ['Verilog', 'verilog'],
  ['VHDL', 'vhdl'],
  ['VRML', null],
  ['XML', 'xml'],
  ['XSLT', 'markup'],
];

export const FONT_SIZES = [
  ['Tiny', 'tiny'],
  ['Script', 'scriptsize'],
  ['Footnote', 'footnotesize'],
  ['Small', 'small'],
  ['Normal', 'normalsize'],
  ['Large', 'large'],
  ['Very large', 'Large'],
  ['Extra large', 'LARGE'],
  ['Huge', 'huge'],
  ['Very huge', 'Huge'],
];

// new Set(LANGUAGES.filter((i) => !!i[1]).map((i) => i[1])).forEach((i) =>
//   console.log(`import 'prismjs/components/prism-${i}';`)
// );
