function mapClassName(name) {
  return name;
}

var PARTNER = {
  '(': ')',
  '[': ']',
  '{': '}',
};

// The names for brace types.
// These names have two purposes: 1) they can be used for styling and 2) they are used to pair braces. Only braces
// of the same type are paired.
var NAMES = {
  '(': 'brace-round',
  '[': 'brace-square',
  '{': 'brace-curly',
};

// A map for brace aliases.
// This is useful for when some braces have a prefix/suffix as part of the punctuation token.
var BRACE_ALIAS_MAP = {
  '${': '{', // JS template punctuation (e.g. `foo ${bar + 1}`)
};

export default function matchBraces(pre) {
  if (!pre || pre.tagName != 'PRE') {
    return;
  }

  var pairIdCounter = 0;

  var toMatch = ['(', '[', '{'];

  var punctuation = Array.prototype.slice.call(
    pre.querySelectorAll(
      'span.' + mapClassName('token') + '.' + mapClassName('punctuation')
    )
  );

  var allBraces = [];

  toMatch.forEach(function (open) {
    var close = PARTNER[open];
    var name = mapClassName(NAMES[open]);

    /** @type {[number, number][]} */
    var pairs = [];
    /** @type {number[]} */
    var openStack = [];

    for (var i = 0; i < punctuation.length; i++) {
      var element = punctuation[i];
      if (element.childElementCount == 0) {
        var text = element.textContent;
        text = BRACE_ALIAS_MAP[text] || text;
        if (text === open) {
          allBraces.push({ index: i, open: true, element: element });
          element.classList.add(name);
          element.classList.add(mapClassName('brace-open'));
          openStack.push(i);
        } else if (text === close) {
          allBraces.push({ index: i, open: false, element: element });
          element.classList.add(name);
          element.classList.add(mapClassName('brace-close'));
          if (openStack.length) {
            pairs.push([i, openStack.pop()]);
          }
        }
      }
    }

    pairs.forEach(function (pair) {
      var pairId = 'pair-' + pairIdCounter++ + '-';
      var opening = punctuation[pair[0]];
      var closing = punctuation[pair[1]];
      opening.id = pairId + 'open';
      closing.id = pairId + 'close';
    });

    pre.querySelectorAll('.brace-open, .brace-close').forEach((punkt) => {
      if (!punkt.id) {
        punkt.classList.add('brace-unbalanced');
      }
    });
  });
}
