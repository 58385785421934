import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/containers/Modal';
import Button from '../../common/components/Button';
import Config from '../../config';
import Ajax from '../../common/ajax';
import { withNamespaces } from 'react-i18next';
import { AlertInfo } from '../../common/components/Alert';
import truncate from 'underscore.string/truncate';
import PaginationWithClick from '../../common/components/PaginationWithClick';

class Models extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      processing: 0,
      userModelsPagination: null,
      publicModelsPagination: null,
      selectedModel: null,
      previewModel: null,
    };

    this.fetch = this.fetch.bind(this);
    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.selectModel = this.selectModel.bind(this);
    this.insertModel = this.insertModel.bind(this);
    this.deleteModel = this.deleteModel.bind(this);
    this.togglePreviewModel = this.togglePreviewModel.bind(this);

    this.publicURL = `${Config.apiHost}document-models/`;
    this.userURL = `${Config.apiHost}document-models/?user=1`;
  }

  fetch(url) {
    this.setState((state) => ({
      ...state,
      processing: state.processing + 1,
    }));
    return Ajax.get(url).done((pagination) => {
      const isUser = url.indexOf('user=1') > -1;
      this.setState((state) => ({
        ...state,
        [isUser ? 'userModelsPagination' : 'publicModelsPagination']: pagination,
        selectedModel: null,
        processing: state.processing - 1,
      }));
    });
  }

  handlePaginationClick(url) {
    this.fetch(decodeURIComponent(url));
  }

  openModal() {
    this.fetch(this.publicURL);
    this.fetch(this.userURL);
    this.setState((state) => ({
      ...state,
      showModal: true,
    }));
  }

  closeModal() {
    this.setState({
      ...this.state,
      showModal: false,
      selectedModel: null,
    });
  }

  insertModel(replaceContent = false) {
    if (this.state.selectedModel) {
      if (replaceContent) {
        this.props.editor.setData(this.state.selectedModel.model);
      } else {
        this.props.editor.insertHtml(this.state.selectedModel.model);
      }
    }
    this.setState({
      ...this.state,
      showModal: false,
      selectedModel: null,
    });
  }

  selectModel(evt) {
    let selectedModel;
    const { userModelsPagination, publicModelsPagination } = this.state;

    if (userModelsPagination) {
      selectedModel = userModelsPagination.results.find((m) => m.id == evt.target.value);
    }

    if (!selectedModel && publicModelsPagination) {
      selectedModel = publicModelsPagination.results.find((m) => m.id == evt.target.value);
    }

    this.setState((state) => ({
      ...state,
      selectedModel,
    }));
  }

  deleteModel(model) {
    const { i18n } = this.props;
    const msg = i18n.t('Confirma a remoção do modelo?');
    if (confirm(msg)) {
      const url = `${Config.apiHost}document-models/${model.id}/`;
      Ajax.delete_(url).done(() => this.fetch(this.userURL));
    }
  }

  togglePreviewModel(model) {
    this.setState((state) => ({ ...state, previewModel: model }));
  }

  renderTabContent(pagination) {
    const { i18n } = this.props;
    return (
      <>
        <table className="table table-condensed">
          <tbody>
            {pagination &&
              pagination.results.map((model) => {
                return (
                  <tr key={`user-model-${model.id}`}>
                    <td>
                      <div className="be-radio inline">
                        <input
                          type="radio"
                          id={`radio-model-${model.id}`}
                          name="selectedModel"
                          value={model.id}
                          onChange={this.selectModel}
                        />
                        <label htmlFor={`radio-model-${model.id}`} />
                      </div>
                    </td>
                    <td>{model.name}</td>
                    <td>{truncate(model.description, 400)}</td>
                    <td>
                      <a role="button" title={i18n.t('Previsualizar')} onClick={() => this.togglePreviewModel(model)}>
                        <i className="mdi mdi-eye" />
                      </a>
                    </td>
                    {/*<td>*/}
                    {/*{model.owner && (*/}
                    {/*<a role="button" >*/}
                    {/*<i className="mdi mdi-share" />*/}
                    {/*</a>*/}
                    {/*)}*/}
                    {/*</td>*/}
                    <td>
                      {model.owner && (
                        <a role="button" onClick={() => this.deleteModel(model)}>
                          <i className="mdi mdi-delete" />
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {(!pagination || !pagination.results.length) && (
          <>
            <AlertInfo>{i18n.t('Você ainda não possui modelos de conteúdo cadastrados.')}</AlertInfo>
            <p>
              {i18n.t(
                'Para criar um modelo, insira no editor o conteúdo que você gostaria de reutilizar em outros documentos e acione o menu',
              )}{' '}
              <b>{i18n.t('Salvar como modelo')}</b>.
            </p>
          </>
        )}

        {pagination && <PaginationWithClick handleClick={this.handlePaginationClick} pagination={pagination} />}
      </>
    );
  }

  render() {
    const { i18n } = this.props;

    const footer = (
      <React.Fragment>
        <Button type="default" size="lg" onClick={this.closeModal}>
          {i18n.t('Cancelar')}
        </Button>
        <Button
          type="primary"
          size="lg"
          disabled={!this.state.selectedModel}
          onClick={() => this.insertModel()}
          title={i18n.t('O conteúdo do modelo será inserido na posição atual do cursor no editor')}
        >
          {i18n.t('Inserir na posição atual')}
        </Button>
        <Button
          type="primary"
          size="lg"
          disabled={!this.state.selectedModel}
          onClick={() => this.insertModel(true)}
          title={i18n.t('O conteúdo atual do editor será substituído pelo conteúdo do modelo')}
        >
          {i18n.t('Substituir o conteúdo')}
        </Button>
      </React.Fragment>
    );

    return (
      <>
        <a role="button" onClick={this.openModal}>
          <span className="icon mdi mdi-file" />
          <span>{i18n.t('Modelos de conteúdo')}</span>
        </a>

        <Modal
          title={i18n.t('Modelos de conteúdo')}
          headerFullColor="primary"
          show={this.state.showModal}
          onCancel={this.closeModal}
          isProcessing={!!this.state.processing}
          footer={footer}
          width="large"
        >
          <ul className="nav nav-pills" role="tablist">
            <li role="presentation" className="active">
              <a href="#public" aria-controls="public" role="tab" data-toggle="tab">
                {i18n.t('Modelos públicos')}
              </a>
            </li>
            <li role="presentation">
              <a href="#user" aria-controls="user" role="tab" data-toggle="tab">
                {i18n.t('Meus modelos')}
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div role="tabpanel" className="tab-pane active" id="public">
              {this.renderTabContent(this.state.publicModelsPagination)}
            </div>
            <div role="tabpanel" className="tab-pane" id="user">
              {this.renderTabContent(this.state.userModelsPagination)}
            </div>
          </div>
        </Modal>

        {this.state.previewModel && (
          <Modal
            title={this.state.previewModel.name}
            show={this.state.previewModel}
            width="full"
            onCancel={() => this.togglePreviewModel(null)}
          >
            <div dangerouslySetInnerHTML={{ __html: this.state.previewModel.model }} />
          </Modal>
        )}
      </>
    );
  }
}

Models.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Models);
