import Ajax from '../../../common/ajax';
import storage from '../../../common/storage';
import Config from '../../../config';
import debounce from 'lodash.debounce';

export default class IgnoredTokensManager {
  constructor() {
    this.urlIgnore = `${Config.apiHost}proofread/ignored-terms/`;
    this.STORAGE_KEY = 'ffIgnoredTokens';
    // Get tokens from storage to keep compatibility.
    this.initialDefaultCache = { grammar: [], readability: [] };
    this.cache = { ...this.initialDefaultCache };
    this.sync = debounce(this.sync.bind(this), 10000);
    this.enableSyncWithBackend = true;
  }

  start() {
    // Get tokens from storage in order to speed up the bootstrap and to
    // keep compatibility with old users.
    this.cache = JSON.parse(
      storage.getItem(
        this.STORAGE_KEY,
        JSON.stringify({ ...this.initialDefaultCache })
      )
    );

    Ajax.get(this.urlIgnore).done((data) => {
      // Concat with previous content from the browser storage in order to keep the
      // compatibility with old users.

      if (data && data.grammar && data.grammar.length) {
        this.cache.grammar = [
          ...new Set(this.cache.grammar.concat(data.grammar)),
        ];
      }

      if (data && data.readability && data.readability.length) {
        this.cache.readability = [
          ...new Set(this.cache.readability.concat(data.readability)),
        ];
      }
    });
  }

  ignoreToken(token, match) {
    if (match.type === 'langt' && this.cache.grammar.indexOf(token) === -1) {
      this.cache.grammar.push(token);
      this.sync();
    } else if (this.cache.readability.indexOf(token) === -1) {
      this.cache.readability.push(token);
      this.sync();
    }
  }

  sync() {
    const data = { ...this.cache };
    data.grammar = [...new Set(data.grammar)];
    data.readability = [...new Set(data.readability)];

    storage.setItem(this.STORAGE_KEY, JSON.stringify(data));
    if (this.enableSyncWithBackend) {
      // Sync with backend so users can reuse their ignored tokens in different devices.
      Ajax.post(this.urlIgnore, data);
    }
  }

  isMatchResolved(match) {
    if (match.type === 'langt') {
      const isContextIgnored =
        match &&
        match.context &&
        this.cache.grammar.indexOf(match.context) > -1;
      const isTokenIgnored = this.cache.grammar.indexOf(match.token) > -1;
      return isContextIgnored || isTokenIgnored;
    } else {
      return this.cache.readability.indexOf(match.token) > -1;
    }
  }
}
