import 'prismjs/components/prism-latex';
import 'prismjs/themes/prism.css';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../../common/ajax';
import Modal from '../../../common/containers/Modal';
import Config from '../../../config';
import Prism from 'prismjs';
import 'prismjs/components/prism-latex';
import Row from '../../../common/containers/Row';
import Col from '../../../common/containers/Col';

class FormulaGuide extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, processing: false, formulas: [] };
  }

  componentDidMount() {
    this.fetchFormulas();
  }

  fetchFormulas() {
    this.setState((state) => ({ ...state, processing: true }));
    Ajax.get(`${Config.apiHost}latex-formula/`)
      .done((formulas) => {
        this.setState((state) => ({ ...state, formulas }));
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  groupFormulasByCategory() {
    const groups = {};
    this.state.formulas.forEach((f) => {
      if (groups[f.category_name]) {
        groups[f.category_name].push(f);
      } else {
        groups[f.category_name] = [f];
      }
    });
    return groups;
  }

  render() {
    const { i18n } = this.props;

    if (!this.state.formulas.length) {
      return null;
    }

    const formulaGroups = this.groupFormulasByCategory();
    const groupNames = Object.keys(formulaGroups).sort();

    return (
      <>
        <a
          role="button"
          onClick={() => this.setState((s) => ({ ...s, show: true }))}
        >
          {i18n.t('Exemplos de comandos e fórmulas')}
        </a>
        <Modal
          title={i18n.t('Exemplos de comandos e fórmulas')}
          show={this.state.show}
          isProcessing={this.state.processing}
          onCancel={() => this.setState((s) => ({ ...s, show: false }))}
          footer={
            <button
              className="btn btn-primary btn-lg"
              onClick={() => this.setState((s) => ({ ...s, show: false }))}
            >
              {i18n.t('Fechar')}
            </button>
          }
          width="full"
        >
          <div className="tab-container">
            <Row>
              <Col md={2}>
                <ul className="nav nav-pills nav-stacked" role="tablist">
                  {groupNames.map((name, idx) => (
                    <li
                      key={name}
                      role="presentation"
                      className={`tab-pane ${idx === 0 ? 'active' : ''}`}
                    >
                      <a href={`#${idx}`} data-toggle="tab">
                        {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={10}>
                <div className="tab-content">
                  {groupNames.map((name, idx) => (
                    <div
                      key={name}
                      id={idx}
                      className={`tab-pane ${idx === 0 ? 'active' : ''}`}
                    >
                      <table className="table table-condensed">
                        <thead>
                          <tr>
                            <th>{name}</th>
                            <th>{i18n.t('Exemplo')}</th>
                            <th>{i18n.t('Resultado')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formulaGroups[name].map((f, idx) => (
                            <tr key={idx}>
                              <td width="33%">
                                {f.name}
                                <br />
                                <i>{f.description}</i>
                              </td>
                              <td>
                                <pre className="formula-modal-guide-code">
                                  <code
                                    className="language-latex"
                                    dangerouslySetInnerHTML={{
                                      __html: Prism.highlight(
                                        f.latex,
                                        Prism.languages.latex
                                      ),
                                    }}
                                  />
                                </pre>
                              </td>
                              <td>
                                <img
                                  src={`data:image/png;base64,${f.image_b64}`}
                                  alt={f.latex}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

export default withNamespaces()(FormulaGuide);
