import React from 'react';
import PropTypes from 'prop-types';
import truncate from "underscore.string/truncate";
import {withNamespaces} from "react-i18next";

class ApudSelect extends React.Component {
    renderOptions() {
        return Object.values(this.props.selectedReferences).map(ref => {
            return (
                <option value={ref.id} key={ref.id}>
                    {truncate(ref.author_etal, 30)} ({ref.year})
                </option>
            );
        });
    }

    render() {
        if (!this.props.document.is_abnt_based) {
            return null;
        }

        const {i18n} = this.props;

        const apudable = Object.keys(this.props.selectedReferences).length === 2;

        const title = apudable ?
            i18n.t('Selecione a referência de apud, caso desejar.') :
            i18n.t('Apud disponível apenas quando duas referências são selecionadas.');

        return (
            <select
                id='apud-select'
                title={title}
                name="apud_id"
                className="form-control"
                onChange={this.props.onInputChange}
                value={this.props.value}
                disabled={!apudable}
                style={{ height: '40px', padding: '0px 8px' }}
            >
                <option value="" disabled={true}>Apud...</option>
                {this.renderOptions()}
            </select>
        );
    }
}

ApudSelect.propTypes = {
    document: PropTypes.object.isRequired,
    selectedReferences: PropTypes.object.isRequired,
    onInputChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

ApudSelect.defaultProps = {
    value: ''
};

export default withNamespaces()(ApudSelect);