import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import './dropzone.css';
import { AlertError } from '../Alert';
import omit from 'lodash/omit';
import Row from '../../containers/Row';
import Col from '../../containers/Col';
import { withNamespaces } from 'react-i18next';
import LocaleUtils from '../../LocaleUtils';

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      progress: {}, // Object of object as { finished: false, percentage: 0 }
      errorMessage: null,
    };
    this.updateProgress = this.updateProgress.bind(this);
    this.submit = this.submit.bind(this);
    this.checkFinishedUploads = this.checkFinishedUploads.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(acceptedFiles, rejectedFiles, e) {
    const { i18n, maxSize, onAskConfirmation, onStart, maxFiles } = this.props;

    if (rejectedFiles.length && maxSize) {
      const hasGraterThanMaxSize = rejectedFiles.find((f) => f.size > maxSize);
      if (hasGraterThanMaxSize) {
        this.setState((state) => ({
          ...state,
          errorMessage: i18n.t(
            'Não é possível selecionar arquivos maiores que o tamanho máximo permitido.'
          ),
        }));
        return;
      }
    }

    if (!acceptedFiles.length) {
      this.setState((state) => ({
        ...state,
        errorMessage: i18n.t(
          'Erro inesperado. Não foi possível selecionar os arquivos.'
        ),
      }));
      return;
    } else if (acceptedFiles.length > maxFiles) {
      this.setState((state) => ({
        ...state,
        errorMessage: i18n.t('Apenas 5 arquivos podem ser enviados por vez.'),
      }));
      return;
    }

    if (onAskConfirmation) {
      const proceed = onAskConfirmation(acceptedFiles);
      if (!proceed) {
        return;
      }
    }

    this.setState(
      (state) => ({
        ...state,
        processing: true,
        progress: {},
        errorMessage: null,
      }),
      () => acceptedFiles.forEach(this.submit)
    );

    onStart && onStart();
  }

  submit(file) {
    const { i18n } = this.props;
    const data = new FormData();
    data.append('files[]', file, file.name);
    const self = this;

    const xhr = $.ajax({
      url: this.props.url,
      type: 'POST',
      data: data,
      cache: false,
      dataType: 'json',
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      xhrFields: { withCredentials: true },
      xhr: function () {
        const myXhr = $.ajaxSettings.xhr();
        myXhr.upload.addEventListener(
          'progress',
          (e) => self.updateProgress(file, e),
          false
        );
        return myXhr;
      },
    })
      .always(() => {
        this.setState(
          (state) => ({
            ...state,
            progress: {
              ...state.progress,
              [file.name]: {
                finished: true,
                percentage: 100,
                xhr: null,
              },
            },
          }),
          this.checkFinishedUploads
        );
      })
      .done((response) => {
        if (this.props.onSuccess) {
          this.props.onSuccess(response);
        }
      })
      .fail((jqXHR) => {
        let errorMessage;

        if (jqXHR.responseJSON) {
          if (Array.isArray(jqXHR.responseJSON)) {
            errorMessage = jqXHR.responseJSON.join('\n');
          } else {
            errorMessage =
              jqXHR.responseJSON.detail || i18n.t('Erro inesperado');
          }
        }

        if (this.props.onError) {
          this.props.onError(jqXHR, {
            filename: file.name,
            message: errorMessage,
          });
        }
        this.setState((state) => ({
          ...state,
          errorMessage,
        }));
      });

    this.setState((state) => ({
      ...state,
      progress: {
        ...state.progress,
        [file.name]: {
          ...state.progress[file.name],
          xhr,
        },
      },
    }));

    return xhr;
  }

  checkFinishedUploads() {
    if (this.isAllFinished()) {
      this.setState((state) => ({
        ...state,
        processing: false,
      }));

      if (this.props.onAllFinished) {
        this.props.onAllFinished();
      }
    }
  }

  isAllFinished() {
    const values = Object.values(this.state.progress);
    return values.filter((value) => value.finished).length === values.length;
  }

  hasPerformedAnyUpload() {
    return Object.values(this.state.progress).length > 0;
  }

  hasUploadInProgress() {
    return !this.isAllFinished();
  }

  updateProgress(file, e) {
    console.log('progress', e);
    if (e.lengthComputable) {
      const percentComplete = (e.loaded / e.total) * 100;
      this.setState((state) => ({
        ...state,
        progress: {
          ...state.progress,
          [file.name]: {
            ...state.progress[file.name],
            percentage: parseInt(String(percentComplete)),
          },
        },
      }));

      if (percentComplete >= 100 && this.props.onUploadFinished) {
        this.props.onUploadFinished(file);
      }
    }
  }

  renderProgress() {
    const { i18n } = this.props;

    return Object.keys(this.state.progress).map((key) => {
      const progressItem = this.state.progress[key];
      const inProgress = !!progressItem.xhr;
      return (
        <Row key={key}>
          <Col md={inProgress ? 10 : 12}>
            <div className={inProgress ? 'progress' : ''}>
              <div
                style={{ width: progressItem.percentage + '%' }}
                className={
                  inProgress ? 'progress-bar progress-bar-primary' : ''
                }
              >
                {!inProgress && progressItem.percentage >= 100 && (
                  <i className="icon mdi mdi-check text-success" />
                )}
                &nbsp;
                {key} {inProgress && `${progressItem.percentage}%`}
              </div>
            </div>
          </Col>
          {progressItem.xhr && (
            <Col md={2} onClick={() => progressItem.xhr.abort()}>
              <a role="button">
                <i className="icon mdi mdi-close-circle" />
                &nbsp;
                {i18n.t('Cancelar')}
              </a>
            </Col>
          )}
        </Row>
      );
    });
  }

  render() {
    const { i18n } = this.props;
    // Byto -> Kilobytes -> Megabytes
    const maxSize = Math.ceil(Number(this.props.maxSize) / 1000 / 1024);
    return (
      <React.Fragment>
        <AlertError>{this.state.errorMessage}</AlertError>

        {this.renderProgress()}

        {!this.hasPerformedAnyUpload() && (
          <Dropzone
            {...omit(this.props, [
              'onSuccess',
              'onStart',
              'onUploadFinished',
              'onAllFinished',
            ])}
            onDrop={this.onDrop}
            className="dropzone dz-clickable"
            style={{
              position: 'relative',
              border: '2px dashed #c3c3c3',
              padding: '70px 60px 80px',
              background: 'transparent',
            }}
          >
            {this.isAllFinished() && (
              <div className="dz-message text-center">
                <div className="icon">
                  <span className="mdi mdi-cloud-upload" />
                </div>

                <h2>{i18n.t('Clique aqui para selecionar')}</h2>

                <p className="note">
                  {i18n.t('Ou arraste e solte os arquivos para enviar')}
                </p>

                {this.props.accept && (
                  <p className="note">
                    {i18n.t('Arquivos aceitos')}: {this.props.accept.join(', ')}
                  </p>
                )}

                {this.props.maxSize && (
                  <p className="note">
                    {i18n.t('Tamanho máximo')}: {maxSize} MB
                  </p>
                )}
                <div className="dropzone-mobile-trigger needsclick" />
              </div>
            )}
          </Dropzone>
        )}
      </React.Fragment>
    );
  }
}

Upload.propTypes = {
  ...Dropzone.propTypes,
  url: PropTypes.string.isRequired,
  /**
   * Called when all the requests have been enqueued.
   */
  onStart: PropTypes.func,
  /**
   * Called when the request for a single file is finished.
   */
  onSuccess: PropTypes.func,
  /**
   * Called when all the request for a file is finished with error.
   */
  onError: PropTypes.func,
  /**
   * Called when the requests for all files are finished.
   */
  onAllFinished: PropTypes.func,
  /**
   * Called when a file has been completely sent to the server, but the request
   * maybe still alive.
   */
  onUploadFinished: PropTypes.func,
  /**
   * Optional. If provided, it will be called before submitting the files.
   * Should return true to proceed or false to cancel the upload.
   *
   * @param array of file objects
   */
  onAskConfirmation: PropTypes.func,
  /**
   * Max number of files to upload simultaneously.
   */
  maxFiles: PropTypes.number,
};

Upload.defaultProps = {
  maxFiles: 5,
};

export default withNamespaces()(Upload);
