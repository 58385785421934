import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Config from '../../config';
import Upload from '../../common/components/upload/Upload';
import Modal from '../../common/containers/Modal';
import URLS from '../../urls';
import Ajax from '../../common/ajax';
import { withNamespaces } from 'react-i18next';
import Waiting from '../../common/containers/Waiting';

class PdfUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      processing: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.removePDF = this.removePDF.bind(this);
  }

  openModal() {
    this.setState({
      ...this.state,
      showModal: true,
    });
  }

  closeModal() {
    this.setState({
      ...this.state,
      showModal: false,
    });
  }

  renderUploadModal() {
    return (
      <Upload
        url={`${Config.apiHost}references/${this.props.reference.id}/upload_pdf/`}
        onSuccess={() => {
          this.closeModal();
          if (this.props.onUpdateEnd) {
            this.props.onUpdateEnd();
          }
        }}
        onStart={() =>
          this.setState((state) => ({ ...state, processing: true }))
        }
        onAllFinished={() =>
          this.setState((state) => ({ ...state, processing: false }))
        }
        onFileDialogCancel={this.closeModal}
        accept={['.pdf']}
        multiple={false}
        maxSize={10000000}
      />
    );
  }

  removePDF() {
    const { i18n } = this.props;
    if (!confirm(i18n.t('Deseja realmente remover esse pdf?'))) {
      return;
    }
    if (this.props.onUpdateStart) {
      this.props.onUpdateStart();
    }
    const url = `${Config.apiHost}references/${this.props.reference.id}/remove_pdf/`;
    Ajax.delete_(url).done(() => {
      if (this.props.onUpdateEnd) {
        this.props.onUpdateEnd();
      }
    });
  }

  renderPdfButton() {
    const { i18n } = this.props;
    return (
      <div className="btn-group btn-group-xs">
        <Link
          to={URLS.referencesPDF(this.props.reference.id)}
          title={i18n.t('Abrir PDF')}
          className="btn btn-default">
          <i className="icon mdi mdi-collection-pdf" />
          pdf
        </Link>
        <button
          type="button"
          className="btn btn-default"
          aria-expanded="false"
          title={i18n.t('Remover esse PDF')}
          onClick={this.removePDF}>
          <span className="close" style={{ fontSize: '18px' }}>
            &times;
          </span>
        </button>
      </div>
    );
  }

  renderUploadButton() {
    const { i18n } = this.props;
    return (
      <>
        <button
          onClick={this.openModal}
          title={i18n.t('Carregar o PDF da referência')}
          className="btn btn-default btn-xs">
          <i className="icon mdi mdi-collection-pdf" />
          Inserir pdf
        </button>
        <Modal
          title={i18n.t('Inserir PDF')}
          show={this.state.showModal}
          onCancel={this.closeModal}>
          <Waiting isProcessing={this.state.processing}>
            {this.state.showModal && this.renderUploadModal()}
          </Waiting>
        </Modal>
      </>
    );
  }

  render() {
    if (this.props.reference.pdf) {
      return this.renderPdfButton();
    } else {
      return this.renderUploadButton();
    }
  }
}

PdfUpload.propTypes = {
  reference: PropTypes.object.isRequired,
  onUpdateStart: PropTypes.func,
  onUpdateEnd: PropTypes.func,
};

export default withNamespaces()(PdfUpload);
