import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/containers/Modal';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import { withNamespaces } from 'react-i18next';
import Checkbox from '../../../common/components/form/Checkbox';
import Button from '../../../common/components/Button';
import Events from '../Events';

class CrossrefBase extends Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = {
      showModal: false,
      referencesMap: {},
      processing: false,
      selected: {},
    };
    this.addMenuItem = this.addMenuItem.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.insertReference = this.insertReference.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.attributes = {
      content: i18n.t('Capítulos/Seções'),
      appendix: i18n.t('Apêndices'),
      attachment: i18n.t('Anexos'),
    };
  }

  componentDidMount() {
    this.addMenuItem();
  }

  showModal() {
    this.loadReferences();
    this.setState((state) => ({
      ...state,
      showModal: true,
      selected: {},
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  }

  addMenuItem() {
    const menuItemName = this.props.menuName.split().join('');

    if (this.props.editor.getMenuItem(menuItemName)) {
      return;
    }

    this.props.editor.on('afterCommandExec', (evt) => {
      if (evt.data.name === this.props.commandName) {
        this.showModal();
      }
    });

    this.props.editor.addMenuGroup(menuItemName);
    this.props.editor.addMenuItem(menuItemName, {
      label: this.props.menuName,
      group: menuItemName,
      command: this.props.commandName,
      icon: `${this.props.editor.plugins.fastformatCitation.path}icons/hidpi/${this.props.icon}.png`,
      order: -1,
    });

    this.props.editor.contextMenu.addListener((element) => {
      if (
        element.getAttribute('contenteditable') === 'false' ||
        element.getName() === 'div' ||
        element.getName() === 'img' ||
        element.getName() === 'figure' ||
        element.getName() === 'figcaption' ||
        element.getName() === 'caption'
      ) {
        return {
          [menuItemName]: CKEDITOR.TRISTATE_DISABLED,
        };
      }

      return {
        [menuItemName]: CKEDITOR.TRISTATE_OFF,
      };
    });
  }

  loadReferences() {
    const referencesMap = {};
    const remoteAttrs = [];

    Object.keys(this.attributes).forEach((attr) => {
      if (attr !== this.props.currentAttribute) {
        remoteAttrs.push(attr);
      }
    });

    this.setState((state) => ({
      ...state,
      processing: true,
    }));

    const url = `${Config.apiHost}documents/${this.props.document.id}/`;
    const fields = remoteAttrs.join(',');
    Ajax.get(url, fields).done((document) => {
      referencesMap[this.props.currentAttribute] = this.props.getReferences(this.props.editor.getData());

      Object.keys(document).forEach((attribute) => {
        referencesMap[attribute] = this.props.getReferences(document[attribute]);
      });

      this.setState((state) => ({
        ...state,
        referencesMap,
        processing: false,
      }));
    });
  }

  insertReference(reference) {
    this.props.insertCrossref(reference);
    this.closeModal();
    this.props.editor.fire(Events.SAVE_AND_COMPILE);
  }

  onSelect(obj) {
    if (obj.reference === this.state.selected.reference) {
      this.setState((state) => ({ ...state, selected: {} }));
    } else {
      this.setState((state) => ({ ...state, selected: obj }));
    }
  }

  renderTabs() {
    return Object.keys(this.attributes).map((attr) => {
      const tabId = `${attr}CrossrefTab`;
      return (
        <li className={attr === 'content' ? 'active' : ''} key={tabId}>
          <a href={`#${tabId}`} data-toggle="tab">
            {this.attributes[attr]}
          </a>
        </li>
      );
    });
  }

  renderTabContents() {
    return Object.keys(this.state.referencesMap).map((attr) => {
      const tabId = `${attr}CrossrefTab`;
      return (
        <div key={tabId} id={tabId} className={`tab-pane ${attr === 'content' ? 'active' : ''}`}>
          {this.state.referencesMap[attr].map((ref, idx) => (
            <Checkbox
              id={ref.reference}
              key={ref.reference}
              checked={this.state.selected.reference === ref.reference}
              label={ref.caption}
              onChange={() => this.onSelect(ref)}
              asFormGroup={false}
            />
          ))}
        </div>
      );
    });
  }

  renderFooter() {
    const { i18n } = this.props;
    return (
      <Button
        onClick={() => this.insertReference(this.state.selected)}
        disabled={!this.state.selected.reference}
        size="lg"
        type="success"
      >
        {i18n.t('Confirmar')}
      </Button>
    );
  }

  render() {
    return (
      <Modal
        key={this.props.title}
        title={this.props.title}
        show={this.state.showModal}
        onCancel={this.closeModal}
        isProcessing={this.state.processing}
        footer={this.renderFooter()}
      >
        <div className="tab-container">
          <ul className="nav nav-tabs">{this.renderTabs()}</ul>
          <div className="tab-content">{this.renderTabContents()}</div>
        </div>
      </Modal>
    );
  }
}

CrossrefBase.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  currentAttribute: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  menuName: PropTypes.string.isRequired,
  commandName: PropTypes.string.isRequired,
  getReferences: PropTypes.func.isRequired,
  insertCrossref: PropTypes.func.isRequired,
  icon: PropTypes.oneOf(['header', 'image', 'table']).isRequired,
};

export default withNamespaces()(CrossrefBase);
