import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

class StyleSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { i18n } = this.props;
    return (
      <select
        id="citation-format"
        name="style"
        className="form-control"
        value={this.props.value}
        onChange={this.props.onInputChange}
        style={{ height: '40px', padding: '0px 8px' }}
      >
        <option value="parenthesis">{i18n.t('Entre parênteses')}</option>
        <option value="narrative">{i18n.t('Parte da narrativa')}</option>
        {this.props.totalReferences === 1 && (
          <option value="parenthesis-resume">{i18n.t('Entre parênteses com meu resumo')}</option>
        )}
        {this.props.document.is_abnt_based && (
          <React.Fragment>
            <option value="idem" className="abnt-latin-cite-option">
              Id. (Idem)
            </option>
            <option value="ibidem" className="abnt-latin-cite-option">
              Ibid. (Ibidem)
            </option>
            <option value="opuscitatum" className="abnt-latin-cite-option">
              op. cit. (opus citatum)
            </option>
            <option value="passim" className="abnt-latin-cite-option">
              passim (passim)
            </option>
            <option value="lococitato" className="abnt-latin-cite-option">
              loc. cit. (loco citato)
            </option>
            <option value="confira" className="abnt-latin-cite-option">
              Cf. (confira)
            </option>
            <option value="etsequentia" className="abnt-latin-cite-option">
              et seq. (et sequentia)
            </option>
          </React.Fragment>
        )}
      </select>
    );
  }
}

StyleSelect.propTypes = {
  document: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  totalReferences: PropTypes.number,
};

StyleSelect.defaultProps = {
  value: 'parenthesis',
};

export default withNamespaces()(StyleSelect);
