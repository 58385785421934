import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CrossrefBase from "./CrossrefBase";
import {withNamespaces} from "react-i18next";

class CrossrefTable extends Component {
    constructor(props) {
        super(props);
        this.getSectionReferences = this.getTableReferences.bind(this);
        this.insertCrossref = this.insertCrossref.bind(this);
    }

    getTableReferences(content) {
        const $content = $(`<div>${content}</div>`);
        const references = [];

        $("table[reference]", $content).each(function (i, el) {
            const $textTable = $(el);
            const itemName = $textTable.find('caption').text().trim();
            if (!itemName) {
                return;
            }
            const itemValue = $textTable.attr('reference');
            references.push({
                caption: itemName,
                reference: itemValue
            });
        });

        // Find figures inserted as tables
        $('img[data-list=table][reference]', $content).each(function (idx, img) {
            const $img = $(img);
            const itemName = $img.closest('figure').find('figcaption').text().trim();
            if (!itemName) {
                return;
            }
            const itemValue = $img.attr('reference');
            references.push({
                caption: itemName,
                reference: itemValue
            });
        });

        return references;
    }

    insertCrossref(reference) {
        const el = [
            `<span contenteditable="false" class="table-reference" title="${reference.caption}">`,
            `<ff-table-reference target-reference="${reference.reference}">{tab:${reference.reference}}</ff-table-reference>`,
            `</span>`
        ].join('');
        this.props.editor.insertHtml(el);
    }

    render() {
        const {i18n} = this.props;
        return (
            <CrossrefBase
                document={this.props.document}
                editor={this.props.editor}
                currentAttribute={this.props.currentAttribute}
                title={i18n.t('Selecione uma tabela para referenciar')}
                menuName={i18n.t('Inserir referências de tabelas')}
                commandName="crossRefTableCommand"
                icon="table"
                getReferences={this.getTableReferences}
                insertCrossref={this.insertCrossref} />
        );
    }
}

CrossrefTable.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
    currentAttribute: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
};

export default withNamespaces()(CrossrefTable);
