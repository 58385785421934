import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Config from "../../../config";
import Ajax from "../../../common/ajax";
import Events from "../Events";
import debounce from 'lodash.debounce';
import {withNamespaces} from "react-i18next";

class CitationWidgetUpdater extends Component {
    constructor(props) {
        super(props);
        this.updateReadyWidgets = debounce(this.updateReadyWidgets.bind(this), 300);
        this.updateCiteNode = this.updateCiteNode.bind(this);
        this.updatedRefsOnReady = [];
        this.widgets = [];
    }

    componentDidMount() {
        this.props.editor.on(Events.CITATION_WIDGET_READY, evt => {
            const widget = evt.data;
            this.widgets.push(widget.element.$);
        });

        $(this.props.editor.editable().$).on('scroll.citation.widget', () => {
            this.updateReadyWidgets();
        });

        this.props.editor.on(Events.AFTER_REFERENCE_UPDATE, evt => {
            const refId = evt.data;
            const node = document.querySelector(`[data-reference-id="${refId}"]`);
            if (node) {
                this.updateCiteNode(node);
            }
        });
    }

    updateReadyWidgets() {
        this.widgets.forEach(widget => {
            if ($(widget).withinviewport().length > 0) {
                const nodes = widget.querySelectorAll('ff-cite');
                for (let i = 0; i < nodes.length; i++) {
                    const ffCiteNode = nodes[i];
                    const id = ffCiteNode.getAttribute('data-reference-id');
                    if (this.updatedRefsOnReady.indexOf(id) === -1) {
                        this.updatedRefsOnReady.push(id);
                        this.updateCiteNode(ffCiteNode);
                    }
                }
            }
        });
    }

    updateCiteNode(ffCiteNode) {
        const {i18n} = this.props;
        const removedTitle = i18n.t('Referência removida');
        const referenceId = ffCiteNode.getAttribute('data-reference-id');
        const url = `${Config.apiHost}documents/${this.props.document.id}/mount_citation/`;
        const data = {
            ids: [referenceId],
            style: 'narrative',
        };
        Ajax.postJSON(url, data).done(response => {
            const {citation_text, title} = response.citation_data;
            if (ffCiteNode && citation_text !== ffCiteNode.innerText) {
                ffCiteNode.innerText = citation_text;
                console.log('Updated citation text:', citation_text);
            }
            if (ffCiteNode && title !== ffCiteNode.getAttribute('title')) {
                ffCiteNode.setAttribute('title', title);
                console.log('Updated citation title:', title);
            }
        }).fail(jqXHR => {
            if (jqXHR.status === 404) { // Not found
                if (ffCiteNode.innerText !== '??') {
                    ffCiteNode.innerText = '??';
                }
                if (ffCiteNode.getAttribute('title') !== removedTitle) {
                    ffCiteNode.setAttribute('title', removedTitle);
                }
            }
        });
    }

    render() {
        return null;
    }
}

CitationWidgetUpdater.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
};

export default withNamespaces()(CitationWidgetUpdater);