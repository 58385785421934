import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from '../../common/containers/Modal';
import HtmlUtils from '../../common/htmlutils';
import CKEditor from '../ckeditor/CKEditor';
import Citation from './citation/Citation';
import CitationWidgetUpdater from './citation/CitationWidgetUpdater';
import Events from './Events';
import Formula from './formula/Formula';

const FOOTNOTE_COMMAND_NAME = 'fastformatFootnote';

class Footnote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      editorInstance: null,
      footnote: '',
      footnoteElement: null,
    };

    this.toggleCommandState = this.toggleCommandState.bind(this);
    this.openFootnoteModal = this.openFootnoteModal.bind(this);
    this.closeFootnoteModal = this.closeFootnoteModal.bind(this);
    this.onCKEditorInstanceReady = this.onCKEditorInstanceReady.bind(this);
    this.insertFootnote = this.insertFootnote.bind(this);
  }

  componentDidMount() {
    this.props.editor.on(Events.INSERT_FOOTNOTE, this.openFootnoteModal);
    this.props.editor.on('selectionChange', this.toggleCommandState);
    this.props.editor.on(Events.EDIT_FOOTNOTE, this.openFootnoteModal);
  }

  openFootnoteModal(editorEvent) {
    let footnote = '';
    if (editorEvent.data) {
      const element = editorEvent.data.querySelector('.ff-footnote');
      if (element) {
        footnote = element.innerHTML;
      }
    }

    this.setState((state) => ({
      ...state,
      footnote,
      showModal: true,
      footnoteElement: editorEvent.data,
    }));
  }

  closeFootnoteModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      footnote: '',
      footnoteElement: null,
    }));
  }

  toggleCommandState() {
    const disallowedTags = ['table', 'figure', 'h1', 'h2', 'h3', 'h4', 'h5'];
    const element = this.props.editor.getSelection().getStartElement();
    const command = this.props.editor.getCommand(FOOTNOTE_COMMAND_NAME);
    disallowedTags.some((tagName) => {
      const isInside = element.getAscendant(tagName, true) !== null;
      if (isInside) {
        command.setState(CKEDITOR.TRISTATE_DISABLED);
      } else {
        command.setState(CKEDITOR.TRISTATE_OFF);
      }
      return isInside; // Stops if true.
    });

    if (element.$.firstChild && element.$.firstChild.nodeName === 'FIGURE') {
      command.setState(CKEDITOR.TRISTATE_DISABLED);
    }
  }

  insertFootnote() {
    const { i18n } = this.props;
    const footnote = this.state.editorInstance.getData();
    const title = HtmlUtils.stripHtmlTags(footnote);

    if (this.state.footnoteElement) {
      // Is editing
      this.props.editor.fire('saveSnapshot');
      this.state.footnoteElement.setAttribute('title', title);
      const footnoteElement = this.state.footnoteElement.querySelector('.ff-footnote');
      if (footnoteElement) {
        footnoteElement.innerHTML = footnote;
      }
    } else {
      // Create new
      const footnoteTag = [
        `<sup class="btn-link ff-footnote" contenteditable="false" style="cursor: pointer;" title="${title}">`,
        `<span class="ff-footnote-label" contenteditable="false">${i18n.t('note')}</span>`,
        `<span class="ff-footnote" style="display: none;">${footnote}</span>`,
        `</sup>`,
      ].join('');
      this.props.editor.fire('saveSnapshot');
      this.props.editor.insertHtml(footnoteTag);
    }
    this.closeFootnoteModal();
  }

  onCKEditorInstanceReady(evt) {
    this.setState((state) => ({ ...state, editorInstance: evt.editor }));
  }

  renderModalFooter() {
    const { i18n } = this.props;
    return (
      <>
        <button className="btn btn-default btn-lg" onClick={this.closeFootnoteModal}>
          {i18n.t('Cancelar')}
        </button>
        <button className="btn btn-primary btn-lg" onClick={this.insertFootnote}>
          {this.state.footnoteElement ? i18n.t('Atualizar') : i18n.t('Inserir')}
        </button>
      </>
    );
  }

  render() {
    const { i18n } = this.props;

    return (
      <>
        <Modal
          title={i18n.t('Inserir nota de rodapé')}
          show={this.state.showModal}
          onCancel={this.closeFootnoteModal}
          footer={this.renderModalFooter()}
        >
          <CKEditor
            id="ckeditor-footnote"
            content={this.state.footnote}
            languageCode={i18n.language}
            config={{
              toolbar: 'FootnoteToolbar',
              enterMode: CKEDITOR.ENTER_BR,
              readOnly: false,
              extraPlugins: [
                'fastformatCitation',
                'fastformatProofread',
                'fastformatCleanerV2',
                'fastformatLink',
                'fastformatFormula',
                'fastformatDisableGrammarly',
                'divarea',
                'notificationaggregator',
              ].join(','),
              on: {
                instanceReady: this.onCKEditorInstanceReady,
              },
            }}
          />
        </Modal>
        {this.props.document && this.state.editorInstance && (
          <Citation
            id="footnoteCitation"
            key={this.state.editorInstance.id + '-citation'}
            document={this.props.document}
            editor={this.state.editorInstance}
          />
        )}

        {this.props.document && this.state.editorInstance && (
          <CitationWidgetUpdater
            key={this.state.editorInstance.id + '-citation-widget'}
            document={this.props.document}
            editor={this.state.editorInstance}
          />
        )}

        {this.props.document && this.state.editorInstance && (
          <Formula
            key={this.state.editorInstance.id + '-formula'}
            document={this.props.document}
            editor={this.state.editorInstance}
          />
        )}
      </>
    );
  }
}

Footnote.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Footnote);
