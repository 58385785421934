import React from 'react';
import PropTypes from 'prop-types';
import Waiting from '../../common/containers/Waiting';
import Ajax from '../../common/ajax';
import Config from '../../config';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import { withNamespaces } from 'react-i18next';

class ReferencePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      styles: null,
      processingPreview: false,
      preview_style: 'abnt',
      previewData: {
        html: null,
        text: null,
        png: null,
      },
      copied: false,
      reference: null,
      error: false,
    };
    this.onPreviewStyleChange = this.onPreviewStyleChange.bind(this);
    this.fetchPreview = this.fetchPreview.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.previewTimeout = null;
    this.previewXhr = null;
  }

  componentDidMount() {
    Ajax.get(`${Config.apiHost}references/preview_v2/`).done((styles) =>
      this.setState((s) => ({ ...s, styles }))
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.reference && this.props.reference) {
      this.fetchPreview();
    } else {
      const prevKey = JSON.stringify(prevProps.reference);
      const currentKey = JSON.stringify(this.props.reference);
      if (prevKey !== currentKey) {
        this.setState(
          (state) => ({
            ...state,
            reference: {
              ...this.props.reference,
              preview_style: state.preview_style,
            },
          }),
          this.fetchPreview
        );
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.previewTimeout);
    if (this.previewXhr) {
      this.previewXhr.abort();
    }
  }

  onPreviewStyleChange(event) {
    this.setState(
      (state) => ({
        ...state,
        preview_style: event.target.value,
        reference: {
          ...state.reference,
          preview_style: event.target.value,
        },
      }),
      () => {
        clearTimeout(this.previewTimeout);
        this.previewTimeout = setTimeout(this.fetchPreview, 300);
      }
    );
  }

  fetchPreview(e) {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.reference || !this.state.reference.title) {
      return;
    }

    this.setState((state) => ({ ...state, processingPreview: true }));

    const url = `${Config.apiHost}references/preview_v2/`;
    this.previewXhr = Ajax.post(url, this.state.reference)
      .done((data) => {
        this.setState((state) => ({
          ...state,
          previewData: data,
          processingPreview: false,
          error: false,
        }));
      })
      .fail(() => {
        this.setState((state) => ({
          ...state,
          error: true,
          processingPreview: false,
        }));
      });
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.state.previewData.text);
    this.setState((s) => ({ ...s, copied: true }));
    setTimeout(() => {
      this.setState((s) => ({ ...s, copied: false }));
    }, 150);
  }

  renderButton(label, value) {
    const { preview_style: current } = this.state;
    return (
      <button
        key={value}
        onClick={(e) => {
          this.onPreviewStyleChange({ target: { value } });
          e.target.blur();
        }}
        className={`btn btn-default`}
        type="button"
      >
        {value === current && (
          <>
            <i className="mdi mdi-check-circle text-primary" />{' '}
          </>
        )}
        {label}
      </button>
    );
  }

  render() {
    const { i18n } = this.props;
    const { previewData, error, copied, styles, processingPreview } =
      this.state;
    return (
      <Waiting isProcessing={processingPreview}>
        <Row>
          <Col md={12}>
            <div className="btn-toolbar">
              <div className="btn-group btn-group-sm">
                <button className="btn btn-muted" type="button">
                  Previsualizar:
                </button>

                {styles &&
                  styles.map((style) =>
                    this.renderButton(style.label, style.value)
                  )}

                <button
                  className="btn btn-muted btn-xs"
                  onClick={this.fetchPreview}
                  title={i18n.t('Atualizar previsualização')}
                >
                  <i className="mdi mdi-refresh" />
                </button>
              </div>

              <div className="btn-group btn-group-sm">
                <a
                  className={`btn btn-default btn-xs ${
                    copied ? 'btn-primary' : ''
                  }`}
                  onClick={this.copyToClipboard}
                  role="button"
                  title={i18n.t('Copiar texto para Área de Transferência')}
                >
                  <i className="mdi mdi-copy" />{' '}
                  <span className="xs-hidden">{i18n.t('Copiar')}</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {!error && previewData && previewData.png && (
              <>
                <img
                  className="preview-box-shadow"
                  id="referencePreviewImageB64"
                  src={`data:image/png;base64,${previewData.png}`}
                />
              </>
            )}

            {error && (
              <span className="text-danger">
                <i className="icon mdi mdi-alert-triangle" />
                &nbsp;
                {i18n.t('Não foi possível gerar a pré-visualização')}
              </span>
            )}
          </Col>
        </Row>
      </Waiting>
    );
  }
}

ReferencePreview.propTypes = {
  /**
   * The reference object which will have the preview generated.
   */
  reference: PropTypes.object.isRequired,
};

export default withNamespaces()(ReferencePreview);
