import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Button from '../../../common/components/Button';
import LazyImage from '../../../common/components/LazyImage';
import Col from '../../../common/containers/Col';
import Modal from '../../../common/containers/Modal';
import Row from '../../../common/containers/Row';
import Input from '../../../common/components/form/Input';
import Events from '../Events';
import MediaBrowser from './MediaBrowser';
import Select from '../../../common/components/form/Select';
import Checkbox from '../../../common/components/form/Checkbox';

const INCLUDE_PDF_COMMAND_NAME = 'fastformatIncludePDFCommand';

class IncludePDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      editorInstance: null,
      htmlElement: null,
      showMediaBrowser: false,
      image: null,
      form: {
        pages: '',
        rotate: '',
        respectMargins: true,
        landscape: false,
        tocSectionType: '',
        tocTitle: '',
      },
    };

    this.toggleCommandState = this.toggleCommandState.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeMediaBrowser = this.closeMediaBrowser.bind(this);
    this.configureInlinePreview = this.configureInlinePreview.bind(this);
    this.insertHTML = this.insertHTML.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.validatePages = this.validatePages.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitRef = React.createRef();
  }

  componentDidMount() {
    this.props.editor.on(Events.INCLUDE_PDF_OPEN, this.openModal);
    this.props.editor.on('selectionChange', this.toggleCommandState);
    this.props.editor.on('dataReady', this.configureInlinePreview);
    this.configureInlinePreview();
  }

  openModal(editorEvent) {
    let image = null;
    let htmlElement = editorEvent.data;
    let { form } = this.state;
    let openMediaBrowser = false;

    if (htmlElement) {
      // Editing
      const url = htmlElement.getAttribute('data-file') || '';
      const urlParts = url.split('/');
      image = {
        id: htmlElement.getAttribute('data-id'),
        thumbnail: htmlElement.getAttribute('data-thumbnail'),
        filename: urlParts[urlParts.length - 1],
        file: htmlElement.getAttribute('data-file'),
        page_count: parseInt(htmlElement.getAttribute('data-pagecount')),
      };

      form = {
        ...form,
        pages: htmlElement.getAttribute('data-pages'),
        rotate: htmlElement.getAttribute('data-rotate'),
        respectMargins: htmlElement.getAttribute('data-fullpage') !== 'true',
        landscape: htmlElement.getAttribute('data-landscape') === 'true',
        tocSectionType: htmlElement.getAttribute('data-tocsection'),
        tocTitle: htmlElement.getAttribute('data-toctitle'),
      };
    } else {
      // New PDF
      openMediaBrowser = true;
    }

    this.setState((state) => ({
      ...state,
      showModal: true,
      showMediaBrowser: openMediaBrowser,
      htmlElement,
      image,
      form,
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      htmlElement: null,
    }));
  }

  closeMediaBrowser() {
    this.setState((s) => ({ ...s, showMediaBrowser: false }));
    if (!this.state.image) {
      this.closeModal();
    }
  }

  configureInlinePreview() {
    this.props.editor
      .editable()
      .$.querySelectorAll('ff-include-pdf')
      .forEach((element) => {
        const thumb = element.getAttribute('data-thumbnail');
        if (element.style.backgroundImage !== thumb) {
          this.props.editor.fire('lockSnapshot');
          element.style.backgroundImage = `url(${thumb})`;
          element.style.backgroundRepeat = 'no-repeat';
          element.style.backgroundSize = 'contain';
          element.style.padding = '20px 0px 20px 80px';
          element.style.textIndent = '0px';
          this.props.editor.fire('unlockSnapshot');
        }
      });
  }

  toggleCommandState() {
    const command = this.props.editor.getCommand(INCLUDE_PDF_COMMAND_NAME);
    if (!command) {
      return;
    }
    const element = this.props.editor.getSelection().getStartElement();
    const disallowedTags = ['table', 'figure', 'h1', 'h2', 'h3', 'h4', 'h5'];
    disallowedTags.some((tagName) => {
      const isInside = element.getAscendant(tagName, true) !== null;
      if (isInside) {
        command.setState(CKEDITOR.TRISTATE_DISABLED);
      } else {
        command.setState(CKEDITOR.TRISTATE_OFF);
      }
      return isInside; // Stops if true.
    });
    if (element.$.firstChild && element.$.firstChild.nodeName === 'FIGURE') {
      command.setState(CKEDITOR.TRISTATE_DISABLED);
    }
  }

  insertHTML() {
    const { i18n } = this.props;
    const { id, file, filename, thumbnail, page_count } = this.state.image;
    const {
      pages,
      rotate,
      respectMargins,
      landscape,
      tocSectionType,
      tocTitle,
    } = this.state.form;

    const text = `<b>${i18n.t('Arquivo PDF inserido')}:</b><br/>${filename}`;

    if (this.state.htmlElement) {
      this.props.editor.fire('saveSnapshot');
      this.state.htmlElement.setAttribute('data-id', id);
      this.state.htmlElement.setAttribute('data-thumbnail', thumbnail);
      this.state.htmlElement.setAttribute('data-file', file);
      this.state.htmlElement.setAttribute('data-pages', pages);
      this.state.htmlElement.setAttribute('data-rotate', rotate);
      this.state.htmlElement.setAttribute('data-fullpage', !respectMargins);
      this.state.htmlElement.setAttribute('data-landscape', landscape);
      this.state.htmlElement.setAttribute('data-tocsection', tocSectionType);
      this.state.htmlElement.setAttribute('data-toctitle', tocTitle);
      this.state.htmlElement.setAttribute('data-pagecount', page_count);
      this.state.htmlElement.innerHTML = text;
    } else {
      this.props.editor.fire('saveSnapshot');
      this.props.editor.insertHtml(
        `<p><ff-include-pdf 
          class="ff-include-pdf"   
          data-file="${file}" 
          data-id="${id}" 
          data-thumbnail="${thumbnail}" 
          data-pages="${pages}"
          data-rotate="${rotate}"
          data-fullpage="${!respectMargins}"
          data-landscape="${landscape}"
          data-tocsection="${tocSectionType}"
          data-toctitle="${tocTitle}"
          data-pagecount="${page_count}"
        >
            ${text}
        </ff-include-pdf></p>`
      );
    }
    this.configureInlinePreview();
    this.closeModal();
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  }

  validatePages(value) {
    if (!value) {
      return null;
    }

    const { i18n } = this.props;
    const { page_count } = this.state.image || { page_count: 0 };

    const pages = value
      .split(',')
      .map((i) => i.trim())
      .filter((i) => !!i);

    let passed = true;

    pages.length &&
      pages.forEach((p) => {
        if (p.indexOf('-') > -1) {
          // Validate ranges
          const [start, end] = p.split('-');
          if (
            (start && (isNaN(start) || parseInt(start) < 1)) ||
            (end && (isNaN(end) || parseInt(end) < 1))
          ) {
            passed = false;
            return false;
          }
          if (parseInt(start) > parseInt(end)) {
            passed = false;
            return false;
          }
          if (parseInt(start) > page_count || parseInt(end) > page_count) {
            passed = false;
            return false;
          }
        } else if (isNaN(p) || parseInt(p) < 1 || parseInt(p) > page_count) {
          // Validate single page
          passed = false;
          return false;
        }
      });

    return passed ? null : i18n.t('Formato de páginas inválido.');
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.insertHTML();
  }

  render() {
    const { i18n } = this.props;
    const pagesValidationMsg = this.validatePages(this.state.form.pages);

    return (
      <>
        <Modal
          title={i18n.t('Inserir arquivo PDF')}
          show={this.state.showModal}
          onCancel={this.closeModal}
          footer={
            <>
              <button
                className="btn btn-default btn-lg"
                onClick={this.closeModal}
              >
                {i18n.t('Cancelar')}
              </button>
              <button
                className="btn btn-primary btn-lg"
                onClick={() => this.submitRef.current.click()}
                disabled={!this.state.image || !!pagesValidationMsg}
              >
                {i18n.t('Confirmar')}
              </button>
            </>
          }
          width="large"
        >
          <form onSubmit={this.onSubmit}>
            <input type="submit" hidden={true} ref={this.submitRef} />
            <Row>
              <Col md={6}>
                {this.state.image && (
                  <div className="thumbnail">
                    <LazyImage
                      key={JSON.stringify(this.state.image)}
                      src={this.state.image.thumbnail}
                      alt={this.state.image.filename}
                      title={this.state.image.filename}
                    />
                  </div>
                )}
              </Col>

              <Col md={6}>
                <Button
                  onClick={() => {
                    this.setState((s) => ({ ...s, showMediaBrowser: true }));
                  }}
                  type="default"
                  size="lg"
                >
                  <i className="icon mdi mdi-collection-pdf" />{' '}
                  {i18n.t('Selecionar arquivo')}
                </Button>
                {this.state.image && (
                  <>
                    {this.state.image.page_count > 0 && (
                      <p>
                        <b>{this.state.image.filename}</b>
                        <br />
                        {this.state.image.page_count} {i18n.t('página(s)')}
                      </p>
                    )}
                    <hr />
                    <h4>{i18n.t('Opções')}</h4>
                    <Input
                      name="pages"
                      value={this.state.form.pages}
                      id="pages"
                      label={i18n.t('Páginas')}
                      placeholder="Ex.: 1,2,3,8-11,15"
                      maxLength="500"
                      onChange={this.onInputChange}
                      help={i18n.t(
                        'Seleciona as páginas para inserir (ex. 2,3,4-9). Se não informado, todas as páginas serão incluídas.'
                      )}
                      error={pagesValidationMsg}
                    />

                    <Select
                      name="rotate"
                      id="rotate"
                      label={i18n.t('Girar')}
                      value={this.state.form.rotate}
                      options={[
                        { label: i18n.t('Girar à esquerda'), value: 90 },
                        { label: i18n.t('Girar à direita'), value: 270 },
                        {
                          label: i18n.t('Inverter na vertical'),
                          value: 180,
                        },
                      ]}
                      onChange={this.onInputChange}
                      help={i18n.t(
                        'Gira todas as páginas selecionadas na direção indicada.'
                      )}
                    />

                    <Checkbox
                      id="landscape"
                      name="landscape"
                      checked={this.state.form.landscape}
                      label={i18n.t('Modo paisagem')}
                      onChange={this.onInputChange}
                    />

                    <Checkbox
                      id="respectMargins"
                      name="respectMargins"
                      checked={this.state.form.respectMargins}
                      label={i18n.t('Respeitar margens')}
                      onChange={this.onInputChange}
                    />
                  </>
                )}
              </Col>
            </Row>

            {this.state.showMediaBrowser && (
              <MediaBrowser
                documentId={this.props.document.id}
                editor={this.props.editor}
                mediaTypes={['pdf']}
                onImageClick={(img) => {
                  this.setState((s) => ({
                    ...s,
                    showMediaBrowser: false,
                    image: { ...img },
                  }));
                }}
                onClose={() => this.closeMediaBrowser()}
              />
            )}
          </form>
        </Modal>
      </>
    );
  }
}

IncludePDF.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(IncludePDF);
