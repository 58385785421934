import PropTypes from 'prop-types';
import React from 'react';
import truncate from 'underscore.string/truncate';
import ButtonDropdown from '../../../common/components/ButtonDropdown';
import SpinnerIcon from '../../../common/components/SpinnerIcon';
import Events from '../Events';
import { withNamespaces } from 'react-i18next';

class PreviewerToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPages: 0,
      zoom: 100,
      currentTOCItem: {},
      showProgress: true,
    };
    this.setPage = this.setPage.bind(this);
    this.onSetTOCItem = this.onSetTOCItem.bind(this);
    this.onSkipNextPage = this.onSkipNextPage.bind(this);
    this.onSkipPreviousPage = this.onSkipPreviousPage.bind(this);
    this.onSkipFirstPage = this.onSkipFirstPage.bind(this);
    this.onSkipLastPage = this.onSkipLastPage.bind(this);
    this.onZoomOut = this.onZoomOut.bind(this);
    this.onZoomIn = this.onZoomIn.bind(this);
    this.scaleImages = this.scaleImages.bind(this);
  }

  componentDidMount() {
    this.props.setPagesFuncRef(this.setPage);

    this.props.editor.on(Events.PREVIEW_SCROLL_TO_FIRST, this.onSkipFirstPage);

    this.props.editor.on(Events.BEFORE_COMPILATION_START, () =>
      this.setState((state) => ({ ...state, showProgress: true })),
    );

    this.props.editor.on(Events.AFTER_COMPILATION_COMPLETE, () =>
      this.setState((state) => ({ ...state, showProgress: false })),
    );
  }

  setPage(currentPage, totalPages) {
    const lastSelectedItem = this.state.currentTOCItem;
    this.setState((state) => ({
      ...state,
      currentPage,
      totalPages,
      currentTOCItem: this.props.tableOfContents.find((t) => t.page === currentPage - 1) || lastSelectedItem,
    }));
  }

  onSetTOCItem(tocItem) {
    this.setState(
      (state) => ({
        ...state,
        currentTOCItem: tocItem,
        currentPage: tocItem.page + 1,
      }),
      () => {
        this.scrollToPage(this.state.currentPage - 1);
      },
    );
  }

  onSkipNextPage() {
    if (this.state.currentPage === this.state.totalPages) {
      return;
    }

    this.setState(
      (state) => ({
        ...state,
        currentPage: state.currentPage + 1,
      }),
      () => {
        this.scrollToPage(this.state.currentPage - 1);
      },
    );
  }

  onSkipPreviousPage() {
    if (this.state.currentPage === 1) {
      return;
    }
    this.setState(
      (state) => ({
        ...state,
        currentPage: state.currentPage - 1,
      }),
      () => {
        this.scrollToPage(this.state.currentPage - 1);
      },
    );
  }

  onSkipFirstPage() {
    this.setState(
      (state) => ({
        ...state,
        currentPage: 1,
      }),
      () => {
        this.scrollToPage(this.state.currentPage - 1);
      },
    );
  }

  onSkipLastPage() {
    this.setState(
      (state) => ({
        ...state,
        currentPage: this.props.thumbs.length,
      }),
      () => {
        this.scrollToPage(this.state.currentPage - 1);
      },
    );
  }

  scrollToPage(thumbIndex) {
    const thumb = this.props.thumbs[thumbIndex];
    if (thumb) {
      const element = document.getElementById(thumb.filename);
      if (element) {
        element.scrollIntoView();
        this.props.scrollContainer.scrollTop -= 8;
      }
    }
  }

  onZoomIn() {
    this.setState(
      (state) => ({
        ...state,
        zoom: state.zoom + 10,
      }),
      this.scaleImages,
    );
  }

  onZoomOut() {
    if (this.state.zoom > 100) {
      this.setState(
        (state) => ({
          ...state,
          zoom: state.zoom - 10,
        }),
        this.scaleImages,
      );
    }
  }

  scaleImages() {
    this.props.thumbs.forEach((thumb) => {
      const imgContainer = document.getElementById(thumb.filename);
      imgContainer.style.width = this.state.zoom + '%';
    });
  }

  render() {
    const { currentPage, totalPages, zoom, showProgress } = this.state;
    let progressPercent;
    if (this.props.progress) {
      progressPercent = this.props.progress > 100 ? '100%' : `${this.props.progress || 1}%`.padStart(3, '0');
    } else {
      progressPercent = '...';
    }

    return (
      <>
        <div
          className="progress"
          style={{
            width: '100%',
            opacity: 0.7,
            zIndex: '900',
            position: 'absolute',
            height: '20px',
            marginRight: '3px',
            display: showProgress ? '' : 'none',
          }}
        >
          <div style={{ width: '100%' }} className="progress-bar progress-bar-primary progress-bar-striped active">
            {progressPercent !== '100%' && (
              <b>{`${this.props.i18n.t('Carregando pré-visualização')} ${progressPercent}`}</b>
            )}
            {progressPercent === '100%' && <b>{this.props.i18n.t('Finalizando')}...</b>}
          </div>
        </div>

        <div
          className="btn-toolbar"
          style={{
            zIndex: '1000',
            position: 'relative',
            width: '100%',
            display: this.state.showProgress ? 'none' : '',
          }}
          onMouseOver={() => (this.toolbar.style.opacity = 1)}
          onMouseLeave={() => (this.toolbar.style.opacity = 0.35)}
        >
          <div
            ref={(el) => (this.toolbar = el)}
            className="btn-group btn-group-sm pull-right"
            style={{ marginRight: '3px' }}
          >
            {this.renderTOCMenu()}

            <button type="button" className="btn btn-muted" onClick={this.onZoomOut}>
              <i className="icon mdi mdi-zoom-out" />
            </button>
            <button type="button" className="btn btn-muted text-muted">
              {zoom}%
            </button>
            <button type="button" className="btn btn-muted" onClick={this.onZoomIn}>
              <i className="icon mdi mdi-zoom-in" />
            </button>

            <button type="button" className="btn btn-muted" onClick={this.onSkipFirstPage}>
              <i className="icon mdi mdi-skip-previous" />
            </button>
            <button type="button" className="btn btn-muted" onClick={this.onSkipPreviousPage}>
              <i className="icon mdi mdi-fast-rewind" />
            </button>
            <button type="button" className="btn btn-muted text-muted">
              {currentPage}/{totalPages}
            </button>
            <button type="button" className="btn btn-muted" onClick={this.onSkipNextPage}>
              <i className="icon mdi mdi-fast-forward" />
            </button>
            <button type="button" className="btn btn-muted" onClick={this.onSkipLastPage}>
              <i className="icon mdi mdi-skip-next" />
            </button>
          </div>
        </div>
      </>
    );
  }

  renderTOCMenu() {
    const { currentTOCItem } = this.state;
    const { tableOfContents } = this.props;
    const truncateLength = 37;
    return (
      <ButtonDropdown
        key={new Date().getTime()}
        label={truncate(currentTOCItem.title, truncateLength) || 'Sumário'}
        type="default"
        size="sm"
        maxHeight="calc(70vh)"
        width="300px"
      >
        {tableOfContents.length === 0 && (
          <li>
            <a role="button">
              <SpinnerIcon /> Carregando sumário...
            </a>
          </li>
        )}
        {tableOfContents.map((t, idx) => (
          <li key={idx} className={currentTOCItem.page === t.page ? 'active' : ''}>
            <a role="button" onClick={() => this.onSetTOCItem(t)}>
              {t.level > 0 && (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: '&ndash;'.repeat(t.level),
                    }}
                  />{' '}
                </>
              )}
              {truncate(t.title, truncateLength)}
            </a>
          </li>
        ))}
      </ButtonDropdown>
    );
  }
}

PreviewerToolbar.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  thumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableOfContents: PropTypes.arrayOf(PropTypes.object),
  setPagesFuncRef: PropTypes.func.isRequired,
  scrollContainer: PropTypes.object.isRequired,
  progress: PropTypes.number,
};

PreviewerToolbar.defaultProps = {
  tableOfContents: [],
};

export default withNamespaces()(PreviewerToolbar);
