import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

class ReferenceAuthorInput extends React.Component {
  constructor(props) {
    super(props);

    const authorObjList = this.props.authors
      ? this.props.authors.map((name) => ({ name }))
      : [{ name: '' }];

    this.state = {
      authors: this.props.multipleAuthors ? authorObjList : [authorObjList[0]],
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onAddAuthor = this.onAddAuthor.bind(this);
    this.onDeleteAuthor = this.onDeleteAuthor.bind(this);
    this.getAuthors = this.getAuthors.bind(this);
  }

  componentDidMount() {
    // Important when user is changing the reference type for testing.
    this.props.onChange(this.state.authors.map((a) => a.name));
  }

  getAuthors() {
    return this.state.authors.map((a) => a.name);
  }

  onInputChange(author, event) {
    author.name = event.target.value;
    this.setState((state) => ({
      ...state,
    }));

    this.props.onChange(this.state.authors.map((a) => a.name));
  }

  onAddAuthor(e) {
    this.state.authors.push({ name: '' });
    this.setState((state) => ({
      ...state,
      authors: state.authors,
    }));
    e.preventDefault();
  }

  onDeleteAuthor(idx, e) {
    this.state.authors.splice(idx, 1);
    this.setState((state) => ({
      ...state,
      authors: state.authors,
    }));
    e.preventDefault();
    this.props.onChange(this.state.authors.map((a) => a.name));
  }

  renderInputs() {
    const inputs = [
      <input
        className="form-control"
        key="key"
        name="author"
        value={this.state.authors[0].name}
        onChange={(e) => this.onInputChange(this.state.authors[0], e)}
      />,
    ];

    this.state.authors.slice(1).forEach((author, idx) => {
      inputs.push(
        <div key={idx} className="input-group" style={{ marginTop: '5px' }}>
          <input
            className="form-control"
            value={author.name}
            name="author"
            onChange={(e) => this.onInputChange(author, e)}
          />
          <div className="input-group-btn">
            <button
              className="btn btn-default"
              type="button"
              onClick={(e) => this.onDeleteAuthor(idx + 1, e)}
            >
              <i className="icon mdi mdi-close" />
            </button>
          </div>
        </div>
      );
    });

    return inputs;
  }

  render() {
    const { i18n, multipleAuthors } = this.props;
    return (
      <>
        {this.renderInputs()}
        {multipleAuthors && (
          <button
            className="btn btn-primary btn-xs"
            style={{ marginTop: '5px' }}
            onClick={this.onAddAuthor}
          >
            {i18n.t('Adicionar outro autor')}
          </button>
        )}
      </>
    );
  }
}

ReferenceAuthorInput.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  multipleAuthors: PropTypes.bool,
};

ReferenceAuthorInput.defaultProps = {
  multipleAuthors: true,
};

export default withNamespaces()(ReferenceAuthorInput);
