import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './editor.leftmenu.css';
import TableOfContents from './TableOfContents';
import Events from '../Events';
import Identification from '../identification/Identification';
import Abbreviation from './Abbreviation';
import Symbol from './Symbol';
import Author from '../author/Author';
import ErrorBoundary from '../../../common/containers/ErrorBoundary';
import { withNamespaces } from 'react-i18next';
import debounce from 'lodash.debounce';

class WordCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalWords: 0,
    };
    this.countWords = debounce(this.countWords.bind(this), 300);
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.props.editor.on('change', this.countWords);
    this.props.editor.on('dataReady', this.countWords);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  countWords() {
    var text = this.props.editor.editable().$.innerText;
    var matches = text.split(' ');
    var totalWords = 0;
    matches.forEach(function (word) {
      if (word.trim()) {
        totalWords++;
      }
    });
    this._mounted && this.setState((state) => ({ ...state, totalWords }));
  }

  render() {
    const { i18n } = this.props;
    const { totalWords } = this.state;

    if (!totalWords) {
      return null;
    }

    return (
      <small>
        {' '}
        ({totalWords}&nbsp;
        {totalWords > 1 ? i18n.t('palavras') : i18n.t('palavra')})
      </small>
    );
  }
}

WordCount = withNamespaces()(WordCount);

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAttribute: null,
      isCustomAttribute: false,
    };
    this._mounted = false;
    this.notification = null;
    this.isChanging = false;
    this.changeAttribute = this.changeAttribute.bind(this);
    this.changeCustomAttribute = this.changeCustomAttribute.bind(this);
    this._changeAttribute = this._changeAttribute.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.selectDocumentMainAttribute();
    this.props.editor.on(Events.CHANGE_ATTRIBUTE, () => {
      if (this.state.isCustomAttribute) {
        this.changeCustomAttribute(this.state.currentAttribute);
      } else {
        this.changeAttribute(this.state.currentAttribute);
      }
    });
  }

  selectDocumentMainAttribute() {
    const selectedAttribute = this.props.document.left_menu_custom_attrs.find((a) => a.is_selected);
    if (selectedAttribute) {
      this.changeCustomAttribute(selectedAttribute.id);
    } else {
      this.changeAttribute('content');
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this.notification && this.notification.hide();
  }

  setStateIfMounted(state, callback) {
    if (this._mounted) {
      this.setState(state, callback);
    }
  }

  changeAttribute(attribute, e) {
    this._changeAttribute(attribute, false, e);
  }

  changeCustomAttribute(id, e) {
    this._changeAttribute(id, true, e);
  }

  _changeAttribute(attribute, isCustom = false, e) {
    if (!this._mounted) {
      return;
    }

    if (!this.props.editor) {
      // Editor was closed.
      return;
    }

    if (this.isChanging) {
      // Prevents changing the attribute while it is changing already.
      return;
    }

    if (e && this.state.currentAttribute === attribute) {
      // Prevents multiple clicks on the same item
      return;
    }

    this.isChanging = true;

    const wasReadonly = this.props.editor.readOnly;

    if (!wasReadonly) {
      this.props.editor.setReadOnly(true);
    }

    if (this.props.onBeforeAttributeChange) {
      this.props.onBeforeAttributeChange(attribute, isCustom);
    }

    if (this.state.currentAttribute) {
      // Save only if any attribute has been loaded.
      this.props.editor.fire(Events.SAVE);
    }

    const { i18n } = this.props;
    this.showNotification(i18n.t('Carregando') + '...');

    const url = this.getAttributeURL(attribute, isCustom);
    const responseField = isCustom ? 'value' : attribute;
    Ajax.get(url, responseField)
      .done((response) => {
        const data = response[responseField];
        this.setStateIfMounted(
          (state) => ({
            ...state,
            currentAttribute: attribute,
            isCustomAttribute: isCustom,
          }),
          () => {
            this.props.onAfterAttributeChange(attribute, isCustom, data);
            this.props.editor.fire(Events.AFTER_ATTRIBUTE_CHANGE);
            this.notification && this.notification.hide();
            if (!wasReadonly && this.props.editor) {
              this.props.editor.setReadOnly(false);
            }
            this.isChanging = false;
          },
        );
      })
      .fail(() => {
        this.showNotification(i18n.t('Não foi possível carregar o conteúdo.'), 'warning');
        this.isChanging = false;
      });
  }

  getAttributeURL(attribute, isCustom) {
    const documentId = this.props.document.id;
    if (isCustom) {
      return `${Config.apiHost}documents/${documentId}/custom_attribute/?attr=${attribute}`;
    } else {
      return `${Config.apiHost}documents/${documentId}/`;
    }
  }

  showNotification(msg, type = 'info') {
    if (this.notification) {
      this.notification.hide();
    }
    this.notification = this.props.editor.showNotification(msg, type);
  }

  renderAttributeMenuItem(id, attr, label, showWordCount = false) {
    const { i18n, editor } = this.props;
    const isActive = this.state.currentAttribute === attr;
    return (
      <li id={id} className={isActive ? 'active' : ''}>
        <a
          role="button"
          onClick={(e) => this.changeAttribute(attr, e)}
          title={i18n.t('Editar') + ' ' + label.toLowerCase()}
        >
          <span className="mdi mdi-file-text" /> {label}
          {isActive && showWordCount && <WordCount editor={editor} />}
        </a>
      </li>
    );
  }

  render() {
    const { i18n, editor, document } = this.props;

    return (
      <React.Fragment>
        <PerfectScrollbar>
          <div id="document-menu">
            <ul className="nav nav-pills nav-stacked">
              <li id="title-menu-item">
                <ErrorBoundary>
                  <Identification document={document} editor={editor} />
                </ErrorBoundary>
              </li>
              {document.template_meta.show_authors && (
                <li>
                  <ErrorBoundary>
                    <Author document={document} editor={editor} />
                  </ErrorBoundary>
                </li>
              )}
              {document.template_meta.show_acronyms && (
                <li>
                  <ErrorBoundary>
                    <Abbreviation document={document} editor={editor} />
                  </ErrorBoundary>
                </li>
              )}
              {document.template_meta.show_symbols && (
                <li>
                  <ErrorBoundary>
                    <Symbol document={document} editor={editor} />
                  </ErrorBoundary>
                </li>
              )}
              {document.template_meta.show_dedicatory &&
                this.renderAttributeMenuItem('dedicatory', 'dedicatory', i18n.t('Dedicatória'))}

              {document.template_meta.show_acknowledgment &&
                this.renderAttributeMenuItem('acknowledgment', 'acknowledgment', i18n.t('Agradecimentos'))}

              {document.template_meta.show_preface &&
                this.renderAttributeMenuItem('preface', 'preface', i18n.t('Prefácio'))}

              {document.template_meta.show_presentation &&
                this.renderAttributeMenuItem('presentation', 'presentation', i18n.t('Apresentação'))}

              {document.template_meta.show_epigraph &&
                this.renderAttributeMenuItem('epigraph', 'epigraph', i18n.t('Epígrafe'))}

              {document.template_meta.show_abstract_translated &&
                this.renderAttributeMenuItem('abstract_translated', 'abstract_translated', i18n.t('Resumo'), true)}

              {document.template_meta.show_abstract &&
                this.renderAttributeMenuItem('abstract', 'abstract', 'Abstract', true)}

              {document.template_meta.show_content &&
                this.renderAttributeMenuItem('id-content-menu-item', 'content', i18n.t('Texto principal'))}

              {document.template_meta.show_appendix &&
                this.renderAttributeMenuItem('appendix', 'appendix', i18n.t('Apêndices'))}

              {document.template_meta.show_attachment &&
                this.renderAttributeMenuItem('attachment', 'attachment', i18n.t('Anexos'))}

              {document.template_meta.show_abstract_french &&
                this.renderAttributeMenuItem('abstract_french', 'abstract_french', i18n.t('Resumo Francês'), true)}

              {document.template_meta.show_abstract_spanish &&
                this.renderAttributeMenuItem('abstract_spanish', 'abstract_spanish', i18n.t('Resumo Espanhol'), true)}

              {document.template_meta.show_abstract_italian &&
                this.renderAttributeMenuItem('abstract_italian', 'abstract_italian', i18n.t('Resumo Italiano'), true)}

              {document.left_menu_custom_attrs &&
                document.left_menu_custom_attrs.map((attr) => {
                  return (
                    <li key={attr.name} className={this.state.currentAttribute === attr.name ? 'active' : ''}>
                      <a
                        role="button"
                        onClick={(e) => this.changeCustomAttribute(attr.name, e)}
                        title={i18n.t('Editar') + ' ' + attr.label}
                      >
                        <span className="mdi mdi-file-text" />
                        &nbsp;
                        {attr.label}
                      </a>
                    </li>
                  );
                })}

              {document.template_meta.show_glossary &&
                this.renderAttributeMenuItem('glossary', 'glossary', i18n.t('Glossário'))}

              {document.template_meta.show_errata && this.renderAttributeMenuItem('errata', 'errata', 'Errata')}

              {document.template_meta.show_index && this.renderAttributeMenuItem('index', 'index', i18n.t('Índice'))}
            </ul>
          </div>
          <ErrorBoundary>
            <TableOfContents document={document} editor={editor} />
          </ErrorBoundary>
        </PerfectScrollbar>
      </React.Fragment>
    );
  }
}

LeftMenu.propTypes = {
  /**
   * Document instance.
   */
  document: PropTypes.object.isRequired,
  /**
   * Instance of the CKEditor.
   */
  editor: PropTypes.object.isRequired,
  /**
   * function (attributeName, isCustom)
   */
  onBeforeAttributeChange: PropTypes.func,
  /**
   * function (attributeName, isCustom, data)
   */
  onAfterAttributeChange: PropTypes.func.isRequired,
};

export default withNamespaces()(LeftMenu);
