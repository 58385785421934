import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Ajax from '../../common/ajax';
import Config from '../../config';
import Waiting from '../../common/containers/Waiting';
import * as validation from '../../common/validation';
import ReferencePreview from './ReferencePreview';
import './reference.form.css';
import ReferenceAuthorInput from './ReferenceAuthorInput';
import HtmlUtils from '../../common/htmlutils';
import Panel from '../../common/containers/Panel';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import Select from '../../common/components/form/Select';
import debounce from 'lodash.debounce';
import { withNamespaces } from 'react-i18next';
import Textarea from '../../common/components/form/Textarea';
import { AlertInfo } from '../../common/components/Alert';

class ReferenceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      processing: false,
      processingPreview: false,
      previewData: null,
      reference: null,
      form: {},
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onAuthorChange = debounce(this.onAuthorChange.bind(this), 300);
    this.onSubmit = this.onSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.divInputs = null;
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchReferenceTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.form.reference_type !== this.state.form.reference_type) {
      const elements = this.formRef.current.querySelectorAll('.form-group');
      [].forEach.call(elements, (el) => {
        el.style = 'margin-bottom: 20px';
      });
    }
  }

  fetchReferenceTypes() {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}references/types/`;
    Ajax.get(url).done((types) => {
      if (this.props.referenceId) {
        this.fetchReference(types);
      } else {
        this.setState(
          (state) => ({
            ...state,
            processing: false,
            types: types,
            form: {
              reference_type: types[0].key, // Selects the first type
            },
          }),
          this.bindFormElements,
        );
      }
    });
  }

  fetchReference(types) {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}references/${this.props.referenceId}/`;
    Ajax.get(url).done((reference) => {
      this.setState(
        (state) => ({
          ...state,
          types: types,
          reference: reference,
          processing: false,
          form: { ...reference },
        }),
        () => {
          this.bindFormElements();
          this.copyObjectToFormElements(this.state.form);
        },
      );
    });
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const typeChanged = name === 'reference_type' && this.state.form.reference_type !== value;

    this.setState(
      (state) => ({
        ...state,
        form: {
          ...state.form,
          [name]: value,
        },
      }),
      () => {
        if (typeChanged) {
          this.bindFormElements();
          // Re-set values
          this.copyObjectToFormElements(this.state.form);
        }
      },
    );
  }

  onAuthorChange(authors) {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        author: authors.join(';'),
      },
    }));
  }

  bindFormElements() {
    const authorElement = document.getElementById('id_author');
    const { reference } = this.state;
    if (authorElement) {
      const authorComponent = (
        <ReferenceAuthorInput
          onChange={this.onAuthorChange}
          /**
           * Pega os autores da referencia original para facilitar o trabalho do
           * usuario que esta apenas testando os tipos de referencias.
           */
          authors={reference && reference.author ? reference.author.split(';') : ['']}
          multipleAuthors={this.getSelectedType().multiple_authors}
        />
      );
      ReactDOM.render(authorComponent, authorElement.parentNode);
    }

    $(this.divInputs)
      .find('input, select')
      .on('change', (evt) => this.onInputChange(evt));

    // Remove old fields. We keep them only for compatibility purposes.
    if (this.state.form.reference_type === 'book') {
      const editor = this.state.form.editor || '';
      const editortype = this.state.form.editortype || '';
      if (!editor.trim() && !editortype.trim()) {
        $('#id_editor').closest('.row').remove();
      }
    }
  }

  getSelectedType() {
    const type = this.state.types.filter((i) => i.key == this.state.form.reference_type)[0];
    return type;
  }

  copyObjectToFormElements(obj) {
    Object.keys(obj).map((key) => {
      const value = obj[key];
      if (typeof value === 'boolean') {
        $(`input[name="${key}"]`).attr('checked', value);
      } else {
        $(`input[name="${key}"],select[name="${key}"]`).val(value);
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();

    $('p.has-error').remove();
    $('.has-error').removeClass('has-error');

    this.setState((state) => ({ ...state, processing: true }));

    const data = this.getFormData();

    let xhr;

    if (this.props.referenceId) {
      const url = `${Config.apiHost}references/${this.props.referenceId}/`;
      xhr = Ajax.put(url, data);
    } else {
      const url = `${Config.apiHost}references/`;
      xhr = Ajax.post(url, data);
    }

    xhr
      .done((response) => {
        this.setState((state) => ({
          ...state,
          processing: false,
        }));
        if (this.props.onSave) {
          this.props.onSave();
        }
      })
      .fail((response) => {
        validation.showDjangoValidationMessages(response.responseJSON);
        this.setState((state) => ({
          ...state,
          processing: false,
        }));
      })
      .always(() => {
        HtmlUtils.scrollFirstScrollableToTop(this.formRef.current);
      });
  }

  getFormData() {
    const data = {
      reference_type: this.state.form.reference_type,
      preview_style: this.state.form.preview_style,
    };

    // Keep only the type required and optional fields.
    const type = this.getSelectedType();

    type.required_fields.forEach((name) => (data[name] = this.state.form[name]));

    type.optional_fields.forEach((name) => (data[name] = this.state.form[name]));

    return data;
  }

  getTypeDescription() {
    if (this.state.form && this.state.form.reference_type) {
      return this.state.types.filter((type) => type.key === this.state.form.reference_type)[0].description;
    }
  }

  getTypeForm() {
    if (this.state.form && this.state.form.reference_type) {
      return this.state.types.filter((type) => type.key === this.state.form.reference_type)[0].form;
    }
  }

  cancel() {
    HtmlUtils.scrollFirstScrollableToTop(this.formRef.current);
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    const { i18n } = this.props;
    const helpMeuResumo = i18n.t(
      'Você pode escrever nesse campo o seu resumo sobre essa referência, utilizando suas próprias palavras. Esse tipo de resumo é útil para posteriormente ser inserido no capítulo/sessão de bibliografia (trabalhos relacionados).',
    );
    return (
      <Waiting isProcessing={this.state.processing}>
        <form onSubmit={this.onSubmit} ref={this.formRef}>
          <Panel>
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="active">
                <a href="#basic" aria-controls={i18n.t('Identificação')} role="tab" data-toggle="tab">
                  {i18n.t('Identificação')}
                </a>
              </li>
              <li role="presentation">
                <a href="#reference-abstract" aria-controls={i18n.t('Abstract/Resumo')} role="tab" data-toggle="tab">
                  {i18n.t('Abstract/Resumo')}
                </a>
              </li>
              <li role="presentation">
                <a href="#resumo-proprio" aria-controls={i18n.t('Meu resumo')} role="tab" data-toggle="tab">
                  {i18n.t('Meu resumo')} <span className="label label-success">{i18n.t('novo')}</span>
                </a>
              </li>
            </ul>

            <div className="tab-content" style={{ paddingLeft: 0, marginBottom: 0, paddingBottom: 0 }}>
              <div role="tabpanel" className="tab-pane active" id="basic">
                <Row>
                  <Col md={7}>
                    <Select
                      name="reference_type"
                      required={true}
                      id="id_reference_type"
                      dividerLineAt={8}
                      label={i18n.t('Tipo da Referência')}
                      help={this.getTypeDescription()}
                      value={this.state.form.reference_type}
                      options={this.state.types.map((t) => ({
                        label: t.label,
                        value: t.key,
                      }))}
                      onChange={this.onInputChange}
                    />
                  </Col>
                </Row>

                <div ref={(el) => (this.divInputs = el)}>
                  <div dangerouslySetInnerHTML={{ __html: this.getTypeForm() }} />
                </div>

                <ReferencePreview reference={this.state.form} />

                <hr />
              </div>
              <div role="tabpanel" className="tab-pane" id="reference-abstract">
                <Textarea
                  name="abstract"
                  value={this.state.form.abstract}
                  onChange={this.onInputChange}
                  rows="8"
                  showSelectedLineNumber={true}
                  showCharactersCounter={true}
                  placeholder={i18n.t('Abstract/resumo da própria referência')}
                />
              </div>
              <div role="tabpanel" className="tab-pane" id="resumo-proprio">
                <Textarea
                  name="resume"
                  value={this.state.form.resume}
                  onChange={this.onInputChange}
                  rows="8"
                  showSelectedLineNumber={true}
                  showCharactersCounter={true}
                  placeholder={i18n.t('Seu próprio resumo sobre essa referência')}
                  help={helpMeuResumo}
                />
                <AlertInfo>{helpMeuResumo}</AlertInfo>
              </div>
            </div>

            <Row>
              <Col md={12}>
                <button id="submitReferenceButton" className="btn btn-primary btn-lg" type="submit">
                  {i18n.t('Salvar')}
                </button>
                &nbsp;
                <button className="btn btn-default btn-lg" onClick={this.cancel}>
                  {i18n.t('Cancelar')}
                </button>
              </Col>
            </Row>
          </Panel>
        </form>
      </Waiting>
    );
  }
}

ReferenceForm.propTypes = {
  referenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default withNamespaces()(ReferenceForm);
