import React, {Component} from 'react';
import PropTypes from 'prop-types';
import sum from 'hash-sum/hash-sum';
import Button from "../Button";
import {withNamespaces} from "react-i18next";
import Modal from "../../containers/Modal";
import Row from "../../containers/Row";
import Col from "../../containers/Col";
import Checkbox from "../form/Checkbox";
import storage from "../../storage";


class Tip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showAgain: this.props.defaultShowAgain,
        };
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        if (storage.getItem(this.getHashId())) {
            // If this hashId exists in the storage, the user has marked this tip
            // to not show again;
            return;
        } else {
            this.open();
        }
    }

    getHashId() {
        let strs = [];
        this.props.tips.forEach(tip => {
            if (typeof tip === 'string') {
                strs.push(tip);
            }
        });
        return 'ff-show-tip-' + sum(JSON.stringify(strs));
    }

    open() {
        this.setState(s => ({...s, show: true}));
    }

    close() {
        this.setState(s => ({...s, show: false}));
        if (!this.state.showAgain) {
            storage.setItem(this.getHashId(), '0');
        }

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        if (!this.state.show) {
            return null;
        }

        let {i18n, title, tips} = this.props;

        return (
            <Modal
                title={title}
                show={this.state.show}
                width="large"
                fullColor="primary"
                onCancel={this.close}
            >
                {tips.map((tip, idx) => (
                    <Row key={idx}>
                        <Col md={1}>
                            <i style={{fontSize: '16px'}} className="mdi mdi-info-outline pull-right"/>
                        </Col>
                        <Col md={11}>
                            <p style={{fontSize: '16px'}}>{tip}</p>
                        </Col>
                    </Row>
                ))}
                <br/>
                <br/>
                <Row>
                    <Col md={10}>
                        <Checkbox
                            id="do-not-show-again"
                            checked={!this.state.showAgain}
                            onChange={() => this.setState(s => ({...s, showAgain: !s.showAgain}))}
                            label={
                                <span style={{color: 'white'}}>
                                    {i18n.t('Não mostrar mais essa informação')}
                                </span>
                            }
                        />
                    </Col>
                    <Col md={2}>
                        <Button
                            type="default"
                            block={true}
                            onClick={this.close}>
                            {i18n.t('Entendi')}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

Tip.propTypes = {
    /**
     * Title of the modal.
     */
    title: PropTypes.string.isRequired,
    /**
     * List of tip texts to be presented in the modal.
     */
    tips: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ])).isRequired,
    /**
     * Function be called when the modal closes.
     */
    onClose: PropTypes.func,
    /**
     * Default value for the "show again" checkbox. If true, indicates if this
     * tip should show itself again when the user access a component where it
     * is used.
     */
    defaultShowAgain: PropTypes.bool,
};

Tip.defaultProps = {
    defaultShowAgain: true,
};


/**
 * This is a component to show help information (tips) for the users. The end
 * users control whether they want to see the tips again or not, by checking an
 * option for this purpose.
 *
 * To use this component you only need to instantiate it, passing the necessary
 * props. The show logic is totally controlled by the component itself using the
 * browser's storage mechanisms. Thus, there is not need to use the state in
 * parent components to control the show behavior.
 */
export default withNamespaces()(Tip);
