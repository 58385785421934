import React from 'react';
import ImportButton from './ImportButton';
import Waiting from '../../common/containers/Waiting';
import Reference from './Reference';
import Ajax from '../../common/ajax';
import Config from '../../config';
import PaginationWithClick from '../../common/components/PaginationWithClick';
import SearchInput from '../../common/components/SearchInput';
import Alert from '../../common/components/Alert';
import ExportButton from './ExportButton';
import MoveToFolderButton from './MoveToFolderButton';
import PropTypes from 'prop-types';
import SortDropdown from '../../common/components/SortDropdown';
import ReferenceForm from './ReferenceForm';
import sum from 'hash-sum/hash-sum';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import Button from '../../common/components/Button';
import { withNamespaces } from 'react-i18next';
import ErrorBoundary from '../../common/containers/ErrorBoundary';
import Modal from '../../common/containers/Modal';
import FilterByFolderButton from './FilterByFolderButton';

class ReferenceList extends React.Component {
  constructor(props) {
    super(props);

    this.defaultSortAndFilters = {
      sortType: 'desc',
      sortAttribute: 'last_modification_date',
      filters: { hasPdf: false, hasAnnotation: false },
    };

    this.state = {
      errorsList: undefined,
      pagination: {},
      references: null,
      processing: false,
      currentFetchUrl: null,
      selectedReferences: [],
      allSelected: false,
      showReferenceForm: false,
      referenceId: null,
      searchTerm: '',
      lastSearchTerm: '',
      isSearch: false,
      selectedFolder: null,
      ...this.defaultSortAndFilters,
    };

    this.onPaginationClick = this.onPaginationClick.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onDeleteMany = this.onDeleteMany.bind(this);
    this.onModalAlertErrorClose = this.onModalAlertErrorClose.bind(this);
    this.onMovedToFolder = this.onMovedToFolder.bind(this);
    this.onRemovedFromFolder = this.onRemovedFromFolder.bind(this);
    this.onReferenceChange = this.onReferenceChange.bind(this);
    this.onSelectFolder = this.onSelectFolder.bind(this);
    this.selectFolder = this.selectFolder.bind(this);
    this.fetchReferences = this.fetchReferences.bind(this);
    this.fetchFastRef = this.fetchFastRef.bind(this);
    this.editReference = this.editReference.bind(this);
    this.closeReferenceForm = this.closeReferenceForm.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.getDeleteErrorList = this.getDeleteErrorList.bind(this);

    this.highlightContextRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.editReferenceId) {
      this.editReference(this.props.editReferenceId);
    } else {
      this.fetchReferences();
    }

    if (this.props.changeCheckboxFuncRef) {
      this.props.changeCheckboxFuncRef(this.onCheckboxChange);
    }

    if (this.props.selectFolderFuncRef) {
      this.props.selectFolderFuncRef(this.selectFolder);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /*
         Update de references folders by removing the folder that has bee
         removed recently.
        */
    if (this.props.removedFolder && prevProps.removedFolder !== this.props.removedFolder) {
      this.state.references.forEach((reference) => {
        reference.folders = reference.folders.filter((folder) => folder.id !== this.props.removedFolder.id);
      });
      this.setState((state) => ({ ...state }));
    }
  }

  onSearch(searchTerm) {
    const url = `${Config.apiHost}references/?search=${searchTerm}`;
    this.fetchReferences(url).done((pagination) => {
      if (!pagination.results.length) {
        this.fetchFastRef();
      }
    });
  }

  onSearchInputChange(value) {
    this.setState({
      ...this.state,
      searchTerm: value,
    });
  }

  onCheckboxChange(checked, reference) {
    if (checked) {
      this.setState(
        (state) => ({
          ...state,
          selectedReferences: state.selectedReferences.filter((ref) => ref.id !== reference.id).concat(reference),
        }),
        () => {
          if (this.props.onCheckboxChange) {
            this.props.onCheckboxChange(checked, reference);
          }

          if (this.props.onSelectionChange) {
            this.props.onSelectionChange(this.state.selectedReferences);
          }
        },
      );
    } else {
      this.setState(
        (state) => ({
          ...state,
          selectedReferences: state.selectedReferences.filter((ref) => ref.id !== reference.id),
        }),
        () => {
          if (this.props.onCheckboxChange) {
            this.props.onCheckboxChange(checked, reference);
          }

          if (this.props.onSelectionChange) {
            this.props.onSelectionChange(this.state.selectedReferences);
          }
        },
      );
    }
  }

  mountParams(paramsObj = {}) {
    if (this.state.selectedFolder) {
      paramsObj.folder = this.state.selectedFolder.id;
    }

    paramsObj.folder = paramsObj.folder === 'all' ? null : paramsObj.folder;

    if (this.state.sortType && this.state.sortAttribute) {
      let attribute = this.state.sortAttribute;
      if (this.state.sortType === 'desc') {
        attribute = '-' + attribute;
      }
      paramsObj.ordering = attribute;
    }

    if (this.state.filters.hasPdf) {
      paramsObj.hasPdf = 1;
    }

    if (this.state.filters.hasAnnotation) {
      paramsObj.hasAnnotation = 1;
    }

    return $.param(paramsObj);
  }

  onSelectAll(e) {
    if (this.state.allSelected) {
      this.setState((state) => ({
        ...state,
        allSelected: false,
        selectedReferences: state.selectedReferences.filter((id) => state.pagination.results.indexOf(id) === -1),
      }));
    } else {
      this.setState((state) => ({
        ...state,
        allSelected: true,
        selectedReferences: [...new Set(state.selectedReferences.concat(state.pagination.results.slice())).values()],
      }));
    }
  }

  fetchReferences(url) {
    if (!url) {
      url = `${Config.apiHost}references/?${this.mountParams()}`;
    }

    this.setState((state) => ({
      ...state,
      processing: true,
      isSearch: url.indexOf('search=') > -1,
    }));

    const xhr = Ajax.get(url).done((pagination) => {
      this.setState(
        (state) => ({
          ...state,
          processing: false,
          pagination: pagination,
          references: pagination.results,
          allSelected: false,
          currentFetchUrl: url,
          showReferenceForm: false,
          lastSearchTerm: this.state.searchTerm,
        }),
        () => {
          window.scrollTo(0, 0);
        },
      );
    });

    return xhr;
  }

  fetchFastRef() {
    if (!this.state.searchTerm || !this.state.searchTerm.trim()) {
      return;
    }

    this.setState((state) => ({
      ...state,
      processing: true,
      isSearch: true,
    }));

    const url = `${Config.apiHost}fastref/?search=${this.state.searchTerm}`;
    Ajax.get(url).done((pagination) => {
      this.setState(
        (state) => ({
          ...state,
          processing: false,
          pagination: pagination,
          references: pagination.results,
          allSelected: false,
        }),
        () => {
          window.scrollTo(0, 0);
        },
      );
    });
  }

  onDelete(reference) {
    this.fetchReferences(this.state.currentFetchUrl);
    if (this.props.onReferencesChange) {
      this.props.onReferencesChange();
    }
  }

  onReferenceChange(reference) {
    if (this.props.onReferencesChange) {
      this.props.onReferencesChange([reference]);
    }
  }

  onPaginationClick(url) {
    this.fetchReferences(decodeURIComponent(url));
  }

  onSort(attribute, ordering) {
    this.setState(
      (state) => ({
        ...state,
        sortType: ordering,
        sortAttribute: ordering === 'desc' ? '-' + attribute : attribute,
      }),
      () => this.fetchReferences(),
    );
  }

  onFilter(checkboxItem) {
    this.setState(
      (state) => ({
        ...state,
        filters: {
          ...state.filters,
          [checkboxItem.attribute]: checkboxItem.checked,
        },
      }),
      () => this.fetchReferences(),
    );
  }

  onDeleteMany(force = 0) {
    if (!this.state.selectedReferences.length) {
      return;
    }

    const { i18n } = this.props;

    const total = this.state.selectedReferences.length;

    if (force === 1 || confirm(`${i18n.t('Confirma a remoção de')} ${total} ${i18n.t('referência')}(s)?`)) {
      this.setState((state) => ({
        ...state,
        processing: false,
      }));
      const ids = this.state.selectedReferences.map((ref) => ref.id).join(',');
      const url = `${Config.apiHost}references/delete_many/?ids=${ids}&force=${force}`;
      Ajax.delete_(url)
        .done((response) => {
          const selectedReferences = [...this.state.selectedReferences];
          this.setState((state) => ({
            ...state,
            processing: false,
            errorsList: undefined,
            selectedReferences: [],
          }));
          this.fetchReferences();
          if (this.props.onReferencesChange) {
            this.props.onReferencesChange(selectedReferences);
          }
        })
        .fail((jxhr) => {
          this.setState((state) => ({
            ...state,
            processing: false,
            errorsList: this.getDeleteErrorList(jxhr),
          }));
        });
    }
  }

  getDeleteErrorList(jxhr) {
    const { i18n } = this.props;
    const { references_in_use } = jxhr.responseJSON;

    if (references_in_use) {
      return references_in_use.map((data) => {
        const msg = `${i18n.t('A referência')} "${data.reference_title}" ${i18n.t(
          'está sendo utilizada no documento',
        )} "${data.document_title}".`;
        return (
          <Alert key={data.reference_id} type="warning">
            {msg}
          </Alert>
        );
      });
    }

    return <Alert type="danger">{i18n.t('Erro inesperado')}</Alert>;
  }

  onModalAlertErrorClose() {
    this.setState((state) => ({
      ...state,
      errorsList: undefined,
      selectedReferences: [],
    }));
    this.fetchReferences();
  }

  onMovedToFolder(folder) {
    this.state.selectedReferences.forEach((ref) => {
      const exists = ref.folders.find((f) => f.id === folder.id);
      if (!exists) {
        ref.folders.push(folder);
      }
    });
    this.setState((state) => ({ ...state }));
    if (this.props.onReferencesChange) {
      this.props.onReferencesChange(this.state.selectedReferences);
    }
  }

  onRemovedFromFolder(folder) {
    this.state.selectedReferences.forEach((selectedRef) => {
      const folders = selectedRef.folders.filter((f) => f.id !== folder.id);
      selectedRef.folders = folders;
    });
    this.setState((state) => ({ ...state }));
    if (this.props.onReferencesChange) {
      this.props.onReferencesChange(this.state.selectedReferences);
    }
  }

  onSelectFolder(folder) {
    this.selectFolder(folder);
    if (this.props.onSelectFolder) {
      this.props.onSelectFolder(folder);
    }
  }

  selectFolder(folder) {
    this.setState(
      (state) => ({
        ...state,
        selectedFolder: folder,
      }),
      this.fetchReferences,
    );
  }

  editReference(referenceId) {
    this.setState((state) => ({
      ...state,
      showReferenceForm: true,
      referenceId,
      selectedFolder: null,
    }));
    if (this.props.onReferenceFormShow) {
      this.props.onReferenceFormShow(referenceId);
    }
  }

  closeReferenceForm(updateList) {
    this.setState((state) => ({
      ...state,
      showReferenceForm: false,
      referenceId: null,
    }));

    if (updateList) {
      this.fetchReferences(this.state.currentFetchUrl);
    }

    if (this.props.onReferenceFormHide) {
      this.props.onReferenceFormHide(updateList);
    }
  }

  // renderDatabaseSearchLink() {
  //   const { i18n } = this.props;
  //   if (this.state.searchTerm) {
  //     return (
  //       <>
  //         <a
  //           role="button"
  //           style={{ marginTop: '10px' }}
  //           onClick={this.fetchFastRef}
  //         >
  //           <i className="icon mdi mdi-globe" />
  //           &nbsp;
  //           {i18n.t('Buscar apenas em nosso Banco de Referências')} (Shift +
  //           Enter)
  //         </a>
  //       </>
  //     );
  //   }
  // }

  renderAsideHeader() {
    const { i18n } = this.props;

    const { selectedReferences } = this.state;

    return (
      <div
        className="be-aside-header"
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          marginLeft: '-4px',
        }}
      >
        <Row>
          <Col md={8}>
            <div className="be-aside-header-title" style={{ marginTop: '6px' }}>
              {this.renderToolbar()}
            </div>
          </Col>

          <Col md={4}>
            <div className="be-aside-header-search">
              <ErrorBoundary>
                <SearchInput
                  value={this.state.searchTerm}
                  handleSearch={this.onSearch}
                  onChange={this.onSearchInputChange}
                  onShiftEnter={this.fetchFastRef}
                />
              </ErrorBoundary>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            {this.showSelectbox() && selectedReferences && selectedReferences.length > 0 && (
              <span className="text-muted" style={{ marginLeft: '5px' }}>
                {selectedReferences.length} {i18n.t('referência(s) selecionada(s)')} |{' '}
                <a
                  role="button"
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      selectedReferences: [],
                    }))
                  }
                >
                  {i18n.t('Limpar seleção')}
                </a>
              </span>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  showToolbar() {
    const { showToolbarExport, showToolbarLabel, showToolbarSort, showToolbarTrash } = this.props;

    return showToolbarExport || showToolbarLabel || showToolbarSort || showToolbarTrash;
  }

  showSelectbox() {
    const { showToolbarExport, showToolbarLabel, showToolbarTrash } = this.props;

    return showToolbarExport || showToolbarLabel || showToolbarTrash;
  }

  renderToolbar() {
    const {
      i18n,
      showToolbarExport,
      showToolbarLabel,
      showToolbarSort,
      showToolbarTrash,
      showToolbarSelectAll,
      showToolbarFilterByFolder,
      onReferencesChange,
      showImport,
    } = this.props;

    const { selectedReferences, references } = this.state;

    const disabled = references && references.filter((r) => !!r.user).length === 0;

    return (
      <>
        {(showToolbarSelectAll && this.renderSelectAllButton(disabled)) || null}
        <Button type="primary" size="md" onClick={() => this.editReference()}>
          {i18n.t('Nova referência')}
        </Button>{' '}
        {/**/}
        {(showToolbarSort && this.renderSortButton()) || null} {/**/}
        {(showToolbarLabel && (
          <MoveToFolderButton
            disabled={disabled}
            references={selectedReferences}
            onMovedToFolder={this.onMovedToFolder}
            onRemovedFromFolder={this.onRemovedFromFolder}
          />
        )) ||
          null}{' '}
        {/**/}
        {(showToolbarFilterByFolder && <FilterByFolderButton onSelectFolder={this.selectFolder} />) || null} {/**/}
        {showImport && (
          <ImportButton
            onImport={() => {
              this.fetchReferences();
              if (onReferencesChange) {
                onReferencesChange();
              }
            }}
          />
        )}{' '}
        {/**/}
        {(showToolbarExport && <ExportButton disabled={disabled} references={selectedReferences} />) || null} {/**/}
        {(showToolbarTrash && this.renderDeleteButton(disabled)) || null}
      </>
    );
  }

  renderSelectAllButton(disabled = false) {
    const { i18n } = this.props;

    return (
      <div className="btn-group" role="group">
        <div
          className="be-checkbox"
          style={{ paddingTop: '1px' }}
          data-toggle="tooltip"
          title={i18n.t('Selecionar todos')}
          data-placement="bottom"
        >
          <input
            id="select-all"
            type="checkbox"
            checked={
              this.state.allSelected && this.state.selectedReferences && this.state.selectedReferences.length > 0
            }
            disabled={disabled}
            onChange={this.onSelectAll}
          />
          <label htmlFor="select-all">&nbsp;</label>
        </div>
      </div>
    );
  }

  renderSortButton(disabled = false) {
    const { i18n } = this.props;
    return (
      <div className="btn-group hidden-xs" role="group">
        <SortDropdown
          disabled={disabled}
          items={[
            {
              label: i18n.t('Última modificação'),
              attribute: 'last_modification_date',
            },
            {
              label: i18n.t('Título'),
              attribute: 'title',
            },
            {
              label: i18n.t('Autores'),
              attribute: 'author',
            },
            {
              label: i18n.t('Ano'),
              attribute: 'year',
            },
            {
              label: i18n.t('Tipo'),
              attribute: 'type',
            },
          ]}
          checkboxItems={[
            { label: i18n.t('Com anotações'), attribute: 'hasAnnotation' },
            { label: i18n.t('Com PDF'), attribute: 'hasPdf' },
          ]}
          onSortClick={this.onSort}
          onCheckboxChange={this.onFilter}
          onClear={() =>
            this.setState(
              (state) => ({
                ...state,
                ...this.defaultSortAndFilters,
              }),
              () => this.fetchReferences(),
            )
          }
          label={i18n.t('Filtrar')}
          buttonClass="btn btn-default btn-md"
        />
      </div>
    );
  }

  renderDeleteButton(disabled = false) {
    const { i18n } = this.props;
    const disabledClass = disabled || this.state.selectedReferences.length === 0 ? 'disabled' : '';
    return (
      <div className="btn-group actions_for_selected" role="group">
        <button type="button" onClick={() => this.onDeleteMany()} className={`btn btn-danger btn-md ${disabledClass}`}>
          <span className="mdi mdi-delete" title={i18n.t('Excluir')} data-toggle="tooltip" data-placement="bottom" />
        </button>
      </div>
    );
  }

  renderReferences() {
    const { i18n } = this.props;
    if (this.state.references && this.state.references.length) {
      return this.state.references.map((reference) => {
        const isSelected = this.state.selectedReferences.filter((ref) => ref.id === reference.id).length > 0;
        return (
          <Reference
            id={`${this.props.id}Reference`}
            key={`${sum(reference)}-${this.state.lastSearchTerm}`}
            reference={reference}
            onCheckboxChange={this.onCheckboxChange}
            onDeleted={this.onDelete}
            checked={isSelected}
            onReferenceChanged={this.onReferenceChange}
            onSelectFolder={this.onSelectFolder}
            showFolders={this.props.showFolders}
            showActions={this.props.showActions}
            onClickToEdit={(referenceId) => this.editReference(referenceId)}
            highlightTerm={this.state.searchTerm}
          />
        );
      });
    } else {
      return <p className="text-center">{i18n.t('Nenhuma referência encontrada.')}</p>;
    }
  }

  renderReferenceForm() {
    const referenceForm = (
      <ReferenceForm
        onCancel={this.closeReferenceForm}
        onSave={() => this.closeReferenceForm(true)}
        referenceId={this.state.referenceId}
      />
    );

    if (this.props.referenceFormAsPage) {
      return (
        <Row style={{ marginTop: '20px' }}>
          <Col md={12} lg={10} lgOffset={1}>
            {referenceForm}
          </Col>
        </Row>
      );
    } else {
      return referenceForm;
    }
  }

  render() {
    const { i18n } = this.props;

    if (this.state.showReferenceForm) {
      return this.renderReferenceForm();
    } else {
      return (
        <>
          <Modal
            show={!!this.state.errorsList}
            title={i18n.t('Algumas referências não foram removidas')}
            onCancel={this.onModalAlertErrorClose}
            footer={
              <>
                <span className="btn">{i18n.t('Gostaria de removê-las assim mesmo?')}</span>
                <Button onClick={() => this.onDeleteMany(1)} type="danger">
                  {i18n.t('Remover')}
                </Button>
              </>
            }
          >
            {this.state.errorsList}
          </Modal>

          {this.renderAsideHeader()}

          <Waiting isProcessing={this.state.processing} />

          {this.state.references && this.state.references.length > 0 && (
            <>
              <div className="email-list" ref={this.highlightContextRef}>
                {this.state.references && this.renderReferences()}
              </div>

              <Row>
                <Col md={10} mdOffset={1}>
                  <br />
                  <PaginationWithClick handleClick={this.onPaginationClick} pagination={this.state.pagination} />
                </Col>
              </Row>
            </>
          )}

          {(!this.state.references || !this.state.references.length) &&
            !this.state.isSearch &&
            !this.state.processing && (
              <div className="panel panel-default panel-body">
                <div className="jumbotron" style={{ backgroundColor: 'white' }}>
                  <h2>{i18n.t('Você ainda não possui referências')}</h2>
                  <hr />
                  <h3>{i18n.t('Existem quatro formas simples para cadastrar suas referências')}</h3>
                  <br />
                  <ul className="user-timeline user-timeline-compact">
                    <li>
                      <div className="user-timeline-title">
                        <b>{i18n.t('Cadastro Manual')}</b>
                        <br />
                        {i18n.t('Basta clicar no botão')} <b>{i18n.t('Nova referência')}</b>
                        &nbsp;
                        <a
                          target="-_blank"
                          href="https://www.youtube.com/watch?v=hZ5lWH6FHmA&t=0s&list=PLiUteYq_6sB5UmAJaEP3HWBggRODVBCnN&index=5"
                        >
                          <i className="icon mdi mdi-videocam" />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="user-timeline-title">
                        <b>{i18n.t('Banco de Referências FastForamt')}</b>
                        <br />
                        {i18n.t('Utilize o campo de busca para pesquisar em mais de 5 milhões de referências')}
                        &nbsp;
                        <a
                          target="-_blank"
                          href="https://www.youtube.com/watch?v=dLEXqUPXJFg&t=0s&list=PLiUteYq_6sB5UmAJaEP3HWBggRODVBCnN&index=3"
                        >
                          <i className="icon mdi mdi-videocam" />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="user-timeline-title">
                        <b>BibTeX</b>
                        <br />
                        {i18n.t('Importar de um arquivo BibTeX, através do botão de importar')}
                        &nbsp;
                        <a
                          target="-_blank"
                          href="https://www.youtube.com/watch?v=_Lo14tsEOnM&t=0s&list=PLiUteYq_6sB5UmAJaEP3HWBggRODVBCnN&index=4"
                        >
                          <i className="icon mdi mdi-videocam" />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="user-timeline-title">
                        <b>Mendley</b>
                        <br />
                        {i18n.t('Importar da sua conta do Mendley, através do botão de importar')}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
        </>
      );
    }
  }
}

ReferenceList.propTypes = {
  /**
   * A function receiving the list of changed references.
   */
  onReferencesChange: PropTypes.func,
  /**
   * A function to receive the selected references.
   */
  onSelectionChange: PropTypes.func,
  /**
   * A function receiving the object that was clicked in order to filter the
   * listing.
   */
  onSelectFolder: PropTypes.func,
  selectFolderFuncRef: PropTypes.func,
  /**
   * A folder that has been removed. This is used to update the list of
   * folders in each reference.
   */
  removedFolder: PropTypes.object,
  /**
   * If provided, the component will start opening the reference form filled
   * with this reference.
   */
  editReferenceId: PropTypes.string,
  showImport: PropTypes.bool,
  showToolbarSort: PropTypes.bool,
  showToolbarExport: PropTypes.bool,
  showToolbarLabel: PropTypes.bool,
  showToolbarTrash: PropTypes.bool,
  showToolbarFilterByFolder: PropTypes.bool,
  showToolbarSelectAll: PropTypes.bool,
  showFolders: PropTypes.bool,
  showActions: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  onReferenceFormShow: PropTypes.func,
  onReferenceFormHide: PropTypes.func,
  referenceFormAsPage: PropTypes.bool,
  /**
   * Reference to the function that changes a checkbox's state. The function's
   * parameters are: checked:true|false), reference:object.
   */
  changeCheckboxFuncRef: PropTypes.func,
};

ReferenceList.defaultProps = {
  showImport: true,
  showToolbarSort: true,
  showToolbarExport: true,
  showToolbarLabel: true,
  showToolbarTrash: true,
  showToolbarSelectAll: true,
  showActions: true,
  showFolders: true,
  referenceFormAsPage: true,
};

export default withNamespaces()(ReferenceList);
