import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { withNamespaces } from 'react-i18next';

class TableOfContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuNodes: [],
    };
    this.buildTree = debounce(this.buildTree.bind(this), 500);
    this.rerenderTree = debounce(this.rerenderTree.bind(this), 500);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.props.editor.on('dataReady', this.buildTree);
    this.props.editor.on('fastformatFormatApplied', this.buildTree);
    this.props.editor.on('change', this.rerenderTree);
  }

  componentWillUnmount() {
    this.buildTree.cancel();
    this.rerenderTree.cancel();
  }

  onClick(node) {
    if (node) {
      node.scrollIntoView();
    }
  }

  buildTree() {
    const menuNodes = [];
    let segments = [];

    const headers = this.props.editor
      .editable()
      .$.querySelectorAll('h1, h2, h3, h4, h5, h6');

    for (let i = 0; i < headers.length; i++) {
      const node = headers[i];
      let dotedLevel;

      if (node.className.indexOf('nonumber') > -1) {
        dotedLevel = '';
      } else {
        const level = parseInt(node.nodeName.substring(1), 10);
        if (segments.length === level) {
          segments[level - 1]++;
        } else if (segments.length > level) {
          segments = segments.slice(0, level);
          segments[level - 1]++;
        } else if (segments.length < level) {
          for (let i = 0; i < level - segments.length; i++) {
            segments.push(1);
          }
        }
        dotedLevel = segments.join('.');
      }

      menuNodes.push({
        id: dotedLevel,
        node,
      });
    }

    this.setState((state) => ({
      ...state,
      menuNodes,
    }));
  }

  rerenderTree() {
    this.setState((state) => ({ ...state }));
  }

  render() {
    const { i18n } = this.props;
    return (
      <div id="toc">
        <table>
          <tbody>
            {this.state.menuNodes.map((item, idx) => {
              const label = `${item.id} ${item.node.innerText}`;
              return (
                <tr key={idx}>
                  <td>
                    <a
                      role="button"
                      onClick={() => this.onClick(item.node)}
                      title={i18n.t('Ir para seção') + ': ' + label}
                    >
                      {label}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

TableOfContents.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(TableOfContents);
