import Mark from 'mark.js';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import BootstrapMenu from 'bootstrap-menu';
import storage from '../../../common/storage';
import debounce from 'lodash.debounce';
import URLS from '../../../urls';
import IgnoredTokensManager from './editor.nlp.proofread.ignoredTokens';
import { hasProofread, hasSpellchecker } from '../../../context/global';

require('withinviewport/withinviewport');
require('withinviewport/jquery.withinviewport');

const FastFormatEditorNLPGrammar = function (reactComponent) {
  const ckEditor = reactComponent.props.editor;
  const documentId = reactComponent.props.document.id;

  const i18n = reactComponent.props.i18n;

  let proofReadOn = false;

  const ignoredTokensManager = new IgnoredTokensManager();

  let menu;
  let readabilitySupportedLangs;
  let grammarIssuesEnabled = true;
  let typosEnabled = true;
  let readabilityEnabled = true;
  let timeoutAnalyzeOnChange;
  let sentRequests = [];
  let totalAnalyzedElements = 0;
  let totalAnalysiableElements = 0;
  let isForbidden = false;
  let elementContentMemory = {};
  let matches = {};
  let cachedMatches = {};
  const MAX_FREE_ANALYSIS_PERCENTAGE = 10;

  const storageCacheKey = 'proofreadCachedMatches';
  const MISSPELLING = 'misspelling';
  const READABILITY = 'readability';
  const selectors = 'h1, h2, h3, h4, h5, p, td, li, figcaption, caption';
  const onOffKey = 'ff_proofread_on_off';

  const delay = hasProofread() || hasSpellchecker() ? 300 : 500;

  const debouncedAnalyzeAllElements = debounce(analyzeAllElements, delay);

  init();

  function init() {
    proofReadOn = storage.getItem(onOffKey, 'on') === 'on';
    console.log('proofReadOn:', proofReadOn);

    cleanProofreadMarkup(ckEditor.editable().$);

    ckEditor.on('change', () => {
      const element = ckEditor.getSelection().getStartElement();
      if (element) {
        unMarkElementIfChanged(element);
      }
    });

    ckEditor.on('key', () => {
      removeMenu();
    });

    ckEditor.on('menuShow', () => {
      removeMenu();
    });

    ckEditor.on('fastformatProofreadStart', () => {
      startProofRead();
    });

    ckEditor.on('fastformatProofreadStop', () => {
      stopProofRead();
    });

    ckEditor.on('fastformatProofreadRestart', () => {
      restart();
    });

    ckEditor.on('fastformatProofreadStartGrammar', () => {
      toggleGrammarIssues(true);
    });

    ckEditor.on('fastformatProofreadStopGrammar', () => {
      toggleGrammarIssues(false);
    });

    ckEditor.on('fastformatProofreadStartTypos', () => {
      toggleTypos(true);
    });

    ckEditor.on('fastformatProofreadStopTypos', () => {
      toggleTypos(false);
    });

    ckEditor.on('fastformatProofreadStartReadability', () => {
      toggleReadability(true);
    });

    ckEditor.on('fastformatProofreadStopReadability', () => {
      toggleReadability(false);
    });

    ckEditor.on('fastformatAfterAttributeChange', () => {
      restart();
    });

    ckEditor.on('getData', function (evt) {
      /**
       * Clean proofread markup before editor.getData method returns.
       * Proofread markup must not go to thebackend in any case.
       */
      const element = $('<div>' + evt.data.dataValue + '</div>')[0];
      cleanProofreadMarkup(element);
      evt.data.dataValue = element.innerHTML;
    });

    // Get languages
    const url = `${Config.apiHost}proofread/readability-languages/`;
    Ajax.getSync(url).done((languages) => {
      readabilitySupportedLangs = languages;
    });

    if (proofReadOn) {
      startProofRead();
    }
  }

  function startProofRead() {
    ignoredTokensManager.start();

    sentRequests = [];
    elementContentMemory = {};
    matches = {};
    proofReadOn = true;
    isForbidden = false;

    restoreCachedMatches();

    $(ckEditor.editable().$).unbind('scroll.nlp');
    removeMenu();
    cleanProofreadMarkup(ckEditor.editable().$);

    debouncedAnalyzeAllElements();

    $(ckEditor.editable().$).on('scroll.nlp', () => {
      debouncedAnalyzeAllElements();
    });

    ckEditor.on('afterCommandExec', (evt) => {
      if (evt.data.name === 'undo' || evt.data.name === 'redo') {
        clearVisibleElement();
        reanalyzeOnChange();
      }
    });

    storage.setItem(onOffKey, 'on');
    ckEditor.fire('fastformatProofreadStarted');
  }

  function clearVisibleElement() {
    $(ckEditor.editable().$)
      .find(selectors)
      .withinviewport()
      .each((ix, elem) => {
        cleanProofreadMarkup(elem);
      });
  }

  function stopProofRead() {
    sentRequests.forEach((request) => {
      request.abort();
    });

    cachedMatches = {};
    elementContentMemory = {};
    matches = {};
    proofReadOn = false;
    grammarIssuesEnabled = true;
    typosEnabled = true;
    readabilityEnabled = true;
    sentRequests = [];
    totalAnalyzedElements = 0;
    $(ckEditor.editable().$).unbind('scroll.nlp');
    removeMenu();
    cleanProofreadMarkup(ckEditor.editable().$);
    console.log('[fastformatProofread]', 'Proofread stopped');
    storage.setItem(onOffKey, 'off');
    ckEditor.fire('fastformatProofreadStopped');
  }

  function restart() {
    if (proofReadOn && !ckEditor.readOnly) {
      stopProofRead();
      startProofRead();
    }
  }

  function isFreeLimitReached() {
    const percentAnalyzed =
      (totalAnalyzedElements / totalAnalysiableElements) * 100;
    return percentAnalyzed > MAX_FREE_ANALYSIS_PERCENTAGE;
  }

  function analyzeAllElements() {
    if (!proofReadOn || ckEditor.readOnly) {
      return;
    }

    const editable = ckEditor.editable();

    if (!editable) {
      return;
    }

    let $query = $(editable.$).find(selectors);
    totalAnalysiableElements = $query.length;
    $query = $query.withinviewport();

    const runReadability = readabilityEnabled && hasProofread();

    const runLanguageTool =
      (grammarIssuesEnabled || typosEnabled) &&
      (hasSpellchecker() || hasProofread());

    if ($query.length === 0) {
      if (runLanguageTool) {
        // Languagetool performs both spellchecker and grammar/style.
        analyzeLanguageToolElement(editable.$);
      }
      if (runReadability) {
        analyzeReadabilityElement(editable.$);
      }
    } else {
      $query.each((idx, item) => {
        if (runLanguageTool) {
          analyzeLanguageToolElement(item);
        }
        if (runReadability) {
          analyzeReadabilityElement(item);
        }
      });
    }
  }

  function analyzeLanguageToolElement(element) {
    const path = `${Config.apiHost}proofread/grammar-check/`;
    analyzeElement(element, path, 'languageTool');
  }

  function analyzeReadabilityElement(element) {
    const path = `${Config.apiHost}proofread/readability-check/`;
    analyzeElement(element, path, 'readability');
  }

  function analyzeElement(element, path, cacheNamespace) {
    if (
      !proofReadOn ||
      // $(element).parent('blockquote').length > 0 ||
      isForbidden
    ) {
      return;
    }

    element.normalize();

    // Try to reause cached matches.
    const elementHash = makeElementHash(element) + '-' + cacheNamespace;
    if (cachedMatches[elementHash]) {
      cachedMatches[elementHash].forEach((match) => {
        const matchCopy = { ...match };
        matchCopy.parent = element;
        matchCopy.context = matchCopy.token;
        mark(matchCopy);
      });
      if (cachedMatches[elementHash].length > 0) {
        totalAnalyzedElements++;
      }
      return;
    }
    // End of cache.

    cachedMatches[elementHash] = [];
    const elementLang = element.getAttribute('data-lang');
    const lang = elementLang || storage.getItem('ff_proofread_lang');

    if (!lang) {
      console.error('Proofread language not defined!');
      return;
    }

    const sentences = element.innerText.split('. ');

    function analyzePhrasesSequentially() {
      if (sentences.length > 0) {
        let p = sentences.splice(0, 1)[0].trim();
        if (!p.endsWith('.')) {
          p += '.';
        }
        const url = `${path}?text=${p}&lang=${lang}&tagName=${element.tagName}&document=${documentId}`;
        const jqXHR = Ajax.get(url)
          .done((matches) => {
            if (matches && matches.length > 0) {
              cachedMatches[elementHash] =
                cachedMatches[elementHash].concat(matches);
              matches.forEach((match) => {
                const matchCopy = { ...match };
                matchCopy.parent = element;
                matchCopy.context = matchCopy.token;
                mark(matchCopy);
              });
            }
            isForbidden = false;
            analyzePhrasesSequentially();
          })
          .fail((jqXHR) => {
            isForbidden = jqXHR.status === 403;
          });
        sentRequests.push(jqXHR);
      } else {
        cleanSentRequests();
        if (cachedMatches[elementHash].length > 0) {
          totalAnalyzedElements++;
        }
      }
    }

    analyzePhrasesSequentially();
  }

  function makeElementHash(element) {
    const elementLang =
      element.getAttribute('data-lang') || storage.getItem('ff_proofread_lang');
    return objectHash.MD5(element.innerText) + '-' + elementLang;
  }

  function cleanSentRequests() {
    let complete = true;
    sentRequests.forEach((req) => {
      if (!req.status) {
        complete = false;
        return false;
      }
    });
    if (complete) {
      storeCachedMatches();
      sentRequests = [];
      console.log('Sent requests:', sentRequests);
    }
  }

  function storeCachedMatches() {
    // Store cached matches in the storage for later restoring.
    storage.setItem(storageCacheKey, JSON.stringify(cachedMatches));
    console.log('Proofread cached matches stored successfully.');
  }

  function restoreCachedMatches() {
    const permKey = 'ffPreviousProofPerm';
    const previousPermission = storage.getItem(permKey);
    const currentPerm = `${hasProofread()}${hasSpellchecker()}`;
    const shouldRestore = previousPermission === currentPerm;
    storage.setItem(permKey, currentPerm);
    if (shouldRestore) {
      // Restore cached matches from storage
      const storedCache = storage.getItem(storageCacheKey);
      if (storedCache) {
        cachedMatches = JSON.parse(storedCache);
        cleanupCachedMatches();
      }
    } else {
      console.log(
        'Skipping restoring proofread cache because permissions changed.'
      );
    }
  }

  function cleanupCachedMatches() {
    // Calculates all elements hashes and removes from the cache those
    // hashes that have no correspondent elements.
    const elementHashes = [];
    const $query = $(ckEditor.editable().$).find(selectors);
    $query.each(function (idx, element) {
      element.normalize();
      const elementHash = makeElementHash(element);
      elementHashes.push(elementHash + '-' + 'readability');
      elementHashes.push(elementHash + '-' + 'languageTool');
    });
    const newCachedMatches = {};
    elementHashes.forEach(function (elHash) {
      if (cachedMatches.hasOwnProperty(elHash)) {
        newCachedMatches[elHash] = cachedMatches[elHash];
      }
    });
    cachedMatches = { ...newCachedMatches };
    storeCachedMatches();
  }

  function mark(match) {
    if (match.issueType === MISSPELLING) {
      if (!typosEnabled || !hasSpellchecker()) {
        return;
      }
    } else if (match.issueType === READABILITY) {
      if (!readabilityEnabled || !hasProofread()) {
        return;
      }
    } else {
      if (!grammarIssuesEnabled || !hasProofread()) {
        return;
      }
    }

    if (ignoredTokensManager.isMatchResolved(match) || !match.token) {
      console.log('[fastformatProofread]', 'Match has been resolved:', match);
      return;
    }

    const markInstance = new Mark(match.parent);
    lockSnapshot();
    let ranges;

    markInstance.mark(match.token, {
      element: 'span',
      className: (match.isAskUpgrade && 'nlpff-muted') || match.classNames,
      caseSensitive: true,
      separateWordSearch: false,
      accuracy: { value: 'exactly', limiters: [',', '.'] },
      exclude: [
        '.nlpff',
        '.citation',
        'ff-cite',
        '.cite',
        '.ff-footnote',
        '.ff-footnote-label',
        '.math-tex',
      ],
      diacritics: false,
      filter: (textNode, termFound, totalMarkedCounter, markedTermCounter) => {
        const isSameParent = $.contains(match.parent, textNode);
        const wordAtCursor = getWordAtCursor();
        if (isSameParent && (wordAtCursor === termFound || !wordAtCursor)) {
          const selection = ckEditor.getSelection();
          if (selection) {
            ranges = ckEditor.getSelection().getRanges();
          }
        }
        return true;
      },
      each: (domElement) => {
        matches[match.id] = match;
        domElement.id = match.id;
        const $domElement = $(domElement);
        elementContentMemory[domElement.id] = $domElement.text();
        if (!match.isAskUpgrade) {
          $domElement.click(() => {
            createLanguageToolMenu(domElement.id, match);
          });
        }
        updateSelection(ranges);
      },
      done: (numberOfMarks) => {
        unlockSnapshot();
      },
    });
  }

  function getWordAtCursor() {
    const selection = ckEditor.getSelection();

    if (!selection) {
      return;
    }

    const range = selection.getRanges()[0];

    if (!range || range.startContainer.type !== new CKEDITOR.dom.text().type) {
      return null;
    }

    const str = range.startContainer.$.textContent;
    const pos = Number(range.startOffset) >>> 0;

    // Search for the word's beginning and end.
    const left = str.slice(0, pos + 1).search(/\S+$/),
      right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
      return str.slice(left);
    }

    // Return the word, using the located bounds to extract it from the string.
    const word = str.slice(left, right + pos);
    return word;
  }

  function updateSelection(ranges) {
    lockSnapshot();
    if (ranges && ranges.length === 1) {
      const range = ranges[0];
      if (range.startOffset > range.startContainer.$.length) {
        const siblingNode = range.startContainer.$.nextElementSibling;
        if (siblingNode && siblingNode.hasAttribute('data-markjs')) {
          const nextNode = new CKEDITOR.dom.node(siblingNode.firstChild);
          const startOffset = range.startOffset - range.startContainer.$.length;
          const newRange = ckEditor.createRange();
          if (startOffset <= nextNode.$.length) {
            newRange.setStart(nextNode, startOffset);
            newRange.setEnd(nextNode, startOffset);
            ckEditor.getSelection().selectRanges([newRange]);
          }
        }
      }
    }
    unlockSnapshot();
  }

  function unMarkElementIfChanged(ckElement) {
    if (!proofReadOn) {
      return;
    }

    if (!ckElement.hasAttribute('data-markjs')) {
      reanalyzeOnChange();
      return;
    }

    const text = ckElement.getText();

    if (
      elementContentMemory[ckElement.getId()] &&
      elementContentMemory[ckElement.getId()] !== text
    ) {
      removeMenu();
      try {
        lockSnapshot();
        elementContentMemory[ckElement.getId()] = ckElement.getText();
        const bookmarks = ckEditor.getSelection().createBookmarks();
        delete matches[ckElement.getId()];
        ckElement.remove(true);
        ckEditor.getSelection().selectBookmarks(bookmarks);
        unlockSnapshot();
        reanalyzeOnChange();
      } catch (e) {
        console.log('[fastformatProofread]', e);
      }
    }
  }

  function reanalyzeOnChange() {
    clearTimeout(timeoutAnalyzeOnChange);
    timeoutAnalyzeOnChange = setTimeout(() => {
      analyzeAllElements();
    }, 1000);
  }

  function createLanguageToolMenu(markId, match) {
    console.log('[fastformatProofread] Creating proofread menu...');
    removeMenu();

    const actions = [];

    let msgUpgrade;

    if (match.issueType === MISSPELLING && !hasSpellchecker()) {
      msgUpgrade = `<i class="text-danger">O <b>corretor ortográfico</b> analisou apenas ${MAX_FREE_ANALYSIS_PERCENTAGE}% do texto. Faça upgrade para analisar tudo.</i> <button class="btn btn-xs btn-primary"><i class="fas fa-shopping-cart"/> Upgrade</button>`;
    } else if (match.issueType !== MISSPELLING && !hasProofread()) {
      msgUpgrade = `<i class="text-danger">O <b>corretor gramatical e de estilo</b> analisou apenas ${MAX_FREE_ANALYSIS_PERCENTAGE}% do texto. Faça upgrade para analisar tudo.</i> <button class="btn btn-xs btn-primary"><i class="fas fa-shopping-cart"/> Upgrade</button>`;
    }

    if (msgUpgrade) {
      actions.push({
        name: msgUpgrade,
        classNames: 'nlp-context-menu nlp-context-menu-header',
        iconClass: 'no-icon-class',
        onClick: (row) => {
          document.location = `${URLS.plans}`;
        },
      });
    }

    actions.push({
      name:
        '<b>' + match.categoryName.toUpperCase() + '</b><br/>' + match.message,
      classNames: 'nlp-context-menu nlp-context-menu-header',
      iconClass: 'no-icon-class',
      onClick: () => {},
    });

    if (match.replacements.length > 0) {
      actions.push({
        name: i18n.t('Substituir por'),
        classNames: 'nlp-context-menu nlp-context-menu-replacements',
        iconClass: 'no-icon-class',
        onClick: () => {},
      });

      match.replacements.forEach((item) => {
        actions.push({
          name: '<i>' + item.value + '</i>',
          classNames: 'nlp-context-menu nlp-context-menu-replacement-item',
          onClick: (row) => {
            replaceWithSuggestion(markId, match, item);
          },
        });
      });

      actions[actions.length - 1].classNames += ' nlp-context-menu-footer';
    }

    actions.push({
      name: i18n.t('Ignorar'),
      classNames: 'nlp-context-menu action-danger',
      iconClass: 'fa-ban',
      onClick: (row) => {
        ignoreAll(markId, match);
      },
    });

    actions.push({
      name: i18n.t('Remover expressão'),
      classNames: 'nlp-context-menu',
      iconClass: 'fa-trash',
      onClick: (row) => {
        removeMarkedToken(markId, match);
      },
    });

    actions.push({
      name: i18n.t('Fechar'),
      classNames: 'nlp-context-menu',
      iconClass: 'fa-times-circle',
      onClick: (row) => {
        removeMenu();
      },
    });

    menu = new BootstrapMenu('#' + markId, {
      menuEvent: 'click',
      menuSource: 'mouse',
      menuPosition: 'belowLeft', // matches element's right side
      actions: actions,
    });

    menu.markId = markId;
    menu.$menu.css('max-height', '250px');
    menu.$menuList
      .css('max-height', '300px')
      .css('overflow-y', 'auto')
      .css('overflow-x', 'hidden');
    menu.$menuList.addClass('nlp-context-menu-scrollbar');
    menu.$menuList.find('.no-icon-class').remove();
    menu.$menuList
      .find('[role=menuitem]')
      .attr('role', 'button')
      .removeAttr('href');
  }

  function removeMarkedToken(markId, match) {
    ckEditor.fire('saveSnapshot');
    const $mark = $('#' + markId);
    $mark.remove();
    delete matches[markId];
  }

  function replaceWithSuggestion(markId, match, suggestionItem) {
    ckEditor.fire('saveSnapshot');
    const $mark = $('#' + markId);
    $mark.html(suggestionItem.value);
    unMark(markId);
  }

  function ignoreAll(markId, match) {
    const $mark = $('#' + markId);
    const text = $mark.text();
    ignoredTokensManager.ignoreToken(text, match);
    unMarkAll(markId);
    reanalyzeOnChange();
  }

  /**
   * Removes all markup produced by Proofread.
   * @param content (optional). If passed, the cleaning is applied to this content and the
   * cleaned content is returned, else it will clean the current editor content.
   */
  function cleanProofreadMarkup(element) {
    lockSnapshot();
    new Mark(element).unmark();
    $(element)
      .find('[data-grammar-id], [data-readability-id]')
      .removeAttr('data-grammar-id')
      .removeAttr('data-readability-id');
    unlockSnapshot();
  }

  function removeMenu() {
    if (menu) {
      menu.$menu.remove();
      menu.destroy();
      menu = null;
    }
  }

  function unMark(markId) {
    const $mark = $('#' + markId);
    uncheckSentence(markId);
    lockSnapshot();
    new Mark($mark[0]).unmark();
    unlockSnapshot();
    removeMenu();
  }

  function unMarkAll(markId) {
    const $mark = $('#' + markId);
    const text = $mark.text();

    $('[data-markjs=true]').each((i, val) => {
      const $val = $(val);
      if ($val.text() === text) {
        unMark(val.id);
      }
    });
  }

  function toggleGrammarIssues(enabled) {
    grammarIssuesEnabled = enabled;

    if (!grammarIssuesEnabled) {
      for (let m in matches) {
        if (
          matches[m].issueType !== MISSPELLING &&
          matches[m].issueType !== READABILITY
        ) {
          unMark(matches[m].id);
        }
      }
    } else {
      analyzeAllElements();
    }
  }

  function toggleTypos(enabled) {
    typosEnabled = enabled;

    if (!typosEnabled) {
      for (let m in matches) {
        if (matches[m].issueType === MISSPELLING) {
          unMark(matches[m].id);
        }
      }
    } else {
      analyzeAllElements();
    }
  }

  function toggleReadability(enabled) {
    readabilityEnabled = enabled;

    if (!readabilityEnabled) {
      for (let m in matches) {
        if (matches[m].issueType === READABILITY) {
          unMark(matches[m].id);
        }
      }
    } else {
      analyzeAllElements();
    }
  }

  function uncheckSentence(markJsId) {
    delete matches[markJsId];
  }

  function getAllowedContent() {
    const rules = {
      span: {
        classes: ['nlpff', 'readability', 'langt', 'TYPOS'],
        attributes: ['id', 'data-markjs'],
      },
    };

    selectors.split(',').forEach((item) => {
      rules[item.trim()] = {
        attributes: ['data-lang'],
      };
    });

    return rules;
  }

  function lockSnapshot() {
    try {
      ckEditor.fire('lockSnapshot');
    } catch (e) {
      (console.error || console.log).call(console, e.stack || e);
    }
  }

  function unlockSnapshot() {
    try {
      ckEditor.fire('unlockSnapshot');
    } catch (e) {
      (console.error || console.log).call(console, e.stack || e);
    }
  }

  return {
    start: startProofRead,
    stop: stopProofRead,
    restart: restart,
  };
};

export default FastFormatEditorNLPGrammar;
