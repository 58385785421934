import Prism from 'prismjs';

import 'prismjs/components/prism-abap';
import 'prismjs/components/prism-ada';
import 'prismjs/components/prism-asm6502';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-basic';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-cobol';
import 'prismjs/components/prism-pascal';
import 'prismjs/components/prism-eiffel';
import 'prismjs/components/prism-erlang';
import 'prismjs/components/prism-fortran';
import 'prismjs/components/prism-haskell';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-avro-idl';
import 'prismjs/components/prism-inform7';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-lisp';
import 'prismjs/components/prism-lua';
import 'prismjs/components/prism-makefile';
import 'prismjs/components/prism-matlab';
import 'prismjs/components/prism-mizar';
import 'prismjs/components/prism-objectivec';
import 'prismjs/components/prism-opencl';
import 'prismjs/components/prism-oz';
import 'prismjs/components/prism-perl';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-prolog';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-r';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-sas';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-tcl';
import 'prismjs/components/prism-latex';
import 'prismjs/components/prism-vbnet';
import 'prismjs/components/prism-verilog';
import 'prismjs/components/prism-vhdl';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import Checkbox from '../../../common/components/form/Checkbox';
import FormGroup from '../../../common/components/form/FormGroup';
import Input from '../../../common/components/form/Input';
import Select from '../../../common/components/form/Select';
import Col from '../../../common/containers/Col';
import Modal from '../../../common/containers/Modal';
import Row from '../../../common/containers/Row';
import Events from '../Events';
import CodeSnippetPreview from './CodeSnippetPreview';
import './codeSnippetPrismjs.css';
import { FONT_SIZES, getCodeAttributes, getPrismLanguage, LANGUAGES } from './constants';
import { getIframeDocument } from '../../../common/htmlutils';

const TEXTAREA_ID = 'code-editor-id';

class CodeSnippet extends Component {
  constructor(props) {
    super(props);

    this.initialFormValues = {
      caption: 'Exemplo de função JavaScript.',
      codeSnippet: 'function helloWorld() {\n  console.log("Hello world!");\n}',
      language: 'JavaScript',
      fontSize: 'small',
      showColors: true,
      showNumbers: 'left', // 'left', 'right' or 'none'
      firstNumber: 1,
      numbersSpacing: 5,
      numbersStep: 1,
      showFrame: false,
    };

    this.state = {
      showModal: false,
      showConfModal: false,
      editorInstance: null,
      preElement: null,
      error: false,
      form: { ...this.initialFormValues },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.insertHTML = this.insertHTML.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onCodeChanged = this.onCodeChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitRef = React.createRef();
  }

  componentDidMount() {
    const { editor } = this.props;
    editor.on(Events.CODESNIPPET_OPEN, this.openModal);
    // editor.on('paste', (evt) => console.log(evt.data.dataValue));
  }

  openModal(editorEvent) {
    let element = editorEvent.data;

    if (element) {
      let codeElement;

      if (element.tagName === 'PRE') {
        const iframe = element.querySelector('iframe');
        if (iframe) {
          codeElement = getIframeDocument(iframe).getElementById('shadow-pre').querySelector('code');
          codeElement.setAttribute('class', codeElement.getAttribute('savedlanguage'));
        } else {
          codeElement = element.querySelector('code');
        }
      } else {
        codeElement = element;
        element = element.parentElement;
      }

      this.setState((state) => ({
        ...state,
        preElement: element,
        form: {
          ...state.form,
          ...getCodeAttributes(codeElement),
        },
      }));
    }

    this.setState((state) => ({
      ...state,
      showModal: true,
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      preElement: null,
      form: { ...this.initialFormValues },
    }));

    this.props.editor.fire(Events.AFTER_ATTRIBUTE_CHANGE);
  }

  insertHTML() {
    const {
      caption,
      codeSnippet,
      showColors,
      showNumbers,
      firstNumber,
      numbersSpacing,
      numbersStep,
      language,
      fontSize,
      showFrame,
    } = this.state.form;

    const code = `<code 
      caption="${caption || ''}" 
      class="hljs language-${language}" 
      shownumbers="${showNumbers}" 
      firstnumber="${firstNumber}"
      showcolors="${showColors ? 'yes' : 'no'}" 
      numberspacing="${numbersSpacing}" 
      numbersstep="${numbersStep}" 
      fontsize="${fontSize}"
      showframe=${showFrame ? 'yes' : 'no'}
    >${codeSnippet}</code>`;

    this.props.editor.fire('saveSnapshot');

    if (this.state.preElement) {
      // Editing exisintg code
      this.state.preElement.innerHTML = code;
    } else {
      // Inserting new code
      this.props.editor.insertHtml(`<pre>${code}</pre>`);
    }
    this.props.editor.fire(Events.CODESNIPPET_PREVIEW);
    this.closeModal();
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  }

  onCodeChanged(codeSnippet) {
    this.setState((state) => ({
      ...state,
      form: { ...state.form, codeSnippet },
    }));
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.insertHTML();
  }

  render() {
    const { i18n, document, editor } = this.props;
    const numbersEnabled = ['left', 'right'].indexOf(this.state.form.showNumbers) > -1;

    return (
      <>
        <CodeSnippetPreview document={document} editor={editor} />
        <Modal
          title={i18n.t('Código fonte')}
          show={this.state.showModal}
          onCancel={this.closeModal}
          customWidth="900px"
          footer={
            <>
              <button className="btn btn-default btn-lg" onClick={this.closeModal}>
                {i18n.t('Cancelar')}
              </button>
              <button className="btn btn-primary btn-lg" onClick={() => this.submitRef.current.click()}>
                {i18n.t('Confirmar')}
              </button>
            </>
          }
          width="large"
        >
          <form onSubmit={this.onSubmit}>
            <input type="submit" hidden={true} ref={this.submitRef} />
            <Row>
              <Col md={3}>
                <Select
                  id="language"
                  name="language"
                  label={i18n.t('Linguagem')}
                  value={this.state.form.language || ''}
                  onChange={this.onInputChange}
                  options={LANGUAGES.map((l) => ({ label: l[0], value: l[0] }))}
                  required={true}
                />
              </Col>
              <Col md={9}>
                <Input
                  id="caption"
                  name="caption"
                  value={this.state.form.caption || ''}
                  label={i18n.t('Legenda')}
                  onChange={this.onInputChange}
                />
              </Col>
            </Row>

            <FormGroup
              label={
                <span>
                  {i18n.t('Insira códigos fonte de acordo com sua linguagem de programação') + '. '}
                  <a
                    role="button"
                    onClick={() => {
                      this.setState((s) => ({ ...s, showConfModal: true }));
                    }}
                  >
                    <i className="icon mdi mdi-settings" /> {i18n.t('Configurar aparência')}
                  </a>
                </span>
              }
              id={TEXTAREA_ID}
            >
              <Editor
                textareaId={TEXTAREA_ID}
                value={this.state.form.codeSnippet}
                required={true}
                padding={10}
                onValueChange={this.onCodeChanged}
                highlight={(code) =>
                  (this.state.form.showColors &&
                    Prism.highlight(code, Prism.languages[getPrismLanguage(this.state.form.language)])) ||
                  code
                }
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  border: '1px solid #e5e5e5',
                }}
                preClassName="code-snippet"
              />
            </FormGroup>
          </form>

          <Modal
            title={i18n.t('Configurações de Código fonte')}
            show={this.state.showConfModal}
            onCancel={() => {
              this.setState((s) => ({ ...s, showConfModal: false }));
            }}
            footer={
              <>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => this.setState((s) => ({ ...s, showConfModal: false }))}
                >
                  {i18n.t('Fechar')}
                </button>
              </>
            }
          >
            <p>
              <b>{i18n.t('Atenção')}:</b>{' '}
              {i18n.t(
                'algumas dessas opções alteram apenas o resultado final no PDF. O código exibido no editor serve apenas de guia.',
              )}
            </p>
            <Row>
              <Col md={5}>
                <Select
                  id="fontSize"
                  name="fontSize"
                  value={this.state.form.fontSize || ''}
                  label={i18n.t('Tamanho da fonte')}
                  onChange={this.onInputChange}
                  options={FONT_SIZES.map((f) => ({
                    label: f[0],
                    value: f[1],
                  }))}
                />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <Checkbox
                  id="showColors"
                  name="showColors"
                  checked={this.state.form.showColors}
                  label={i18n.t('Fazer highlight do código')}
                  onChange={this.onInputChange}
                />
              </Col>
              <Col md={5}>
                <Checkbox
                  id="showFrame"
                  name="showFrame"
                  checked={this.state.form.showFrame}
                  label={i18n.t('Mostrar quadro ao redor')}
                  onChange={this.onInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Select
                  id="showNumbers"
                  name="showNumbers"
                  options={[
                    { label: i18n.t('Não'), value: 'none' },
                    { label: i18n.t('À esquerda'), value: 'left' },
                    { label: i18n.t('À direita'), value: 'right' },
                  ]}
                  value={this.state.form.showNumbers || ''}
                  label={i18n.t('Exibir número de linha')}
                  onChange={this.onInputChange}
                />
              </Col>
              {numbersEnabled && (
                <Col md={4}>
                  <Input
                    id="firstNumber"
                    name="firstNumber"
                    value={this.state.form.firstNumber || ''}
                    label={i18n.t('Número da linha inicial')}
                    onChange={this.onInputChange}
                    type="number"
                  />
                </Col>
              )}
            </Row>
            {numbersEnabled && (
              <Row>
                <Col md={4}>
                  <Input
                    id="numbersStep"
                    name="numbersStep"
                    value={this.state.form.numbersStep || ''}
                    label={i18n.t('Salto do número de linha. (Ex. 1, 3, 6...)')}
                    onChange={this.onInputChange}
                    type="number"
                  />
                </Col>
                <Col md={4}>
                  <Input
                    id="numbersSpacing"
                    name="numbersSpacing"
                    value={this.state.form.numbersSpacing || ''}
                    label={i18n.t('Espaçamento entre o número e o início da linha')}
                    onChange={this.onInputChange}
                    type="number"
                  />
                </Col>
              </Row>
            )}
          </Modal>
        </Modal>
      </>
    );
  }
}

CodeSnippet.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(CodeSnippet);
