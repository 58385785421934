import React from 'react';
import PropTypes from 'prop-types';
import truncate from 'underscore.string/truncate';
import { Link } from 'react-router-dom';
import Config from '../../config';
import Ajax from '../../common/ajax';
import ModalAlert from '../../common/components/ModalAlert';
import Waiting from '../../common/containers/Waiting';

import './reference.list.css';
import PdfUpload from './PdfUpload';
import CopyButton from '../../fastref/components/CopyButton';
import { withNamespaces } from 'react-i18next';
import ReferenceAnnotationButton from './ReferenceAnnotationButton';
import HtmlUtils from '../../common/htmlutils';
import LocaleUtils from '../../common/LocaleUtils';
import URLS from '../../urls';

class Reference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: this.props.reference,
      showAbstract: false,
      error: undefined,
      processing: false,
      folders: [],
    };
    this.toggleAbstract = this.toggleAbstract.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.onTrashClickHandler = this.onTrashClickHandler.bind(this);
    this.onModalAlertErrorClose = this.onModalAlertErrorClose.bind(this);
    this.onRemoveFolderClick = this.onRemoveFolderClick.bind(this);
    this.onSelectFolder = this.onSelectFolder.bind(this);
    this.fetchReference = this.fetchReference.bind(this);
    this.copyFastRefReference = this.copyFastRefReference.bind(this);
    this.referenceDivRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.highlightTerm) {
      HtmlUtils.highlight(this.props.highlightTerm, this.referenceDivRef.current);
    }
  }

  toggleAbstract() {
    this.setState((state) => ({
      ...state,
      showAbstract: !this.state.showAbstract,
    }));
  }

  checkboxChangeHandler(e) {
    if (this.props.onCheckboxChange) {
      this.props.onCheckboxChange(e.target.checked, this.props.reference);
    }
  }

  onTrashClickHandler() {
    const { i18n } = this.props;
    const title = this.props.reference.title;
    const confirmed = confirm(`${i18n.t('Confirma a remoção de')} "${title}"?`);
    if (confirmed) {
      this.setState((state) => ({
        ...state,
        processing: true,
      }));
      const url = `${Config.apiHost}references/${this.props.reference.id}/`;
      Ajax.delete_(url)
        .done((response) => {
          this.props.onDeleted && this.props.onDeleted(this.props.reference);
          this.setState((state) => ({
            ...state,
            processing: false,
            error: undefined,
          }));
        })
        .fail((jxhr) => {
          this.setState((state) => ({
            ...state,
            processing: false,
            error: jxhr.responseJSON ? jxhr.responseJSON.detail : i18n.t('Erro inesperado'),
          }));
        });
    }
  }

  onModalAlertErrorClose() {
    this.setState((state) => ({
      ...state,
      error: undefined,
    }));
  }

  onSelectFolder(folder, e) {
    e.stopPropagation();
    if (this.props.onSelectFolder) {
      this.props.onSelectFolder(folder);
    }
  }

  onRemoveFolderClick(folder, e) {
    e.stopPropagation();
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const data = { reference_ids: this.props.reference.id };
    const url = `${Config.apiHost}folders/${folder.id}/remove_references/`;
    Ajax.delete_(url, data).done(() => {
      const folders = this.props.reference.folders.filter((f) => {
        return f.id !== folder.id;
      });
      this.props.reference.folders = folders;
      this.setState((state) => ({
        ...state,
        processing: false,
      }));
      if (this.props.onReferenceChanged) {
        this.props.onReferenceChanged(this.props.reference);
      }
    });
  }

  fetchReference() {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}references/${this.state.reference.id}/`;
    Ajax.get(url).done((reference) => {
      this.setState((state) => ({
        ...state,
        reference,
        processing: false,
      }));
    });
  }

  copyFastRefReference(reference) {
    this.setState((state) => ({
      ...state,
      reference,
    }));
    if (this.props.onReferenceChanged) {
      this.props.onReferenceChanged(reference);
    }
  }

  renderFolders() {
    const { i18n } = this.props;
    const folders = this.props.reference.folders || [];
    return folders.map((folder) => {
      return (
        <div className="btn-group btn-group-xs btn-space" key={folder.id}>
          <button type="button" className="btn btn-default" onClick={(e) => this.onSelectFolder(folder, e)}>
            <span className="text-muted">{truncate(folder.name, 20)}</span>
          </button>

          <button
            type="button"
            className="btn btn-default"
            aria-expanded="false"
            title={i18n.t('Remover esse marcador dessa referência')}
            onClick={(e) => this.onRemoveFolderClick(folder, e)}
          >
            <span className="close" style={{ fontSize: '18px' }}>
              &times;
            </span>
          </button>
        </div>
      );
    });
  }

  renderCopyButton() {
    if (this.state.reference.user) {
      return null;
    }

    return <CopyButton reference={this.state.reference} onCopy={this.copyFastRefReference} />;
  }

  renderUserActions() {
    if (!this.state.reference.user) {
      return null;
    }
    return (
      <React.Fragment>
        <ReferenceAnnotationButton reference={this.state.reference} />{' '}
        <PdfUpload
          reference={this.state.reference}
          onUpdateStart={() => this.setState({ ...this.state, processing: true })}
          onUpdateEnd={this.fetchReference}
        />
      </React.Fragment>
    );
  }

  render() {
    const { i18n } = this.props;
    const r = this.state.reference;

    return (
      <Waiting isProcessing={this.state.processing}>
        <ModalAlert show={this.state.error} type="danger" onClose={this.onModalAlertErrorClose}>
          <h3>{this.state.error}</h3>
        </ModalAlert>

        <div
          className={`email-list-item ${this.state.reference.user ? '' : 'email-list-item--unread'}`}
          style={{ padding: '14px 16px' }}
        >
          {this.state.reference.user && this.props.onCheckboxChange && (
            <div className="email-list-actions text-center">
              <div className="be-checkbox" style={{ padding: '0px' }}>
                <input
                  id={`${this.props.id}${r.id}`}
                  type="checkbox"
                  name="reference-checkbox"
                  value={r.id}
                  onChange={this.checkboxChangeHandler}
                  checked={this.props.checked}
                />
                <label htmlFor={`${this.props.id}${r.id}`}>&nbsp;</label>
              </div>
            </div>
          )}

          <div className="email-list-detail" ref={this.referenceDivRef}>
            <span
              onClick={() => {
                if (r.user && this.props.onClickToEdit) {
                  this.props.onClickToEdit(r.id);
                }
              }}
              style={{ cursor: r.user ? 'pointer' : '' }}
              title={r.user ? i18n.t('Clique para editar') : r.title}
            >
              <a role={r.user ? 'button' : ''} className="reference-title">
                {`${r.title_highlighted || r.title_by_type}${r.subtitle ? ': ' + r.subtitle : ''}`}.
              </a>

              {r.author_separated_by_colon && (
                <span className="reference-author">
                  &nbsp;
                  {truncate(r.author_separated_by_colon, 70)}
                </span>
              )}

              {(r.booktitle || r.journal || r.school) && (
                <span className="text-info">
                  &nbsp; &#8210; &nbsp;
                  {r.booktitle || r.journal || r.school}
                </span>
              )}

              {r.year && <span className="text-info">&nbsp; ({r.year})</span>}

              {r.formatted_url && (
                <span>
                  &nbsp; &#8210; &nbsp;
                  <a target="_blank" rel="noopener">
                    {truncate(r.formatted_url, 50)}
                  </a>
                </span>
              )}

              <span className="text-muted">
                &nbsp; &#8210; &nbsp;
                {r.typename}
              </span>

              <span className="text-muted small">
                <br />
                {i18n.t('Modificado em')} {LocaleUtils.calendar(r.last_modification_date)}
              </span>
            </span>

            {(r.abstract || r.resume) && (
              <p className="msg">
                {!this.state.showAbstract && <i>{truncate(r.abstract || r.resume, 200)}</i>}

                {this.state.showAbstract && <i>{r.abstract || r.resume}</i>}

                <a role="button" onClick={this.toggleAbstract}>
                  &nbsp;
                  {this.state.showAbstract ? i18n.t('menos') : i18n.t('mais')}
                </a>
              </p>
            )}

            <div className="msg" style={{ marginTop: '8px' }}>
              {this.props.showFolders && this.renderFolders()}
              {this.renderCopyButton()}
              {!this.props.showFolders && (
                <>
                  <ReferenceAnnotationButton reference={this.state.reference} />
                  {this.state.reference.pdf && (
                    <>
                      {' '}
                      <Link
                        to={URLS.referencesPDF(this.state.reference.id)}
                        title={i18n.t('Abrir PDF')}
                        className="btn btn-default btn-xs"
                        target={'_blank'}
                      >
                        <i className="icon mdi mdi-collection-pdf" />
                        {i18n.t('Abrir')} pdf
                      </Link>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {this.props.showActions && (
            <div className="email-list-actions hidden-xs" style={{ width: '250px' }}>
              <div className="pull-right">{this.renderUserActions()}</div>
            </div>
          )}
        </div>
      </Waiting>
    );
  }
}

Reference.propTypes = {
  reference: PropTypes.object.isRequired,
  onCheckboxChange: PropTypes.func,
  onDeleted: PropTypes.func,
  onReferenceChanged: PropTypes.func,
  onSelectFolder: PropTypes.func,
  onClickToEdit: PropTypes.func,
  checked: PropTypes.bool,
  showActions: PropTypes.bool,
  showFolders: PropTypes.bool,
  highlightTerm: PropTypes.string,
};

Reference.defaultProps = {
  showActions: true,
  showFolders: true,
};

export default withNamespaces()(Reference);
