import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/containers/Modal';
import Config from '../../config';
import Ajax from '../../common/ajax';
import { withNamespaces } from 'react-i18next';
import Button from '../../common/components/Button';

class ExportToDocx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState((state) => ({
      ...state,
      show: true,
    }));
  }

  close() {
    this.setState((state) => ({ ...state, show: false }));
  }

  render() {
    const { i18n } = this.props;
    const paragraphStyle = { fontSize: '11pt' };

    return (
      <>
        <a role="button" onClick={this.open}>
          <i className="icon far fa-file-word" />
          &nbsp;
          {i18n.t('Como exportar para MS Word (docx)')}
        </a>
        <Modal
          show={this.state.show}
          headerFullColor="primary"
          title={i18n.t('Como exportar o documento para MS Word (docx)')}
          onCancel={this.close}
          footer={
            <Button onClick={this.close} size="lg">
              {i18n.t('Fechar')}
            </Button>
          }
        >
          <div style={paragraphStyle}>
            <p>
              Para exportar seu documento para MS Word (docx), recomendamos utilizar o aplicativo <b>Smallpdf</b>.
            </p>

            <p>
              Você só precisa baixar o PDF gerado pelo FastFormat, acessar o endereço{' '}
              <a href="https://smallpdf.com/pdf-to-word" target="_blank">
                https://smallpdf.com/pdf-to-word
              </a>{' '}
              e realizar a conversão.
            </p>

            <p>
              Já realizamos inúmeros testes com aplicativos que convertem PDF para DOCX e o <b>Smallpdf</b> é o que
              apresenta o melhor resultado.
            </p>

            <p>
              <i>
                <b>Observação:</b>
                <br />
                Salientamos que o Smallpdf é uma plataforma externa, sobre a qual não temos responsabilidade. O uso do
                serviço é de inteira responsabilidade do usuário ao acessar essa ferramenta.
              </i>
            </p>
          </div>
        </Modal>
      </>
    );
  }
}

export default withNamespaces()(ExportToDocx);
