import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/containers/Modal';
import ReferenceList from '../../../references/components/ReferenceList';
import ApudSelect from './ApudSelect';
import PagesInput from './PagesInput';
import StyleSelect from './StyleSelect';
import SelectedReferences from './SelectedReferences';
import Ajax from '../../../common/ajax';
import Config from '../../../config';
import Row from '../../../common/containers/Row';
import Col from '../../../common/containers/Col';
import Button from '../../../common/components/Button';
import { withNamespaces } from 'react-i18next';
import Events from '../Events';
import ErrorBoundary from '../../../common/containers/ErrorBoundary';
import HtmlUtils from '../../../common/htmlutils';

class Citation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      editReferenceId: null,
      referenceFormShown: false,
      referenceFormEditShown: false,
      selectedReferences: {},
      editorBookmarks: null,
      form: {
        style: 'parenthesis',
        ids: [],
        apud_id: '',
        pages: '',
      },
      referenceListKey: new Date().getMilliseconds(),
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.editReference = this.editReference.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.confirmCitation = this.confirmCitation.bind(this);
    this.onRemoveCitation = this.onRemoveCitation.bind(this);
    this.onReferenceFormShow = this.onReferenceFormShow.bind(this);
    this.onReferenceFormHide = this.onReferenceFormHide.bind(this);
    this.changeCheckboxFuncRef = null;
    this.referenceListDivRef = React.createRef();
  }

  componentDidMount() {
    this.props.editor.on('fastformatOpenCitation', (evt) => {
      this.openModal();
    });

    this.props.editor.on('fastformatEditReference', (evt) => {
      const referenceId = evt.data;
      this.editReference(referenceId);
    });
  }

  openModal() {
    this.setState((state) => ({
      ...state,
      showModal: true,
      editorBookmarks: this.props.editor.getSelection().createBookmarks2(),
      form: {
        style: 'parenthesis',
        ids: [],
        apud_id: '',
        pages: '',
      },
    }));
  }

  editReference(referenceId) {
    this.setState((state) => ({
      ...state,
      showModal: true,
      editReferenceId: referenceId,
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      editReferenceId: null,
      referenceFormShown: false,
      selectedReferences: {},
      referenceListKey: new Date().getMilliseconds(), // Force clear component's state
    }));
  }

  onReferenceFormShow(referenceId) {
    this.setState((state) => ({
      ...state,
      referenceFormEditShown: !!referenceId,
      referenceFormShown: true,
    }));
  }

  onReferenceFormHide(updateCitations) {
    const { editReferenceId } = this.state;
    if (editReferenceId) {
      this.setState((state) => ({
        ...state,
        showModal: false,
        referenceFormShown: false,
        editReferenceId: null,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        referenceFormShown: false,
      }));
    }

    if (updateCitations) {
      this.props.editor.fire(Events.AFTER_REFERENCE_UPDATE, editReferenceId);
      this.props.editor.fire(Events.COMPILE);
    }

    if (this.referenceListDivRef.current) {
      HtmlUtils.scrollFirstScrollableToTop(this.referenceListDivRef.current);
    }
  }

  onSelectionChange(selectedReferences) {
    const selectedObject = {};
    const apudeable = selectedReferences.length === 2;
    selectedReferences.forEach((ref) => (selectedObject[ref.id] = ref));
    this.setState((state) => ({
      ...state,
      selectedReferences: { ...selectedObject },
      form: {
        ...state.form,
        ids: selectedReferences.map((r) => r.id),
        // Resets apud value if necessary.
        apud_id: apudeable ? state.form.apud_id : '',
      },
    }));
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  }

  confirmCitation() {
    const url = `${Config.apiHost}documents/${this.props.document.id}/mount_citation/`;
    const form = { ...this.state.form };
    let withResume = false;

    if (form.style === 'parenthesis-resume') {
      form.style = 'parenthesis';
      withResume = true;
    }

    Ajax.postJSON(url, form).done((response) => {
      this.props.editor.fire('saveSnapshot');
      try {
        this.props.editor.getSelection().selectBookmarks(this.state.editorBookmarks);
      } catch (e) {
        console.log(e);
      }

      if (withResume) {
        const references = Object.values(this.state.selectedReferences);
        if (references && references.length && references[0].resume) {
          this.props.editor.insertHtml(`${references[0].resume} ${response.html}`);
        } else {
          this.props.editor.insertHtml(response.html);
        }
      } else {
        this.props.editor.insertHtml(response.html);
      }

      this.props.editor.fire('saveSnapshot');
      this.closeModal();
      this.props.editor.fire(Events.SAVE_AND_COMPILE);
    });
  }

  onRemoveCitation(reference) {
    this.changeCheckboxFuncRef(false, reference);
  }

  hasSelectedReferences() {
    return Object.keys(this.state.selectedReferences).length > 0;
  }

  renderFooter() {
    if (this.state.referenceFormShown) {
      return null;
    }
    const { i18n } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <StyleSelect
              document={this.props.document}
              value={this.state.form.style}
              onInputChange={this.onInputChange}
              totalReferences={(this.state.form.ids && this.state.form.ids.length) || 0}
            />
          </Col>
          <Col md={3}>
            <PagesInput
              document={this.props.document}
              value={this.state.form.pages}
              onInputChange={this.onInputChange}
            />
          </Col>
          <Col md={3}>
            <ApudSelect
              document={this.props.document}
              onInputChange={this.onInputChange}
              value={this.state.form.apud_id}
              selectedReferences={this.state.selectedReferences}
            />
          </Col>
          <Col md={2}>
            <Button type="success" size="lg" onClick={this.confirmCitation}>
              {i18n.t('Confirmar')}
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  render() {
    const { i18n } = this.props;
    const list = (
      <ReferenceList
        id={`${this.props.id}ReferencesList`}
        key={`${this.props.key}${this.state.referenceListKey}`}
        showImport={false}
        showToolbarSort={true}
        showToolbarExport={false}
        showToolbarLabel={false}
        showToolbarTrash={false}
        showToolbarFilterByFolder={true}
        showToolbarSelectAll={false}
        showActions={false}
        showFolders={false}
        keepSelection={true}
        onSelectionChange={this.onSelectionChange}
        editReferenceId={this.state.editReferenceId}
        referenceFormAsPage={false}
        changeCheckboxFuncRef={(f) => (this.changeCheckboxFuncRef = f)}
        onReferenceFormShow={this.onReferenceFormShow}
        onReferenceFormHide={this.onReferenceFormHide}
      />
    );

    const selected = (
      <SelectedReferences
        document={this.props.document}
        selectedReferences={this.state.selectedReferences}
        onRemoveCitation={this.onRemoveCitation}
      />
    );

    const modalFooter = this.renderFooter();

    const hasSelected = this.hasSelectedReferences();
    const colStyle = {
      maxHeight: modalFooter ? 'calc(70vh)' : 'calc(80vh)',
      overflowY: 'auto',
      overflowX: 'hidden',
    };

    let modalTitle = i18n.t('Selecione as referências da citação');

    if (this.state.editReferenceId || this.state.referenceFormEditShown) {
      modalTitle = i18n.t('Editar referência');
    } else if (this.state.referenceFormShown) {
      modalTitle = i18n.t('Nova referência');
    }

    return (
      <Modal
        title={modalTitle}
        show={this.state.showModal}
        footer={modalFooter}
        onCancel={this.closeModal}
        customWidth="70vw"
        renderInBody={false}
        bodyStyle={{ padding: '0px', overflowX: 'hidden' }}
        footerStyle={{ paddingTop: '10px' }}
        style={{ zIndex: '10001' }}
        bodyMinHeight={modalFooter ? 'calc(70vh)' : 'calc(80vh)'}
      >
        <ErrorBoundary>
          <Row>
            <Col md={this.state.referenceFormShown ? 12 : 9}>
              <div className="ff-scrollbar" style={{ ...colStyle }} ref={this.referenceListDivRef}>
                {list}
              </div>
            </Col>
            {!this.state.referenceFormShown && (
              <Col md={3}>
                <div className="ff-scrollbar" style={{ ...colStyle }}>
                  <h3>{i18n.t('Selecionados')}</h3>
                  {selected}
                  {!hasSelected && <p className="text-muted">{i18n.t('Nenhuma referência selecionada')}</p>}
                </div>
              </Col>
            )}
          </Row>
        </ErrorBoundary>
      </Modal>
    );
  }
}

Citation.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Citation);
