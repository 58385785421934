import debounce from 'lodash.debounce';
import 'prismjs/components/prism-latex';
import 'prismjs/themes/prism.css';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import withinviewport from 'withinviewport';
import Ajax from '../../../common/ajax';
import Config from '../../../config';
import Events from '../Events';
import './formula.css';
import { FORMULA_CACHE, getTrimedFormula } from './formulaCommon';
import spinner from './loader.gif';

class FormulaPreview extends Component {
  constructor(props) {
    super(props);
    this.previewVisibleFormulas = debounce(
      this.previewVisibleFormulas.bind(this),
      200
    );
    this.previewUrl = `${Config.apiHost}documents/${props.document.id}/formula_preview/`;
  }

  componentDidMount() {
    const { editor } = this.props;
    editor.on(Events.AFTER_ATTRIBUTE_CHANGE, this.previewVisibleFormulas);
    editor.on(Events.FORMULA_PREVIEW, this.previewVisibleFormulas);
    editor.on('instanceReady', this.previewVisibleFormulas);
    editor.editable().$.addEventListener('scroll', this.previewVisibleFormulas);
    this.previewVisibleFormulas();
  }

  componentWillUnmount() {
    if (this.props.editor) {
      const editable = this.props.editor.editable();
      if (editable && editable.$) {
        editable.$.removeEventListener('scroll', this.previewVisibleFormulas);
      }
    }
  }

  previewVisibleFormulas() {
    const { editor } = this.props;
    const editable = editor.editable();

    if (!editable) {
      return;
    }

    const editorElement = editable.$;
    const elements = editorElement.querySelectorAll('.math-tex');

    elements.forEach((spanElement) => {
      if (
        withinviewport(spanElement) &&
        !spanElement.querySelector('img') &&
        !spanElement.closest('.ff-footnote') // formulas inside footnote
      ) {
        const formula = '\\(' + getTrimedFormula(spanElement.innerText) + '\\)';

        if (formula) {
          const encodedFormula = encodeURIComponent(formula);
          editor.fire('lockSnapshot');
          spanElement.setAttribute('data-formula', encodedFormula);
          spanElement.innerHTML = `<img class="math-tex-loading" src="${spinner}" />`;
          editor.fire('unlockSnapshot');

          function updateImg(base64) {
            editor.fire('lockSnapshot');
            const img = spanElement.querySelector('img');
            img.setAttribute('src', `data:image/png;base64,${base64}`);
            img.removeAttribute('class');
            editor.fire('unlockSnapshot');
          }

          const cachedBase64 = FORMULA_CACHE.get(formula);
          if (cachedBase64) {
            updateImg(cachedBase64);
          } else {
            const url = `${this.previewUrl}?formula=${encodedFormula}&cache=true&size=large`;
            Ajax.get(url).done((base64) => {
              updateImg(base64);
              FORMULA_CACHE.set(formula, base64);
            });
          }
        }
      }
    });
  }

  render() {
    return null;
  }
}

FormulaPreview.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(FormulaPreview);
