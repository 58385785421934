import React from 'react';
import Config from '../config';
import PrivatePage from '../common/containers/PrivatePage';
import Content from '../common/containers/Content';
import Ajax from '../common/ajax';

import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex/dist/umd/react-reflex.min';
import ReflexViewport from '../common/components/ReflexViewport';
import CKEditor from './ckeditor/CKEditor';

import 'react-reflex/styles.css';
import './css/custom.css';
import './css/editor.contents.css';
import './css/editor.comments.css';
import './css/editor.contextmenu.css';
import './css/editor.css';
import './css/editor.footnote.css';
import './css/editor.headercounter.css';
import './css/editor.nlp.css';
import './css/editor.reference.css';
import LeftMenu from './components/leftmenu/LeftMenu';
import RightSidebar from './components/RightSidebar';
import EditorNavbar from './components/EditorNavbar';
import URLS from '../urls';
import Waiting from '../common/containers/Waiting';
import Previewer from './components/previewer/Previewer';
import Citation from './components/citation/Citation';
import Footnote from './components/Footnote';
import Comment from './components/comment/Comment';
import CrossrefFigure from './components/crossref/CrossrefFigure';
import CrossrefTable from './components/crossref/CrossrefTable';
import CrossrefSection from './components/crossref/CrossrefSection';
import CrossrefFormula from './components/crossref/CrossrefFormula';
import HtmlUtils from '../common/htmlutils';
import Proofread from './components/proofread/Proofread';
import ErrorBoundary from '../common/containers/ErrorBoundary';

import { withNamespaces } from 'react-i18next';
import ModalAlert from '../common/components/ModalAlert';
import Button from '../common/components/Button';
import CitationWidgetUpdater from './components/citation/CitationWidgetUpdater';
import CommentsPanel from './components/comment/CommentsPanel';
import IncludePDF from './components/image/IncludePDF';
import IncludeImage from './components/image/IncludeImage';
import Formula from './components/formula/Formula';
import CodeSnippet from './components/codeSnippet/CodeSnippet';
import ToolbarControl from './components/ToolbarControl';

class EditorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
      ckeditorLoaded: false,
      attributeLoaded: false,
      currentAttribute: 'content',
      isCustomAttribute: false,
      ckeditorData: null,
      currentPage: 1,
      totalPages: 0,
      cannotLoadDocumentMessage: null,
      showCommentsPane: false,
    };
    this.editorInstance = null;
    this._mounted = false;
    this.onResizeEditorPane = this.onResizeEditorPane.bind(this);
    this.onEditorInstanceReady = this.onEditorInstanceReady.bind(this);
    this.onBeforeAttributeChange = this.onBeforeAttributeChange.bind(this);
    this.onAfterAttributeChange = this.onAfterAttributeChange.bind(this);
    this.getMainCKEditorConfig = this.getMainCKEditorConfig.bind(this);
    this.fetchDocument = this.fetchDocument.bind(this);
    this.resizeEditor = this.resizeEditor.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    document.body.className = 'editor-body';
    document.documentElement.className = 'editor-body';
    this.fetchDocument();
  }

  componentWillUnmount() {
    this._mounted = false;
    document.body.className = '';
    document.documentElement.className = '';
    window.removeEventListener('resize', this.resizeEditor);
  }

  setStateIfMounted(state, callback) {
    if (this._mounted) {
      this.setState(state, callback);
    }
  }

  fetchDocument() {
    let documentId;

    if (this.props.match) {
      documentId = this.props.match.params.documentId;
    } else {
      documentId = this.state.document.id;
    }

    const url = `${Config.apiHost}documents/${documentId}/`;
    const xhr = Ajax.get(url).done((document) => {
      this.setStateIfMounted((state) => ({
        ...state,
        document,
        cannotLoadDocumentMessage: null,
      }));
      window.editorDocument = document;
    });

    xhr.fail((jqXHR) => {
      const { i18n } = this.props;
      let message = i18n.t('Não foi possível abrir o documento');
      if (jqXHR.status === 403) {
        message = i18n.t('Você não tem permissão para abrir esse documento');
      } else if (jqXHR.status === 404) {
        message = i18n.t('Documento não encontrado');
      }
      this.setStateIfMounted((state) => ({
        ...state,
        cannotLoadDocumentMessage: message,
      }));
    });

    return xhr;
  }

  getMainCKEditorConfig() {
    return {
      toolbar: 'FullV2',
      readOnly: false,
      on: {
        instanceReady: this.onEditorInstanceReady,
        // [Events.RELOAD_DOCUMENT]: this.fetchDocument,
      },
      extraPlugins: [
        'fastformatFormat',
        'fastformatCitation',
        'fastformatComments',
        'fastformatFootnote',
        'fastformatImage',
        'fastformatLayout',
        'fastformatLink',
        // 'fastformatMathjax',
        // 'mathjax',
        'fastformatFormula',
        'fastformatNoIndent',
        'fastformatProofread',
        'fastformatSave',
        'fastformatTable',
        'fastformatTableResize',
        'fastformatZoom',
        'fastformatDisableGrammarly',
        'image2',
        'divarea',
        'notificationaggregator',
        'fastformatCleanerV2',
        this.state.document.type !== 'presentation' ? 'fastformatIncludePDF' : null,
        // 'fastformatCodesnippet',
        'fastformatCodeSnippetV2',
        'fastformatHideButtons',
        'fastformatMarkdownToHTML',
      ]
        .filter((pluginName) => !!pluginName)
        .join(','),
    };
  }

  onEditorInstanceReady(evt) {
    this.editorInstance = evt.editor;
    this.editorInstance.undoManager.strokesLimit = 10;
    this.editorInstance.undoManager.limit = 100;
    this.resizeEditor();
    this.setStateIfMounted((state) => ({
      ...state,
      ckeditorLoaded: true,
    }));

    this.editorInstance.on('fastformatToggleCommentPanel', () => {
      this.setStateIfMounted(
        (state) => ({
          ...state,
          showCommentsPane: !state.showCommentsPane,
          ckeditorData: this.editorInstance.getData(),
        }),
        this.resizeEditor,
      );
    });

    window.addEventListener('resize', this.resizeEditor);
  }

  onResizeEditorPane() {
    this.resizeEditor();
  }

  resizeEditor() {
    if (this.editorInstance && this.editorContainer && this.editorContainer.parentNode) {
      try {
        this.editorInstance.resize(
          this.editorContainer.parentNode.clientWidth,
          this.editorContainer.parentNode.clientHeight,
        );
      } catch (e) {
        (console.error || console.log).call(console, e.stack || e);
      }
    }
  }

  onBeforeAttributeChange(attributeName, isCustom) {
    console.log('Changing attribute to', attributeName, isCustom);
  }

  onAfterAttributeChange(attributeName, isCustom, data) {
    this.setStateIfMounted(
      (state) => ({
        ...state,
        attributeLoaded: true,
        currentAttribute: attributeName,
        isCustomAttribute: isCustom,
        ckeditorData: data,
      }),
      () => {
        this.editorInstance.resetDirty();
        this.editorInstance.resetUndo();
        this.editorInstance.focus();
      },
    );
  }

  renderPreviewer() {
    if (this.isDocumentAndEditorReady()) {
      return <Previewer editor={this.editorInstance} document={this.state.document} />;
    }
    return null;
  }

  isDocumentAndEditorReady() {
    return this.state.document && this.editorInstance && this.state.ckeditorLoaded;
  }

  render() {
    const { i18n } = this.props;

    if (this.state.cannotLoadDocumentMessage) {
      return (
        <ModalAlert type="warning" show={true} onClose={() => (document.location = URLS.documents)}>
          <h3>{this.state.cannotLoadDocumentMessage}</h3>
          <br />
          <Button type="primary" onClick={() => (document.location = URLS.documents)}>
            {i18n.t('Fechar')}
          </Button>
        </ModalAlert>
      );
    }

    if (!this.state.document) {
      return null;
    }

    const canMountComponents = this.isDocumentAndEditorReady() && this.state.attributeLoaded;

    let navbar = null;

    if (canMountComponents) {
      navbar = (
        <EditorNavbar
          document={this.state.document}
          editor={this.editorInstance}
          attribute={this.state.currentAttribute}
          isCustomAttribute={this.state.isCustomAttribute}
        />
      );
    }

    const title = this.state.document.title
      ? HtmlUtils.stripHtmlTags(this.state.document.title)
      : i18n.t('Documento sem título');

    const isLoading = !this.isDocumentAndEditorReady() || !this.state.attributeLoaded;

    const leftMenuWidth = 0.12;
    const commentsWidth = 0.2;
    const editorWidth = (1 - leftMenuWidth) / 2;
    const previewerWidth = this.state.showCommentsPane ? editorWidth - commentsWidth : editorWidth;

    const editorConfig = this.getMainCKEditorConfig();

    return (
      <PrivatePage
        key={this.state.document.id}
        title={title}
        hasLeftSidebar={false}
        navbar={isLoading ? <span /> : navbar}
        classes="ff-editor"
        loadTrackers={false} // Trying to isolate the problem of loosing sections formatting.
      >
        <Content noPadding={true}>
          <Waiting isProcessing={isLoading} useLogo={true} message={i18n.t('Carregando editor, aguarde')}>
            <ReflexViewport>
              <ReflexContainer orientation="vertical">
                <ReflexElement className="left-pane" flex={leftMenuWidth}>
                  <ErrorBoundary>
                    {this.isDocumentAndEditorReady() && (
                      <LeftMenu
                        document={this.state.document}
                        editor={this.editorInstance}
                        onAfterAttributeChange={this.onAfterAttributeChange}
                        onBeforeAttributeChange={this.onBeforeAttributeChange}
                      />
                    )}
                  </ErrorBoundary>
                </ReflexElement>

                <ReflexSplitter propagate={true} />

                <ReflexElement className="middle-pane" onResize={this.onResizeEditorPane} flex={editorWidth}>
                  <div id="editor-container" ref={(el) => (this.editorContainer = el)}>
                    <ErrorBoundary>
                      {this.state.document && (
                        <CKEditor
                          id="ckeditor-main"
                          content={this.state.ckeditorData}
                          type={this.state.document.type}
                          config={editorConfig}
                          languageCode={i18n.language}
                        />
                      )}
                    </ErrorBoundary>
                  </div>
                </ReflexElement>

                {canMountComponents && this.state.showCommentsPane && <ReflexSplitter propagate={true} />}

                {canMountComponents && this.state.showCommentsPane && (
                  <ReflexElement className="middle-pane" flex={commentsWidth}>
                    <CommentsPanel
                      key={this.state.currentAttribute}
                      document={this.state.document}
                      editor={this.editorInstance}
                    />
                  </ReflexElement>
                )}

                <ReflexSplitter propagate={true} />

                <ReflexElement className="right-pane" flex={previewerWidth}>
                  <ErrorBoundary>{this.renderPreviewer()}</ErrorBoundary>
                </ReflexElement>
              </ReflexContainer>
            </ReflexViewport>
            {canMountComponents && <RightSidebar document={this.state.document} />}

            {canMountComponents && (
              <IncludeImage
                key={this.editorInstance.id + '-include-image'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <Citation
                key={this.editorInstance.id + '-citation'}
                id="mainCitation"
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <CitationWidgetUpdater
                key={this.editorInstance.id + '-citation-widget'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <Footnote
                key={this.editorInstance.id + '-footnote'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <Comment
                key={this.editorInstance.id + '-comment'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <CrossrefFigure
                key={this.editorInstance.id + '-crossref-figure'}
                document={this.state.document}
                editor={this.editorInstance}
                currentAttribute={this.state.currentAttribute}
              />
            )}

            {canMountComponents && (
              <CrossrefTable
                key={this.editorInstance.id + '-crossref-table'}
                document={this.state.document}
                editor={this.editorInstance}
                currentAttribute={this.state.currentAttribute}
              />
            )}

            {canMountComponents && (
              <CrossrefSection
                key={this.editorInstance.id + '-crossref-section'}
                document={this.state.document}
                editor={this.editorInstance}
                currentAttribute={this.state.currentAttribute}
              />
            )}

            {canMountComponents && (
              <CrossrefFormula
                key={this.editorInstance.id + '-crossref-formula'}
                document={this.state.document}
                editor={this.editorInstance}
                currentAttribute={this.state.currentAttribute}
              />
            )}

            {canMountComponents && (
              <Proofread
                key={this.editorInstance.id + '-proofread'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <IncludePDF
                key={this.editorInstance.id + '-include-pdf'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <Formula
                key={this.editorInstance.id + '-formula'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <CodeSnippet
                key={this.editorInstance.id + '-code-snippet'}
                document={this.state.document}
                editor={this.editorInstance}
              />
            )}

            {canMountComponents && (
              <ToolbarControl key={this.editorInstance.id + '-toolbar-control'} editor={this.editorInstance} />
            )}

            {/* {canMountComponents && <EditorTour key={this.editorInstance.id + '-tour'} />} */}
          </Waiting>
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(EditorPage);
