import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import Ajax from '../../common/ajax';
import ModalConfirm from '../../common/containers/ModalConfirm';
import FileSaver from 'file-saver';
import { withNamespaces } from 'react-i18next';

const propTypes = {
  references: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
};

class ExportButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formatted_types: [],
      formatted_languages: [],
      showExportModal: false,
      modalProcessing: false,
      exportForm: {
        format: 'abnt',
        language: 'brazil',
        citations: 'true',
      },
    };
    this.onBibtexClickHandler = this.onBibtexClickHandler.bind(this);
    this.onFormattedClickHandler = this.onFormattedClickHandler.bind(this);
    this.onExportModalCancel = this.onExportModalCancel.bind(this);
    this.onExportModalConfirm = this.onExportModalConfirm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.downloadLink = undefined;
  }

  componentDidMount() {
    this.fetchTypes();
    this.fetchLanguages();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      exportForm: {
        ...this.state.exportForm,
        [name]: value,
      },
    });
  }

  getExportDownloadLink() {
    const ids = this.props.references.map((r) => r.id).join(',');
    let params = `ids=${ids}&format_type=${this.state.exportForm.format}&lang=${this.state.exportForm.language}`;
    if (this.state.exportForm.citations === 'true') {
      params += `&citations=${this.state.exportForm.citations}`;
    }
    return `${Config.apiHost}references/export_formatted/?${params}`;
  }

  onBibtexClickHandler() {
    const ids = this.props.references.map((r) => r.id).join(',');
    const url = `${Config.apiHost}references/export_bibtex/?ids=${ids}`;
    Ajax.get(url).done((response) => {
      const blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, 'references.bib');
    });
  }

  onFormattedClickHandler() {
    this.setState({
      ...this.state,
      showExportModal: true,
      modalProcessing: false,
    });
  }

  fetchTypes() {
    const url = `${Config.apiHost}references/export_formatted_types/`;
    Ajax.get(url).done((types) => {
      this.setState({
        ...this.state,
        formatted_types: types,
      });
    });
  }

  fetchLanguages() {
    const url = `${Config.apiHost}references/export_formatted_languages/`;
    Ajax.get(url).done((languages) => {
      this.setState({
        ...this.state,
        formatted_languages: languages,
      });
    });
  }

  renderFormattedTypeOptions() {
    return this.state.formatted_types.map((type) => {
      return (
        <option key={type[0]} value={type[0]} selected={type[0] === 'abnt'}>
          {type[1]}
        </option>
      );
    });
  }

  renderFormattedLanguagesOptions() {
    return this.state.formatted_languages.map((language) => {
      return (
        <option
          key={language[0]}
          value={language[0]}
          selected={language[0] === 'brazil'}
        >
          {language[1]}
        </option>
      );
    });
  }

  onExportModalCancel() {
    this.setState({
      ...this.state,
      showExportModal: false,
      modalProcessing: false,
    });
  }

  onExportModalConfirm() {
    this.setState({
      ...this.state,
      modalProcessing: true,
    });

    window.addEventListener('focus', window_focus, false);
    let that = this;
    function window_focus() {
      window.removeEventListener('focus', window_focus, false);
      that.setState({
        ...this.state,
        modalProcessing: false,
        showExportModal: false,
      });
    }

    this.downloadLink.click();
  }

  render() {
    const { i18n } = this.props;
    const disabled =
      this.props.disabled || this.props.references.length === 0
        ? 'disabled'
        : '';
    return (
      <div className="btn-group" role="group">
        <button
          id="id_export_button"
          type="button"
          className={`btn btn-default btn-md dropdown-toggle ${disabled}`}
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <span
            className="mdi mdi-cloud-upload"
            title={i18n.t('Exportar')}
            data-toggle="tooltip"
            data-placement="bottom"
          />{' '}
          <span className="hidden-md hidden-sm">{i18n.t('Exportar')}</span>
        </button>
        <ul className="dropdown-menu" role="menu">
          <li>
            <a role="button" onClick={this.onBibtexClickHandler}>
              BibTex
            </a>
          </li>
          <li>
            <a role="button" onClick={this.onFormattedClickHandler}>
              {i18n.t('Formatado')}
            </a>
          </li>
        </ul>

        <ModalConfirm
          title={i18n.t('Exportar formatado')}
          show={this.state.showExportModal}
          onCancel={this.onExportModalCancel}
          onConfirm={this.onExportModalConfirm}
          isProcessing={this.state.modalProcessing}
        >
          <form method="get">
            <div className="form-group">
              <label htmlFor="id_format">{i18n.t('Estilo')}</label>
              <select
                id="id_format"
                className="form-control"
                name="format"
                onChange={this.handleInputChange}
              >
                {this.renderFormattedTypeOptions()}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="id_language">Idioma</label>
              <select
                id="id_language"
                className="form-control"
                name="language"
                onChange={this.handleInputChange}
              >
                {this.renderFormattedLanguagesOptions()}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="id_citations">
                {i18n.t('Opções de impressão')}
              </label>
              <select
                id="id_citations"
                className="form-control"
                name="citations"
                onChange={this.handleInputChange}
              >
                <option value="false">{i18n.t('Imprimir referências')}</option>
                <option value="true" selected={true}>
                  {i18n.t('Imprimir referências e citações')}
                </option>
              </select>
            </div>
          </form>
          <a
            style={{ display: 'none' }}
            ref={(el) => (this.downloadLink = el)}
            href={this.getExportDownloadLink()}
            target="_blank"
          />
        </ModalConfirm>
      </div>
    );
  }
}

ExportButton.propTypes = propTypes;

export default withNamespaces()(ExportButton);
