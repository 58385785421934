import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/containers/Modal';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import Button from '../../../common/components/Button';
import Events from '../Events';

import './custom.css';
import { AlertError, AlertSuccess } from '../../../common/components/Alert';

import { withNamespaces } from 'react-i18next';
import Tip from '../../../common/components/onboarding/Tip';
import storage from '../../../common/storage';

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      processing: false,
      success: null,
      error: null,
      htmlForm: '',
    };
    this.configureTabClick = this.configureTabClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.save = this.save.bind(this);
    this.formRef = React.createRef();
  }

  configureTabClick() {
    if (this.formRef.current) {
      const nodes = this.formRef.current.querySelectorAll('[role=tab]');
      if (nodes.length) {
        // Select previous tab.
        const storageKey = `ff-options-tab-${this.props.document.id}`;
        const selectedTab = storage.getItem(storageKey);
        if (selectedTab) {
          nodes.forEach((node) => node.getAttribute('href') === selectedTab && node.click());
        }
        // Configure click to save the active tab.
        nodes.forEach((node) => (node.onclick = (e) => storage.setItem(storageKey, e.target.getAttribute('href'))));
        return;
      }
    }
    setTimeout(this.configureTabClick, 1000);
  }

  openModal() {
    this.setState((state) => ({
      ...state,
      processing: true,
      showModal: true,
      success: null,
      error: null,
    }));

    const url = `${Config.djangoHost}e/options/${this.props.document.id}`;
    Ajax.get(url).done((htmlForm) => {
      const div = document.createElement('div');
      div.innerHTML = htmlForm;
      $(div).find('select').addClass('form-control');
      this.setState((state) => ({
        ...state,
        processing: false,
        htmlForm: div.innerHTML,
      }));
      this.configureTabClick();
    });
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  }

  save() {
    const { i18n } = this.props;

    this.setState((state) => ({
      ...state,
      processing: true,
      success: null,
      error: null,
    }));

    const form = document.getElementById('id-options-form');
    const formData = new FormData(form);
    const url = `${Config.djangoHost}e/options/${this.props.document.id}`;
    Ajax.postFormData(url, formData)
      .done(() => {
        this.setState((state) => ({
          ...state,
          processing: false,
          success: i18n.t('As informações foram salvas com sucesso!'),
        }));
        this.props.editor.fire(Events.COMPILE);
      })
      .fail(() => {
        this.setState((state) => ({
          ...state,
          processing: false,
          error: i18n.t('As informações não poderam ser salvas.'),
        }));
      })
      .always(() => {
        const modalBody = $(this.formRef.current).closest('.modal-body')[0];
        if (modalBody) {
          modalBody.scrollTop = 0;
        }
      });
  }

  render() {
    const { i18n } = this.props;

    const footer = (
      <>
        <Button type="default" size="lg" onClick={this.closeModal}>
          {i18n.t('Fechar')}
        </Button>
        <Button type="primary" size="lg" onClick={this.save}>
          {i18n.t('Salvar')}
        </Button>
      </>
    );

    return (
      <>
        <a
          role="button"
          title={i18n.t('Configurar opções para personalizar seu documento')}
          data-toggle="tooltip"
          data-placement="bottom"
          onClick={this.openModal}
        >
          <span className="icon mdi mdi-settings" />
          <span className="hidden-md hidden-sm hidden-xs">
            &nbsp;
            {i18n.t('Configurações')}
          </span>
        </a>
        <Modal
          title={i18n.t('Configurações')}
          show={this.state.showModal}
          isProcessing={this.state.processing}
          width="full"
          onCancel={this.closeModal}
          footer={footer}
          bodyMinHeight="calc(70vh)"
        >
          <AlertSuccess>{this.state.success}</AlertSuccess>
          <AlertError>{this.state.error}</AlertError>

          {this.state.htmlForm && (
            <Tip
              title={i18n.t('Sobre as configurações...')}
              tips={[
                i18n.t(
                  'Você pode utilizar a janela de Configurações para mudar a forma como seu documento é formatado.',
                ),
                i18n.t(
                  'A configuração inicial é definida de acordo com o padrão do modelo escolhido. Raramente você precisará alterar esses valores.',
                ),
                i18n.t(
                  'As opções de configuração dependem do modelo escolhido. Isto é, um modelo de TCC tem opções diferentes de um modelo de artigo. Assim como modelos de instituições diferentes podem ter configurações diferentes.',
                ),
                <span>
                  {i18n.t('Algumas configurações possuem uma explicação no ícone')} <i className="fas fa-info-circle" />
                </span>,
              ]}
            />
          )}

          <form id="id-options-form" ref={this.formRef}>
            <div dangerouslySetInnerHTML={{ __html: this.state.htmlForm }} />
          </form>
        </Modal>
      </>
    );
  }
}

Options.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Options);
