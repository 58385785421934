import React from "react";
import Modal from "../../common/containers/Modal";
import Waiting from "../../common/containers/Waiting";
import Button from "../../common/components/Button";
import Config from "../../config";
import Ajax from "../../common/ajax";
import PropTypes from "prop-types";
import Upload from "../../common/components/upload/Upload";
import {AlertError, AlertSuccess} from "../../common/components/Alert";
import {withNamespaces} from "react-i18next";

class ImportBibtex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBibtexModal: false,
            processing: false,
            importMessage: null,
            errorMessage: null,
            form: {
                folder: '',
            },
            folders: [],
        };
        this.openBibtexModal = this.openBibtexModal.bind(this);
        this.closeBibtexModal = this.closeBibtexModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onStart = this.onStart.bind(this);
        this.onUploadFinished = this.onUploadFinished.bind(this);
        this.uniqueKey = new Date().getMilliseconds();
    }

    openBibtexModal() {
        this.fetchUserFolders();
        this.setState(state => ({
            ...state,
            showBibtexModal: true,
            importMessage: null,
            errorMessage: null,
        }));
        this.uniqueKey = new Date().getMilliseconds();
    }

    closeBibtexModal() {
        this.setState({
            ...this.state,
            showBibtexModal: false,
        });
    }

    fetchUserFolders() {
        this.setState(state => ({
            ...state,
            processing: true,
        }));
        const url = `${Config.apiHost}folders/`;
        Ajax.get(url).done(folders => {
            this.setState(state => ({
                ...state,
                folders,
                processing: false,
            }));
        });
    }

    onChange(e) {
        e.persist();
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [e.target.name]: e.target.value,
            },
        }));
    }

    onSuccess(response) {
        const {i18n} = this.props;
        let msg = `${response.added} ${i18n.t('adicionadas com sucesso')}. ${response.skipped} ${i18n.t('não foram adicionadas porque eram duplicadas')}.`;

        this.setState(state => ({
            ...state,
            importMessage: msg,
            processing: false,
        }));

        this.props.onImport && this.props.onImport();
    }

    onStart() {
        this.setState(state => ({
            ...state,
            importMessage: null,
            errorMessage: null,
        }));
    }

    onUploadFinished() {
        // Upload has finished and it will start the importing on the server.
        this.setState(state => ({
            ...state,
            processing: true,
        }));
    }

    onError(jqXHR) {
        const {i18n} = this.props;
        this.setState(state => ({
            ...state,
            errorMessage: i18n.t('Não foi possível importar as referências.'),
            processing: false,
        }));
    }

    renderBibtexModal() {
        const {i18n} = this.props;

        const footer = (
            <Button size="lg"
                    type="primary"
                    processing={this.state.processing}
                    onClick={this.closeBibtexModal}>
                {i18n.t('Fechar')}
            </Button>
        );

        return (
            <Modal title={i18n.t('Importar BibTeX')}
                   show={this.state.showBibtexModal}
                   footer={footer}
                   onCancel={this.closeBibtexModal}>
                <Waiting isProcessing={this.state.processing}>
                    <AlertError>{this.state.errorMessage}</AlertError>
                    <AlertSuccess>{this.state.importMessage}</AlertSuccess>
                    <div className="form-group">
                        <label htmlFor="selectFolder">
                            {i18n.t('Marcador')}
                        </label>
                        <select id="selectFolder"
                                className="form-control"
                                name="folder"
                                value={this.state.form.folder}
                                onChange={this.onChange}>
                            <option value="">Selecione</option>
                            {this.state.folders.map(f => {
                                return (
                                    <option key={f.id} value={f.id}>
                                        {f.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Upload key={this.uniqueKey}
                            url={`${Config.apiHost}references/import_bibtex/?folder=${this.state.form.folder}`}
                            accept={['.bib']}
                            multiple={false}
                            onSuccess={this.onSuccess}
                            onStart={this.onStart}
                            onError={this.onError}
                            onUploadFinished={this.onUploadFinished} />
                </Waiting>
            </Modal>
        );
    }

    render() {
        const {i18n} = this.props;
        return (
            <React.Fragment>
                <a role="button" onClick={this.openBibtexModal}>
                    {i18n.t('Importar BibTeX')}
                </a>
                {this.renderBibtexModal()}
            </React.Fragment>
        );
    }
}

ImportBibtex.propTypes = {
    onImport: PropTypes.func,
};

export default withNamespaces()(ImportBibtex);