import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import MediaBrowser from './MediaBrowser';

class IncludeImage extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      show: false,
      ckEditorCallFunctionNumber: null,
      selectedImage: null,
      newSelectedImage: null,
    };

    this.state = { ...this.initialState };
    this.configureImageModal = this.configureImageModal.bind(this);
    this.onImageSelected = this.onImageSelected.bind(this);
    this.onCloseMediaBrowser = this.onCloseMediaBrowser.bind(this);
    this.imagePreviewElement = null;
    this.ckeDialog = null;
  }

  componentDidMount() {
    this._mounted = true;
    this.props.editor.on('onFileBrowserClick', (evt) => {
      this.setState((state) => ({
        ...state,
        show: true,
        ckEditorCallFunctionNumber: evt.data,
      }));
    });

    this.props.editor.on('dialogShow', (evt) => {
      if (evt.data && evt.data.widget && evt.data.widget.name === 'image') {
        this.configureImageModal(evt);
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  configureImageModal(evt) {
    this.ckeDialog = evt.data;

    if (this.ckeDialog.widget.data.src) {
      this.setState(() => ({
        ...this.initialState,
        selectedImage: {
          file_for_editor: this.ckeDialog.widget.data.src,
        },
      }));
    } else {
      this.setState(() => ({
        ...this.initialState,
      }));
    }

    const table = this.ckeDialog.parts.contents.$.closest('table');

    if (table) {
      const dialogElement = table.closest('.cke_dialog');
      this.imagePreviewElement = document.getElementById('image-preview');

      if (this.imagePreviewElement) {
        this.imagePreviewElement.src = this.ckeDialog.widget.data.src;
      } else {
        const div = document.createElement('div');
        div.innerHTML = `
          <div style="max-height: 200px; overflow: auto; text-align: center;" class="ff-scrollbar">
            <img id="image-preview" alt="" style="width: 200px;" src="${this.ckeDialog.widget.data.src}" />
          </div>
        `;
        table.parentElement.insertBefore(div.querySelector('div'), table);
      }

      if (dialogElement) {
        dialogElement.style.top = '10%';
      }

      // Remove label image only for the first input (src).
      const srcInput = table.querySelector('.cke_dialog_ui_input_text');
      if (srcInput) {
        const uiText = srcInput.closest('.cke_dialog_ui_text');
        if (uiText) {
          const label = uiText.querySelector('.cke_dialog_ui_labeled_label');
          if (label) {
            label.remove();
          }
        }
      }

      // Set image button margin
      const browserButton = table.querySelector('.cke_dialog_ui_button');
      if (browserButton) {
        browserButton.style.marginTop = null;
        // Start media browser opened.
        if (!this.ckeDialog.widget.data.src) {
          browserButton.click();
        }
      }

      this.imagePreviewElement = document.getElementById('image-preview');
    }
  }

  onImageSelected(newSelectedImage) {
    let imagePath = newSelectedImage.file_for_editor;

    if (this.imagePreviewElement) {
      const oldPreviewSrc = this.imagePreviewElement.src;
      if (oldPreviewSrc.endsWith(imagePath)) {
        // Strategy to skip cache on previewing edited images.
        imagePath += '?' + new Date().getTime();
      }
      this.imagePreviewElement.src = imagePath;
      this.imagePreviewElement.closest('.cke_dialog').style.top = '10%';
    }

    CKEDITOR.tools.callFunction(
      this.state.ckEditorCallFunctionNumber,
      imagePath
    );

    this.setState((state) => ({ ...state, show: false, newSelectedImage }));
  }

  onCloseMediaBrowser() {
    const { selectedImage, newSelectedImage } = this.state;
    if (!selectedImage && !newSelectedImage) {
      this.ckeDialog.hide();
    } else if (this.imagePreviewElement) {
      // Avoid cache in case the same selected image has been edited in the image editor.
      this.imagePreviewElement.src =
        this.imagePreviewElement.src.split('?')[0] + '?' + new Date().getTime();
    }

    this.setState((state) => ({
      ...state,
      show: false,
    }));
  }

  render() {
    if (this.state.show) {
      return (
        <MediaBrowser
          documentId={this.props.document.id}
          editor={this.props.editor}
          mediaTypes={['image']}
          onImageClick={this.onImageSelected}
          onClose={this.onCloseMediaBrowser}
        />
      );
    }
    return null;
  }
}

IncludeImage.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(IncludeImage);
