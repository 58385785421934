import React from 'react';
import PropTypes from 'prop-types';

export default class RightSidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return null;
    }
}

RightSidebar.propTypes = {
    document: PropTypes.object.isRequired,
};