import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/containers/Modal';
import DocumentHistory from '../../documents/DocumentHistory';
import { withNamespaces } from 'react-i18next';

class HistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const { i18n } = this.props;
    return (
      <React.Fragment>
        <a
          role="button"
          title={i18n.t('Visualizar histórico de modificações')}
          data-toggle="tooltip"
          data-placement="bottom"
          onClick={() => this.setState((s) => ({ ...s, show: true }))}
        >
          <span className="icon mdi mdi-time-restore" />
          &nbsp;
          <span className="hidden-lg hidden-md hidden-sm hidden-xs">
            {i18n.t('Histórico de modificações')}
          </span>
        </a>
        <Modal
          title={i18n.t('Histórico de modificações')}
          width="full"
          show={this.state.show}
          onCancel={() => this.setState((s) => ({ ...s, show: false }))}
          bodyMinHeight="calc(80vh)"
        >
          <DocumentHistory documentId={this.props.documentId} asPanel={false} />
        </Modal>
      </React.Fragment>
    );
  }
}

HistoryModal.propTypes = {
  documentId: PropTypes.number.isRequired,
};

export default withNamespaces()(HistoryModal);
