import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Waiting from '../../common/containers/Waiting';
import { withNamespaces } from 'react-i18next';

const propTypes = {
  references: PropTypes.arrayOf(PropTypes.object).isRequired,
  onMovedToFolder: PropTypes.func,
  onRemovedFromFolder: PropTypes.func,
  disabled: PropTypes.bool,
};

class MoveToFolderButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      processing: false,
    };
    this.onCheckboxChangeHandler = this.onCheckboxChangeHandler.bind(this);
    this.fetchFolders = this.fetchFolders.bind(this);
  }

  fetchFolders() {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}folders/`;
    Ajax.get(url).done((folders) => {
      this.setState((state) => ({
        ...state,
        folders,
        processing: false,
      }));
    });
  }

  isReferencesInFolder(folder) {
    let isInFolder = true;
    this.props.references.forEach((reference) => {
      if (
        reference.folders.filter((refFolder) => refFolder.id === folder.id)
          .length === 0
      ) {
        isInFolder = false;
      }
    });
    return isInFolder;
  }

  onCheckboxChangeHandler(e, folder) {
    const ids = this.props.references.map((ref) => ref.id).join(',');
    const data = { reference_ids: ids };

    if (e.target.checked) {
      const url = `${Config.apiHost}folders/${folder.id}/add_references/`;
      Ajax.post(url, data).done(() => {
        this.setState({ state: this.state });
        this.props.onMovedToFolder(folder);
      });
    } else {
      const url = `${Config.apiHost}folders/${folder.id}/remove_references/`;
      Ajax.delete_(url, data).done(() => {
        this.setState({ state: this.state });
        this.props.onRemovedFromFolder(folder);
      });
    }

    e.preventDefault();
  }

  renderFolders() {
    return this.state.folders.map((folder) => {
      const checked = this.isReferencesInFolder(folder);
      return (
        <li key={folder.id}>
          <a role="button">
            <span className="be-checkbox" style={{ marginLeft: '10px' }}>
              <input
                id={folder.id}
                type="checkbox"
                checked={checked}
                onChange={(e) => this.onCheckboxChangeHandler(e, folder)}
              />
              <label htmlFor={folder.id}>{folder.name}</label>
            </span>
          </a>
        </li>
      );
    });
  }

  render() {
    const { i18n } = this.props;
    const disabled =
      this.props.disabled || this.props.references.length === 0
        ? 'disabled'
        : '';
    return (
      <div className="btn-group" role="group">
        <button
          type="button"
          className={`btn btn-default btn-md dropdown-toggle ${disabled}`}
          data-toggle="dropdown"
          aria-expanded="false"
          onClick={this.fetchFolders}
        >
          <i
            className="mdi mdi-tag"
            title={i18n.t('Marcador')}
            data-toggle="tooltip"
            data-placement="bottom"
          />{' '}
          <span className="hidden-md hidden-sm">{i18n.t('Marcador')}</span>
        </button>
        <ul
          className="dropdown-menu ff-scrollbar"
          role="menu"
          style={{ maxHeight: '400px', overflowY: 'auto' }}
        >
          <Waiting isProcessing={this.state.processing}>
            {this.renderFolders()}
          </Waiting>
        </ul>
      </div>
    );
  }
}

MoveToFolderButton.propTypes = propTypes;

export default withNamespaces()(MoveToFolderButton);
