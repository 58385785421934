import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/containers/Modal';
import Button from '../../common/components/Button';
import Config from '../../config';
import Ajax from '../../common/ajax';
import { withNamespaces } from 'react-i18next';
import { showDjangoValidationMessages } from '../../common/validation';
import { AlertError, AlertInfo, AlertSuccess } from '../../common/components/Alert';

class ModelsAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      processing: false,
      showSuccess: false,
      showError: false,
      form: {
        name: '',
        description: '',
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.insertModel = this.insertModel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  openModal() {
    this.setState((state) => ({
      ...state,
      showModal: true,
      processing: false,
      showSuccess: false,
      showError: false,
    }));
  }

  closeModal() {
    this.setState({
      ...this.state,
      showModal: false,
    });
  }

  insertModel() {
    this.setState((state) => ({
      ...state,
      processing: true,
      showError: false,
    }));
    const url = `${Config.apiHost}document-models/`;
    const data = {
      ...this.state.form,
      model: this.props.editor.getData(),
    };
    Ajax.post(url, data)
      .done((response) => {
        this.setState((state) => ({ ...state, showSuccess: true }));
      })
      .fail((jqXHR) => {
        showDjangoValidationMessages(jqXHR.responseJSON);
        this.setState((state) => ({ ...state, showError: true }));
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => ({
      ...state,
      form: {
        ...this.state.form,
        [name]: value,
      },
    }));
  }

  render() {
    const { i18n } = this.props;
    let footer;

    if (!this.state.showSuccess) {
      footer = (
        <>
          <Button type="default" size="lg" onClick={this.closeModal}>
            {i18n.t('Cancelar')}
          </Button>
          <Button
            type="primary"
            size="lg"
            onClick={this.insertModel}
            disabled={!this.state.form.name || !this.state.form.description}
          >
            {i18n.t('Salvar')}
          </Button>
        </>
      );
    }

    return (
      <>
        <a role="button" onClick={this.openModal}>
          <span className="icon mdi mdi-file-plus" />
          {i18n.t('Salvar como modelo de conteúdo')}
        </a>

        <Modal
          title={i18n.t('Salvar documento como modelo de conteúdo')}
          headerFullColor="primary"
          width="full"
          show={this.state.showModal}
          onCancel={this.closeModal}
          isProcessing={this.state.processing}
          footer={footer}
        >
          {this.state.showSuccess && (
            <AlertSuccess>{i18n.t(`O modelo de conteúdo foi salvo com sucesso.`)}</AlertSuccess>
          )}

          {this.state.showError && <AlertError>{i18n.t(`Não foi possível salvar o modelo.`)}</AlertError>}

          {!this.state.showSuccess && (
            <>
              <AlertInfo>
                {i18n.t(
                  `Utilize essa janela para salvar o conteúdo atual do editor como modelo para futura reutilização em outro documento.`,
                )}
              </AlertInfo>
              <div className="form-group">
                <input
                  name="name"
                  required={true}
                  className="form-control"
                  maxLength="254"
                  placeholder={i18n.t('Nome do modelo')}
                  onChange={this.handleInputChange}
                  value={this.state.form.email}
                />
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  id="description"
                  rows="2"
                  placeholder={i18n.t('Descrição do modelo')}
                  cols="40"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={this.state.form.message}
                  required={true}
                ></textarea>
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }
}

ModelsAdd.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(ModelsAdd);
