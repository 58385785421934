import React, {Component} from 'react';
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

const SCRIPT_URL =
    "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/MathJax.js?config=TeX-MML-AM_HTMLorMML";

const baseConfig = {
    showMathMenu: false,
    tex2jax: {
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
        ]
    },
    skipStartupTypeset: true
};

class MathJaxPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingState: 'loading',
        };
        this.previewRef = React.createRef();
    }

    componentDidMount() {

        if (window.MathJax) {
            this.setState(state => ({...state, loadingState: 'loaded'}));
        } else {
            let mathjaxScriptTag = document.querySelector(
                `script[src="${SCRIPT_URL}"]`
            );
            if (!mathjaxScriptTag) {
                mathjaxScriptTag = document.createElement("script");
                mathjaxScriptTag.async = true;
                mathjaxScriptTag.src = SCRIPT_URL;

                for (const [k, v] of Object.entries(baseConfig || {})) {
                    mathjaxScriptTag.setAttribute(k, v);
                }
                const node = document.head || document.getElementsByTagName("head")[0];
                node.appendChild(mathjaxScriptTag);
            }
            this.configListeners(mathjaxScriptTag);
        }
    }

    configListeners(mathjaxScriptTag) {
        mathjaxScriptTag.addEventListener("load", () => {
            const config = this.props.config || {};
            window.MathJax.Hub.Config({...baseConfig, ...config});
            this.setState(state => ({...state, loadingState: 'loaded'}));
        });
        mathjaxScriptTag.addEventListener("error", () =>
            this.setState(state => ({...state, loadingState: 'failed'})));
    }

    componentDidUpdate() {
        if (this.state.loadingState === 'loaded') {
            const sanitizedMath = DOMPurify.sanitize(this.props.math);
            this.previewRef.current.innerHTML = sanitizedMath;
            window.MathJax.Hub.Queue([
                "Typeset",
                window.MathJax.Hub,
                this.previewRef.current
            ]);
        }
    }

    render() {
        return (
            <span
                className={this.props.className}
                id="react-mathjax-preview"
                style={this.props.style}
            >
                {this.state.loadingState === "failed" && <span>fail loading mathjax lib</span>}
                <span id="react-mathjax-preview-result" ref={this.previewRef} />
            </span>
        );
    }
}

MathJaxPreview.propTypes = {
    config: PropTypes.object,
    className: PropTypes.string,
    math: PropTypes.string,
    style: PropTypes.object
};

export default MathJaxPreview;
