import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { AppContext } from '../../context/global';
import Ajax from '../ajax';
import Modal from '../containers/Modal';
import Waiting from '../containers/Waiting';

class WaitDownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadId: null,
      hasError: false,
      serverErrorMessage: null,
    };
    this.onClick = this.onClick.bind(this);
    this.monitorDownload = this.monitorDownload.bind(this);
    this.iframeRef = React.createRef();
  }

  onClick(e) {
    this.setState(
      (state) => ({
        ...state,
        downloadId: new Date().getTime(),
        hasError: false,
      }),
      () => {
        this.monitorDownload();
        this.iframeRef.current.onload = () => {
          /**
           * If the iframe src responds with an attachment (which is our case),
           * than this onload event is not fired (download dialog is shown instead).
           * On the other hand, if the load event is fired, we can assume that the
           * server responded with error, then we perform a get to get the returned
           * server error.
           */
          Ajax.get(this.props.href).fail((jqXHR) => {
            this.setState((s) => ({
              ...s,
              hasError: true,
              serverErrorMessage: jqXHR.responseJSON && jqXHR.responseJSON.detail,
            }));
          });
        };
        this.iframeRef.current.src = `${this.props.href}?ffDownloadId=${this.state.downloadId}`;
      },
    );

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  monitorDownload() {
    setTimeout(() => {
      if (Cookies.get('ffDownloadId') == this.state.downloadId) {
        this.setState((state) => ({ ...state, downloadId: null }));
        if (this.props.onAfterDownloadStart) {
          this.props.onAfterDownloadStart();
        }
      } else if (!this.state.hasError) {
        this.monitorDownload();
      }
    }, 1000);
  }

  render() {
    const { i18n } = this.props;

    return (
      <>
        <a role="button" onClick={this.onClick} title={this.props.buttonTitle} aria-label={this.props.buttonTitle}>
          <i className={this.props.buttonIcon} /> {!this.props.hideLabel && this.props.buttonTitle}
        </a>

        <iframe src="" ref={this.iframeRef} style={{ display: 'none' }} />

        <Modal
          show={!!this.state.downloadId}
          onCancel={() => this.setState((state) => ({ ...state, downloadId: null }))}
        >
          <Waiting isProcessing={!this.state.hasError}>
            <div className="text-center">
              {!this.state.hasError && (
                <>
                  <span className="modal-main-icon mdi mdi-download" />
                  <p>{i18n.t('O download irá iniciar em alguns instantes...')}</p>
                </>
              )}

              {this.state.hasError && (
                <>
                  <span className="modal-main-icon mdi mdi-alert-octagon text-danger" />
                  <p className="text-danger">
                    {this.props.errorMessage ||
                      this.state.serverErrorMessage ||
                      i18n.t('Houve um problema inesperado e não foi possível concluir o download solicitado.')}
                  </p>
                </>
              )}
            </div>
          </Waiting>
        </Modal>
      </>
    );
  }
}

WaitDownloadLink.contextType = AppContext;

WaitDownloadLink.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onAfterDownloadStart: PropTypes.func,
  hideLabel: PropTypes.bool,
  /**
   * This error message will override the returned server error message.
   */
  errorMessage: PropTypes.string,
};

export default withNamespaces()(WaitDownloadLink);
