import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CrossrefBase from "./CrossrefBase";
import {withNamespaces} from "react-i18next";

class CrossrefFigure extends Component {
    constructor(props) {
        super(props);
        this.getFigureReferences = this.getFigureReferences.bind(this);
        this.insertCrossref = this.insertCrossref.bind(this);
    }

    getFigureReferences(content) {
        const $content = $(`<div>${content}</div>`);
        const references = [];

        const $items = $("img[reference]", $content);
        if ($items.length > 0) {
            $items.each((i, el) => {
                const $img = $(el);
                const filename = $img.attr('src').split('/').reverse()[0];
                const itemName = filename.split(' ').join('');

                if (itemName.endsWith('==')) { // Avoid base64 images
                    return;
                }

                const itemValue = $img.attr('reference');
                let caption = $img.closest('figure').find('figcaption').text();

                if (caption) {
                    caption += ' (' + itemName + ')';
                } else {
                    caption = itemName;
                }

                references.push({
                    caption,
                    reference: itemValue
                });
            });
        }

        return references;
    }

    insertCrossref(reference) {
        console.log(reference);
        const el = [
            `<span contenteditable="false" class="figure-reference" title="${reference.caption}">`,
            `<ff-figure-reference target-reference="${reference.reference}">{fig:${reference.reference}}</ff-figure-reference>`,
            `</span>`
        ].join('');
        this.props.editor.insertHtml(el);
    }

    render() {
        const {i18n} = this.props;
        return (
            <CrossrefBase
                document={this.props.document}
                editor={this.props.editor}
                currentAttribute={this.props.currentAttribute}
                title={i18n.t('Selecione uma figura para referenciar')}
                menuName={i18n.t('Inserir referências de figuras')}
                commandName="crossRefFigureCommand"
                icon="image"
                getReferences={this.getFigureReferences}
                insertCrossref={this.insertCrossref} />
        );
    }
}

CrossrefFigure.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
    currentAttribute: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
};

export default withNamespaces()(CrossrefFigure);
