export function getTrimedFormula(formula) {
  if (formula.indexOf('\\(') === -1 && formula.indexOf('\\)') === -1) {
    return formula;
  }

  const begin = formula.indexOf('\\(') + 2,
    end = formula.lastIndexOf('\\)');

  return formula.substring(begin, end).trim();
}

class FormulaCache {
  constructor() {
    this.cache = {};
  }

  set(formula, base64) {
    this.cache[getTrimedFormula(formula)] = base64;
  }

  get(formula) {
    return this.cache[getTrimedFormula(formula)];
  }
}

export const FORMULA_CACHE = new FormulaCache();

export const EDITOR_STYLE = {
  fontFamily: '"Fira code", "Fira Mono", monospace',
  fontSize: 12,
  border: '1px solid #e5e5e5',
};
