import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../common/containers/Modal";
import Events from "../Events";
import Button from "../../../common/components/Button";

import './editor.comments.css';
import Ajax from "../../../common/ajax";
import Config from "../../../config";
import LocaleUtils from "../../../common/LocaleUtils";
import {AlertError} from "../../../common/components/Alert";
import {AppContext} from "../../../context/global";
import {withNamespaces} from "react-i18next";

export const REMOVE_MESSAGE = 'Deseja realmente remover esse comentário? Essa operação não poderá ser desfeita.';

class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalTitle: 'Inserir comentário',
            commentText: '',
            comment: null,
            widget: null,
            processing: false,
            errorMessage: null,
        };
        this.openModalToInsert = this.openModalToInsert.bind(this);
        this.openModalToEdit = this.openModalToEdit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.cleanMarkup = this.cleanMarkup.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.insertComment = this.insertComment.bind(this);
        this.editComment = this.editComment.bind(this);
        this.removeComment = this.removeComment.bind(this);
        this.removeCommentFromModal = this.removeCommentFromModal.bind(this);
        this.moveCursorToEndOfSelection = this.moveCursorToEndOfSelection.bind(this);
        this.autoRemoveOrphanComment = this.autoRemoveOrphanComment.bind(this);
        this.textareaRef = React.createRef();
        this.deleteMsg = REMOVE_MESSAGE;
    }

    componentDidMount() {
        this.props.editor.on(Events.INSERT_COMMENT, this.openModalToInsert);
        this.props.editor.on(Events.EDIT_COMMENT, this.openModalToEdit);
        this.props.editor.on(Events.REMOVE_COMMENT, this.removeComment);
        this.props.editor.on(Events.CREATED_COMMENT_WIDGET, this.autoRemoveOrphanComment);
        this.props.editor.on('getData', (evt) => {
            /**
             * Clean comments markup before editor.getData method returns.
             */
            const $element = $('<div>' + evt.data.dataValue + '</div>');
            this.cleanMarkup($element);
            evt.data.dataValue = $element[0].innerHTML;
        });
        this.initTooltipTrigger();
    }

    initTooltipTrigger() {
        let xhr;
        let oldTitle;

        this.props.editor.on(Events.INSERT_COMMENT_TOOLTIP, (evt) => {
            const editableElement = evt.editor.editable();
            const widget = evt.data;
            const commentId = widget.element.getAttribute('id');
            oldTitle = editableElement.getAttribute('title');
            const url = `${Config.apiHost}comments/${commentId}/`;
            xhr = Ajax.get(url).done(comment => {
                const email = comment.user.email;
                const date = LocaleUtils.fromNow(comment.last_modification_date);
                const title = `${comment.comment} (${email}, ${date})`;
                editableElement.setAttribute('title', title);
            });
        });

        this.props.editor.on(Events.REMOVE_COMMENT_TOOLTIP, (evt) => {
            if (xhr) {
                xhr.abort();
                xhr = null;
            }
            const editableElement = evt.editor.editable();
            editableElement.setAttribute('title', oldTitle);
        });
    }

    closeModal() {
        this.setState({
            ...this.state,
            showModal: false,
            comment: null,
            commentText: '',
            errorMessage: null,
            widget: null,
        });
    }

    openModalToInsert() {
        const {i18n} = this.props;
        this.setState({
            ...this.state,
            showModal: true,
            modalTitle: i18n.t('Inserir comentário'),
        });
    }

    openModalToEdit(editorEvt) {
        const {i18n} = this.props;
        this.setState({
            ...this.state,
            showModal: true,
            processing: true,
            errorMessage: null,
            modalTitle: i18n.t('Editar comentário'),
            widget: null,
        });
        const widget = editorEvt.data;
        const commentId = widget.element.getAttribute('id');
        const url = `${Config.apiHost}comments/${commentId}/`;
        Ajax.get(url).done(comment => {
            this.setState({
                ...this.state,
                widget,
                comment,
                commentText: comment.comment,
                processing: false,
            });
        }).fail(() => {
            this.setState({
                ...this.state,
                errorMessage: i18n.t('Não foi possível realizar a operação.'),
                processing: false,
            });
        });
    }

    onCommentChange(e) {
        this.setState({
            ...this.state,
            commentText: e.target.value,
        });
    }

    insertComment() {
        const {i18n} = this.props;
        this.setState({
            ...this.state,
            processing: true,
            errorMessage: null,
        });

        const url = `${Config.apiHost}comments/`;
        const data = {
            comment: this.state.commentText,
            document: this.props.document.id,
        };

        Ajax.postJSON(url, data).done(comment => {
            this.setState({
                ...this.state,
                processing: false,
                comment: null,
                commentText: '',
                showModal: false,
            });
            const classes = 'ff-comment ff-comment-mark';
            const commentMark = `<mark class="${classes}" id="${comment.id}" title="${comment.comment}" />`;
            this.moveCursorToEndOfSelection();
            this.props.editor.insertHtml(commentMark);
            this.props.editor.fire(Events.UPDATE_COMMENT_PANEL);
        }).fail(() => {
            this.setState({
                ...this.state,
                processing: false,
                errorMessage: i18n.t('Não foi possível realizar a operação.'),
            });
        });
    }

    editComment() {
        const {i18n} = this.props;
        this.setState({
            ...this.state,
            processing: true,
            errorMessage: null,
        });

        const url = `${Config.apiHost}comments/${this.state.comment.id}/`;
        const data = {
            comment: this.state.commentText,
        };

        Ajax.patch(url, data).done(comment => {
            this.setState({
                ...this.state,
                processing: false,
                comment: null,
                commentText: '',
                showModal: false,
            });
            this.props.editor.fire(Events.UPDATE_COMMENT_PANEL);
        }).fail(() => {
            this.setState({
                ...this.state,
                processing: false,
                errorMessage: i18n.t('Não foi possível realizar a operação.'),
            });
        });
    }

    removeComment(evt) {
        const {i18n} = this.props;
        const msg = i18n.t(this.deleteMsg);
        if (!confirm(msg)) {
            return;
        }
        if (evt.editor.widgets.focused) {
            const widget = evt.editor.widgets.focused;
            const commentId = widget.element.getAttribute('id');
            const url = `${Config.apiHost}comments/${commentId}/`;
            Ajax.delete_(url).done(() => {
                evt.editor.widgets.del(widget);
                this.props.editor.fire(Events.UPDATE_COMMENT_PANEL);
            }).fail(xhr => {
                if (xhr.responseJSON.status_code === 404) {
                    evt.editor.widgets.del(widget);
                }
            });
        }
    }

    autoRemoveOrphanComment(editorEvt) {
        /**
         * Removes comments widgets which have been removed from database.
         */
        const widget = editorEvt.data;
        const commentId = widget.element.getAttribute('id');
        const url = `${Config.apiHost}comments/${commentId}/`;
        Ajax.get(url).fail(xhr => {
            if (xhr.status === 404) { // Not found
                this.props.editor.fire('lockSnapshot');
                this.props.editor.widgets.del(widget);
                this.props.editor.fire('unlockSnapshot');
            }
        });
    }

    removeCommentFromModal() {
        const {i18n} = this.props;
        if (!confirm(i18n.t(this.deleteMsg))) {
            return;
        }

        this.setState({
            ...this.state,
            processing: true,
            errorMessage: null,
        });

        const url = `${Config.apiHost}comments/${this.state.comment.id}/`;
        Ajax.delete_(url).done(() => {
            this.props.editor.widgets.del(this.state.widget);
            this.setState({
                ...this.state,
                processing: false,
                comment: null,
                commentText: '',
                showModal: false,
                widget: null,
            });
            this.props.editor.fire(Events.UPDATE_COMMENT_PANEL);
        }).fail(xhr => {
            this.setState({
                ...this.state,
                processing: false,
                errorMessage: i18n.t('Não foi possível realizar a operação.'),
            });
        });
    }

    moveCursorToEndOfSelection() {
        const ranges = this.props.editor.getSelection().getRanges();
        if (ranges && ranges.length > 0) {
            const range = ranges[0];
            const endNode = range.endContainer;
            const endOffset = range.endOffset;
            range.setStart(endNode, endOffset);
            range.setEnd(endNode, endOffset);
            this.props.editor.getSelection().selectRanges([range]);
        }
    }

    cleanMarkup($element) {
        $element.find('.ff-comment')
            .removeClass('ff-comment-focused ff-comment-default');
    }

    renderFooter() {
        const {i18n} = this.props;
        let canEdit = true;

        if (this.state.comment &&
            this.state.comment.user.email !== this.context.user.email) {
            canEdit = false;
        }

        return (
            <React.Fragment>
                {this.state.comment &&
                    <span className="text-muted pull-left">
                        <i className="icon mdi mdi-account" />
                        &nbsp;
                        {this.state.comment.user.email}
                        <br/>
                        {LocaleUtils.fromNow(this.state.comment.last_modification_date)}
                    </span>}
                {this.state.comment &&
                    <Button type="default"
                            size="lg"
                            disabled={!canEdit}
                            onClick={this.removeCommentFromModal}>
                        <i className="icon mdi mdi-delete" />
                    </Button>}
                <Button type="primary"
                        size="lg"
                        disabled={!canEdit}
                        onClick={this.state.comment ? this.editComment : this.insertComment}>
                    {i18n.t('Salvar')}
                </Button>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Modal title={this.state.modalTitle}
                   show={this.state.showModal}
                   onCancel={this.closeModal}
                   onShown={() => this.textareaRef.current.focus()}
                   isProcessing={this.state.processing}
                   footer={this.renderFooter()}>
                <AlertError>{this.state.errorMessage}</AlertError>
                <textarea rows="5"
                          ref={this.textareaRef}
                          className="form-control"
                          value={this.state.commentText}
                          onChange={this.onCommentChange} />
            </Modal>
        );
    }
}

Comment.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
};

Comment.contextType = AppContext;

export default withNamespaces()(Comment);
