import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/components/Button';
import Waiting from '../../../common/containers/Waiting';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import { showDjangoValidationMessages } from '../../../common/validation';
import Row from '../../../common/containers/Row';
import Col from '../../../common/containers/Col';
import Input from '../../../common/components/form/Input';
import { withNamespaces } from 'react-i18next';

class AuthorAffiliationSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      showForm: false,
      form: {},
      affiliations: [],
      selectedAffiliation: '',
      deleteError: false,
    };
    this.save = this.save.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.fetchAffiliations = this.fetchAffiliations.bind(this);
    this.openEditForm = this.openEditForm.bind(this);
    this.openAddForm = this.openAddForm.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.selectAffiliation = this.selectAffiliation.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    this.fetchAffiliations();

    if (this.props.selectedAffiliation) {
      this.setState((state) => ({
        ...state,
        selectedAffiliation: this.props.selectedAffiliation,
      }));
    }
  }

  fetchAffiliations() {
    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}affiliations/?document=${this.props.document.id}`;
    Ajax.get(url)
      .done((data) => {
        this.setState((state) => ({ ...state, affiliations: data }));
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  selectAffiliation(e) {
    e.persist();
    const id = e.target.value;
    this.setState((state) => ({
      ...state,
      selectedAffiliation: id,
    }));

    const affiliation = this.state.affiliations.find(
      (a) => a.id == e.target.value
    );
    this.props.onSelect(affiliation);
  }

  openAddForm() {
    this.setState((state) => ({ ...state, showForm: true, form: {} }));
  }

  openEditForm() {
    if (this.state.selectedAffiliation) {
      const affiliation = this.state.affiliations.find(
        (a) => a.id == this.state.selectedAffiliation
      );
      this.setState((state) => ({
        ...state,
        showForm: true,
        form: { ...affiliation },
      }));
    }
  }

  closeForm() {
    this.setState((state) => ({
      ...state,
      showForm: false,
      processing: false,
    }));
  }

  handleInputChange(event) {
    event.persist();
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => {
      return {
        ...state,
        form: {
          ...state.form,
          [name]: value,
        },
      };
    });
  }

  save() {
    this.setState((state) => ({ ...state, processing: true }));
    let url;
    let jqXHR;
    const data = { ...this.state.form };

    if (data.id) {
      url = `${Config.apiHost}affiliations/${data.id}/`;
      jqXHR = Ajax.patch(url, data);
    } else {
      url = `${Config.apiHost}affiliations/`;
      data.document = this.props.document.id;
      jqXHR = Ajax.post(url, data);
    }

    jqXHR
      .done((data) => {
        this.props.onSelect(data);
        this.setState((state) => ({
          ...state,
          form: {},
          selectedAffiliation: data.id,
        }));
        this.fetchAffiliations();
        this.closeForm();
      })
      .fail((_jqXHR) => {
        showDjangoValidationMessages(_jqXHR.responseJSON);
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  delete() {
    if (!this.state.selectedAffiliation) {
      return;
    }

    const { i18n } = this.props;

    if (confirm(i18n.t('Deseja realmente excluir essa afiliação?'))) {
      this.setState((state) => ({
        ...state,
        processing: true,
        deleteError: false,
      }));
      const url = `${Config.apiHost}affiliations/${this.state.selectedAffiliation}/`;
      Ajax.delete_(url)
        .done(() => {
          this.fetchAffiliations();
          this.props.onSelect(null);
        })
        .fail((_jqXHR) => {
          showDjangoValidationMessages(_jqXHR.responseJSON);
          this.setState((state) => ({
            ...state,
            processing: false,
            deleteError: true,
          }));
        });
    }
  }

  render() {
    const { i18n } = this.props;
    return (
      <Waiting isProcessing={this.state.processing}>
        {!this.state.showForm && (
          <div className="form-group">
            <label className="control-label" htmlFor="id_affiliation">
              {i18n.t('Afiliação')}
            </label>
            <div className="input-group">
              <select
                name="affiliation"
                className=" form-control"
                id="id_affiliation"
                onChange={this.selectAffiliation}
                value={this.state.selectedAffiliation}
              >
                <option value={''}>---------</option>
                {this.state.affiliations.map((a) => {
                  return (
                    <option value={a.id} key={a.id}>
                      {a.department} &mdash; {a.institution}
                    </option>
                  );
                })}
              </select>
              <div className="input-group-btn">
                {this.state.selectedAffiliation && (
                  <Button type="default" size="md" onClick={this.openEditForm}>
                    <i className="icon mdi mdi-edit" />
                  </Button>
                )}

                {this.state.selectedAffiliation && (
                  <Button type="default" size="md" onClick={this.delete}>
                    <i className="icon mdi mdi-delete" />
                  </Button>
                )}

                <Button type="default" size="md" onClick={this.openAddForm}>
                  <i className="icon mdi mdi-plus-circle-o" />
                </Button>
              </div>
            </div>
            {this.state.deleteError && (
              <p className="text-danger">
                {i18n.t('Error ao remover afiliação.')}
              </p>
            )}
          </div>
        )}

        {this.state.showForm && (
          <div className="well well-sm">
            <Row>
              <Col md={6}>
                <Input
                  name="institution"
                  id="id_institution"
                  label={i18n.t('Nome da instituição')}
                  value={this.state.form.institution}
                  maxLength="500"
                  autoComplete="fuck-off"
                  required={true}
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col md={6}>
                <Input
                  name="department"
                  id="id_department"
                  value={this.state.form.department}
                  label={i18n.t('Nome do departamento')}
                  maxLength="500"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Input
              name="address"
              id="id_address"
              label={i18n.t('Endereço')}
              value={this.state.form.address}
              maxLength="500"
              onChange={this.handleInputChange}
            />
            <Button type="primary" size="md" onClick={this.save}>
              Salvar
            </Button>
            &nbsp;
            <Button type="default" size="md" onClick={this.closeForm}>
              Cancelar
            </Button>
          </div>
        )}
      </Waiting>
    );
  }
}

AuthorAffiliationSelect.propTypes = {
  document: PropTypes.object.isRequired,
  /**
   * The author affiliation ID.
   */
  selectedAffiliation: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

export default withNamespaces()(AuthorAffiliationSelect);
