const Events = {
  SAVE: 'fastformatSave',
  COMPILE: 'fastformatCompile',
  AFTER_COMPILE: 'fastformatAfterCompile',
  BEFORE_COMPILATION_START: 'fastformatBeforeCompilationStarted',
  AFTER_COMPILATION_COMPLETE: 'fastformatAfterCompilationComplete',
  SAVE_AND_COMPILE: 'fastformatSaveAndCompile',
  BEFORE_SAVE: 'fastformatBeforeSave',
  AFTER_SAVE: 'fastformatAfterSave',
  AFTER_IDENTIFICATION_SAVE: 'fastformatAfterIdentificationSave',
  PREVIEW: 'fastformatPreview',
  PREVIEW_SCROLL_TO_FIRST: 'fastformatPreview ScrollToFirst',
  AFTER_ATTRIBUTE_CHANGE: 'fastformatAfterAttributeChange',
  CHANGE_ATTRIBUTE: 'fastformatChangeAttribute',
  INSERT_FOOTNOTE: 'fastformatInsertFootnote',
  EDIT_FOOTNOTE: 'fastformatEditFootnote',
  AFTER_FOOTNOTE_MODAL_CLOSE: 'fastformatAfterFootnoteModalClose',
  COMPILATION_PROGRESS: 'fastformatCompilationProgress',

  AFTER_REFERENCE_UPDATE: 'fastformatAfterReferenceUpdate',
  CITATION_WIDGET_READY: 'fastformatCitationWidgetReady',

  INSERT_COMMENT: 'fastformatInsertComment',
  EDIT_COMMENT: 'fastformatEditComment',
  REMOVE_COMMENT: 'fastformatRemoveComment',
  CREATED_COMMENT_WIDGET: 'fastformatCommentWidgetCreated',
  INSERT_COMMENT_TOOLTIP: 'fastformatCommentTooltip',
  REMOVE_COMMENT_TOOLTIP: 'fastformatCommentRemoveTooltip',
  UPDATE_COMMENT_PANEL: 'fastformatCommentUpdatePanel',

  CHANGE_PROOFREAD_IDIOM: 'fastformatChangeProofreadIdiom',
  CHANGE_ELEMENT_PROOFREAD_IDIOM: 'fastformatChangeElementProofreadIdiom',
  PROOFREAD_STARTED: 'fastformatProofreadStarted',

  INCLUDE_PDF_OPEN: 'fastformatOpenIncludePDF',

  FORMULA_OPEN: 'fastformatFormulaOpen',
  FORMULA_PREVIEW: 'fastformatFormulaPreview',

  CODESNIPPET_OPEN: 'fastformatCodeSnippetOpen',
  CODESNIPPET_PREVIEW: 'fastformatCodeSnippetPreview',

  HIDE_TOOLBAR_BUTTONS: 'fastformatHideButtons',
};

export default Events;
