import React from 'react';
import PropTypes from 'prop-types';
import configureToolbars from './toolbars';
import configureCKEditor from './config';
import Config from '../../config';

export default class CKEditor extends React.Component {
  constructor(props) {
    super(props);
    this.onLoad = this.onLoad.bind(this);
  }

  shouldComponentUpdate() {
    // Prevent losing content when components update.
    return false;
  }

  componentDidMount() {
    this.onLoad();
  }

  componentWillReceiveProps(props) {
    const editor = this.editorInstance;
    if (editor && editor.getData() !== props.content) {
      editor.setData(props.content);
    }
  }

  componentWillUnmount() {
    this.unmounting = true;
    if (this.editorInstance) {
      this.editorInstance.destroy();
      this.editorInstance = null;
    }
  }

  onLoad() {
    if (this.unmounting) return;

    if (!window.CKEDITOR) {
      console.error('CKEditor not found');
      return;
    }

    const debug = false; //!Config.isProduction;

    if (debug) {
      this.props.config.extraPlugins += ',devtools,codemirror';
    }

    configureToolbars(debug);

    configureCKEditor(debug, this.props.languageCode, this.props.type);

    this.editorInstance = CKEDITOR.replace(this.props.id, this.props.config);

    //Register listener for custom events if any
    for (const event in this.props.events) {
      const eventHandler = this.props.events[event];
      this.editorInstance.on(event, eventHandler);
    }
  }

  render() {
    console.log('re rendered!!');
    return (
      <textarea
        id={this.props.id}
        // value={this.props.content || ''}
        onChange={() => {}}
      />
    );
  }
}

CKEditor.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.any,
  type: PropTypes.oneOf(['article', 'book', 'presentation']),
  config: PropTypes.object,
  activeClass: PropTypes.string,
  events: PropTypes.object,
  languageCode: PropTypes.string,
  debug: PropTypes.bool,
};

CKEditor.defaultProps = {
  content: '',
  config: {},
  activeClass: '',
  events: {},
  languageCode: 'pt-BR',
  debug: false,
};
