import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Events from '../Events';
import { getCodeAttributes, getPrismLanguage } from './constants';
import './codeSnippetPreview.css';
import { getIframeDocument } from '../../../common/htmlutils';

/**
 * This component renders the stylized code snippet preview inside the editor.
 */
class CodeSnippetPreview extends Component {
  constructor(props) {
    super(props);
    this.renderPreview = this.renderPreview.bind(this);
  }

  componentDidMount() {
    const { editor } = this.props;
    editor.on('dataReady', this.renderPreview);
    editor.on('afterPaste', this.renderPreview);
    editor.on(Events.CODESNIPPET_PREVIEW, this.renderPreview);
    this.renderPreview();
  }

  renderPreview() {
    const { editor } = this.props;
    const editorElement = editor.editable().$;

    // Preview the pure construction <pre><code [attrs...]>...</code></pre> that comes from the backend.
    editorElement.querySelectorAll('pre > code').forEach((codeElement) => {
      editor.fire('lockSnapshot');
      this.createPreviewIframe(codeElement.parentElement);
      editor.fire('unlockSnapshot');
    });

    /**
     * When performing DOM operation, like paste - undo - redo, the iframe preview contents
     * are removed by CKEditor. Thus, as a workaround, another preview is performed based on the
     * "saved-code" attribute inserted in the pre tag.
     */
    editorElement.querySelectorAll('pre[saved-code]').forEach((preElement) => {
      const iframe = preElement.querySelector('iframe');
      if (iframe) {
        if (!getIframeDocument(iframe).querySelector('pre > code')) {
          // The iframe content was removed during DOM operations by ckeditor. Thus, recrate the preview.
          editor.fire('lockSnapshot');
          this.createPreviewIframe(preElement);
          editor.fire('unlockSnapshot');
        }
      }
    });
  }

  createPreviewIframe(preElement) {
    let iframe = document.createElement('iframe');
    iframe.setAttribute('src', 'javascript:void(0)');
    iframe.setAttribute('style', 'border: 0; width: 100%; max-height: 500px;');
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('allowTransparency', true);
    preElement.style.padding = '0px';

    let codeElement = preElement.querySelector('code');

    if (!codeElement && preElement.hasAttribute('saved-code')) {
      const div = document.createElement('div');
      div.innerHTML = decodeURIComponent(preElement.getAttribute('saved-code'));
      codeElement = div.firstChild;
    }

    const codeAttributes = getCodeAttributes(codeElement);
    const prismLang = getPrismLanguage(codeAttributes.language);

    if (codeAttributes.showFrame) {
      iframe.setAttribute(
        'style',
        iframe.getAttribute('style') + '; border: 1px solid black;'
      );
    }

    preElement.setAttribute(
      'saved-code',
      encodeURIComponent(codeElement.outerHTML)
    );

    codeElement.setAttribute(
      'savedlanguage',
      codeElement.getAttribute('class')
    );

    codeElement.setAttribute('class', 'language-none');
    const codeShadowHtml = codeElement.outerHTML;

    codeElement.setAttribute('class', `language-${prismLang}`);
    const codeHtml = codeElement.outerHTML;

    codeElement.replaceWith(iframe);

    iframe = preElement.querySelector('iframe');
    const iframeDoc = getIframeDocument(iframe);
    iframeDoc.open();

    const showNumbers = codeAttributes.showNumbers !== 'no';
    iframeDoc.write(`
      <!DOCTYPE html>
      <html style="overflow: auto; padding: 10px 0px 0px 30px;">
      <head>
      ${
        showNumbers
          ? '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/plugins/line-numbers/prism-line-numbers.min.css"/>'
          : ''
      }
      <style>
      ${PREVIEW_STYLE}
      </style>
      </head>
      <body style="padding: 0; margin: 0; background:transparent;">
        <figure>
          ${
            codeAttributes.caption
              ? `<figcaption>${codeAttributes.caption}</figcaption>`
              : ''
          }
          <pre class="${showNumbers ? 'line-numbers' : ''}" data-start="${
      codeAttributes.firstNumber
    }">${codeHtml}</pre>
        </figure>
        <pre id="shadow-pre" style="display: none;">${codeShadowHtml}</pre>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/prism.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/plugins/autoloader/prism-autoloader.min.js"></script>
        ${
          showNumbers
            ? '<script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/plugins/line-numbers/prism-line-numbers.min.js"></script>'
            : ''
        }
      </body>
      </html>
    `);
    iframeDoc.close();
    iframe.style.height = iframeDoc.body.clientHeight + 15 + 'px';
  }

  render() {
    return null;
  }
}

CodeSnippetPreview.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(CodeSnippetPreview);

const PREVIEW_STYLE = `
  pre[class*=language-].line-numbers {
    padding-left: 2em;
  }

  figure, pre {
    margin: 0;
  }

  figcaption {
    margin-bottom: 10px;
    font-family: monospace;
    font-style: italic;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata,
  .token.triple-quoted-string.string {
    color: rgb(63, 127, 95);
  }

  .token.punctuation {
    color: inherit;
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: rgb(127, 0, 85);
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted,
  .token.string-interpolation {
    color: rgb(42, 0, 255);
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: inherit;
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: rgb(127, 0, 85);
  }

  .token.function,
  .token.class-name {
    color: inherit;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: rgb(42, 0, 255);
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
`;
