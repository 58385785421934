import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CrossrefBase from "./CrossrefBase";
import {withNamespaces} from "react-i18next";

class CrossrefSection extends Component {
    constructor(props) {
        super(props);
        this.getSectionReferences = this.getSectionReferences.bind(this);
        this.insertCrossref = this.insertCrossref.bind(this);
    }

    getSectionReferences(content) {
        const $content = $(`<div>${content}</div>`);
        const references = [];
        const $items = $('h1[reference], h2[reference], h3[reference], h4[reference], h5[reference], h6[reference], h7[reference]', $content);
        if ($items.length > 0) {
            $items.each(function (i, el) {
                const $section = $(el);
                const itemName = $section.text();
                if (!itemName) {
                    return;
                }
                const itemValue = $section.attr('reference');
                references.push({
                    caption: itemName,
                    reference: itemValue
                });
            });
        }
        return references;
    }

    insertCrossref(reference) {
        const el = [
            `<span contenteditable="false" class="section-reference" title="${reference.caption}">`,
            `<ff-section-reference target-reference="${reference.reference}">{sec:${reference.reference}}</ff-section-reference>`,
            `</span>`
        ].join('');
        this.props.editor.insertHtml(el);
    }

    render() {
        const {i18n} = this.props;
        return (
            <CrossrefBase
                document={this.props.document}
                editor={this.props.editor}
                currentAttribute={this.props.currentAttribute}
                title={i18n.t('Selecione uma seção para referenciar')}
                menuName={i18n.t('Inserir referências de seções')}
                commandName="crossRefSectionCommand"
                icon="header"
                getReferences={this.getSectionReferences}
                insertCrossref={this.insertCrossref} />
        );
    }
}

CrossrefSection.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
    currentAttribute: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
};

export default withNamespaces()(CrossrefSection);
