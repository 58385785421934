import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../../common/ajax';
import Button from '../../../common/components/Button';
import Upload from '../../../common/components/upload/Upload';
import Modal from '../../../common/containers/Modal';
import Waiting from '../../../common/containers/Waiting';

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      images: null,
    };
    this.onCloseModal = this.onCloseModal.bind(this);
    this.fetchImageNames = this.fetchImageNames.bind(this);
    this.onAllFinished = this.onAllFinished.bind(this);
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showModal && !prevState.showModal) {
      this.fetchImageNames();
    }
  }

  fetchImageNames() {
    this.setState((state) => ({ ...state, processing: true }));
    Ajax.get(this.props.fetchURL)
      .done((images) => {
        if (this._mounted) {
          this.setState((state) => ({ ...state, images }));
        }
      })
      .always(() => {
        if (this._mounted) {
          this.setState((state) => ({ ...state, processing: false }));
        }
      });
  }

  onCloseModal() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onAllFinished() {
    this.setState((state) => ({ ...state, processing: false }));
    if (this.props.onAllFinished) {
      this.props.onAllFinished();
    }
  }

  renderUpload() {
    return (
      <Upload
        url={this.props.uploadURL}
        onFileDialogCancel={this.onCloseModal}
        accept={this.props.acceptedExtensions}
        onStart={() =>
          this.setState((state) => ({ ...state, processing: true }))
        }
        onAllFinished={this.onAllFinished}
        onError={this.props.onError}
        maxSize={10000000}
      />
    );
  }

  render() {
    const { i18n } = this.props;
    return (
      <Modal
        title={i18n.t('Enviar arquivos')}
        show={true}
        onCancel={this.onCloseModal}
        style={{ zIndex: '11000' }}
        footer={
          <Button onClick={this.onCloseModal} type="primary">
            {i18n.t('Fechar')}
          </Button>
        }
      >
        <Waiting isProcessing={this.state.processing}>
          {this.renderUpload()}
        </Waiting>
      </Modal>
    );
  }
}

ImageUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
  acceptedExtensions: PropTypes.array,
  fetchURL: PropTypes.string.isRequired,
  uploadURL: PropTypes.string.isRequired,
};

ImageUpload.defaultProps = {
  acceptedExtensions: ['.jpg', '.jpeg', '.png', '.eps'],
};

export default withNamespaces()(ImageUpload);
