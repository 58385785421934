import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/components/Button';
import Waiting from '../../../common/containers/Waiting';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import { showDjangoValidationMessages } from '../../../common/validation';
import AuthorAffiliationSelect from './AuthorAffiliationSelect';
import Row from '../../../common/containers/Row';
import Col from '../../../common/containers/Col';
import Input from '../../../common/components/form/Input';
import Checkbox from '../../../common/components/form/Checkbox';
import { withNamespaces } from 'react-i18next';

class AuthorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      form: {
        name: '',
        email: '',
        affiliation_id: null,
        correspondent: false,
        adviser: false,
      },
    };
    this.save = this.save.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectAffiliation = this.selectAffiliation.bind(this);
  }

  componentDidMount() {
    if (this.props.author) {
      this.setState((state) => {
        return {
          ...state,
          form: { ...this.props.author },
        };
      });
    }
  }

  handleInputChange(event) {
    event.persist();
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => {
      return {
        ...state,
        form: {
          ...state.form,
          [name]: value,
        },
      };
    });
  }

  selectAffiliation(affiliation) {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        affiliation,
        affiliation_id: (affiliation && affiliation.id) || undefined,
      },
    }));
  }

  save() {
    this.setState((state) => ({ ...state, processing: true }));
    let url;
    let jqXHR;
    const data = { ...this.state.form };

    if (data.affiliation) {
      data.affiliation_id = data.affiliation.id;
    }

    if (data.id) {
      url = `${Config.apiHost}authors/${data.id}/`;
      jqXHR = Ajax.patch(url, data);
    } else {
      url = `${Config.apiHost}authors/`;
      data.document = this.props.document.id;
      jqXHR = Ajax.post(url, data);
    }

    let success = false;
    jqXHR
      .done(() => {
        success = true;
        this.setState((state) => ({ ...state, form: {} }));
      })
      .fail((_jqXHR) => {
        showDjangoValidationMessages(_jqXHR.responseJSON);
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
        if (success) {
          this.props.onSave(data);
        }
      });
  }

  render() {
    const { i18n } = this.props;
    const affiliationId =
      this.props.author && this.props.author.affiliation
        ? this.props.author.affiliation.id
        : null;

    return (
      <Waiting isProcessing={this.state.processing}>
        <Row>
          <Col md={6}>
            <Input
              name="name"
              id="id_name"
              label={i18n.t('Nome')}
              required={true}
              value={this.state.form.name || ''}
              maxLength="500"
              autoComplete="fuck-off"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col md={6}>
            <Input
              type="email"
              name="email"
              id="id_email"
              label="E-mail"
              required={true}
              value={this.state.form.email || ''}
              maxLength="254"
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <AuthorAffiliationSelect
          document={this.props.document}
          selectedAffiliation={affiliationId}
          onSelect={this.selectAffiliation}
        />
        <Row>
          <Col md={6}>
            <Checkbox
              name="correspondent"
              id="id_correspondent"
              label={i18n.t('Autor para correspondência')}
              help={i18n.t(
                'Marque caso esse seja o autor para correspondência'
              )}
              checked={this.state.form.correspondent}
              onChange={this.handleInputChange}
            />
          </Col>
          <Col md={6}>
            <Checkbox
              name="adviser"
              id="id_adviser"
              label={i18n.t('Orientador')}
              help={i18n.t(
                'Marque caso esse autor seja o orientador do trabalho'
              )}
              checked={this.state.form.adviser}
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <Button type="primary" size="lg" onClick={this.save}>
          {i18n.t('Salvar')}
        </Button>
        &nbsp;
        <Button type="default" size="lg" onClick={() => this.props.onCancel()}>
          {i18n.t('Cancelar')}
        </Button>
      </Waiting>
    );
  }
}

AuthorForm.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  author: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withNamespaces()(AuthorForm);
