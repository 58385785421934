import React from 'react';
import PropTypes from 'prop-types';
import ImportBibtex from './ImportBibtex';
import { withNamespaces } from 'react-i18next';
import Config from '../../config';
import Ajax from '../../common/ajax';
import ModalAlert from '../../common/components/ModalAlert';
import queryString from 'query-string';
import URLS from '../../urls';
import ImportFromDatabase from './ImportFromDatabase';

class ImportButton extends React.Component {
  constructor(props) {
    super(props);
    this.getMendeleyAuthUrl = this.getMendeleyAuthUrl.bind(this);
    this.state = {
      mendeleyError: false,
      mendeleySuccessMsg: false,
    };
  }

  componentDidMount() {
    const params = queryString.parse(location.search);
    if (params.code && params.state) {
      this.importMendeley();
    }
  }

  importMendeley() {
    const params = queryString.parse(location.search);
    const url = `${Config.apiHost}references/mendeley/?code=${params.code}&state=${params.state}`;
    Ajax.get(url)
      .done((data) => {
        this.setState((s) => ({ ...s, mendeleySuccessMsg: data.detail }));
      })
      .fail(() => {
        this.setState((s) => ({ ...s, mendeleyError: true }));
      });
  }

  getMendeleyAuthUrl() {
    this.setState((s) => ({ ...s, mendeleyError: false }));
    const url = `${Config.apiHost}references/mendeley/`;
    Ajax.get(url)
      .done((url) => {
        document.location = url;
      })
      .fail(() => {
        this.setState((s) => ({ ...s, mendeleyError: true }));
      });
  }

  render() {
    const { i18n } = this.props;
    return (
      <>
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-default btn-md dropdown-toggle hidden-xs"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i title={i18n.t('Importar')} className="mdi mdi-cloud-download" />{' '}
            <span className="hidden-md hidden-sm">{i18n.t('Importar')}</span>{' '}
            <span className="caret" />
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu" role="menu">
            <li>
              <ImportBibtex onImport={this.props.onImport} />
            </li>
            <li>
              <ImportFromDatabase onImport={this.props.onImport} />
            </li>
            <li>
              <a role="button" onClick={this.getMendeleyAuthUrl}>
                {i18n.t('Importar do Mendeley')}
              </a>
            </li>
          </ul>
        </div>

        <ModalAlert
          show={this.state.mendeleyError}
          type="danger"
          onClose={() => this.setState((s) => ({ ...s, mendeleyError: false }))}
        >
          <h3>
            {i18n.t('Não foi possível importar as referências do Mendeley.')}
          </h3>
        </ModalAlert>

        <ModalAlert
          show={this.state.mendeleySuccessMsg}
          type="success"
          onClose={() => (document.location = URLS.references)}
        >
          <h3>{this.state.mendeleySuccessMsg}</h3>
        </ModalAlert>
      </>
    );
  }
}

ImportButton.propTypes = {
  onImport: PropTypes.func,
};

export default withNamespaces()(ImportButton);
