import Prism from 'prismjs';
import 'prismjs/components/prism-latex';
import 'prismjs/themes/prism.css';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Editor from 'react-simple-code-editor';
import Ajax from '../../../common/ajax';
import { AlertError } from '../../../common/components/Alert';
import Checkbox from '../../../common/components/form/Checkbox';
import FormGroup from '../../../common/components/form/FormGroup';
import Modal from '../../../common/containers/Modal';
import Waiting from '../../../common/containers/Waiting';
import Config from '../../../config';
import Events from '../Events';
import './formula.css';
import { EDITOR_STYLE, FORMULA_CACHE, getTrimedFormula } from './formulaCommon';
import FormulaGuide from './FormulaGuide';
import FormulaPreview from './FormulaPreview';
import matchBraces from './matchBraces';
import './matchBraces.css';

const DEFAULT_FORMULA = 'x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}';
const WIDGET_CLASS = 'math-tex';

const TEXTAREA_ID = 'code-editor-id';

class Formula extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showGuide: false,
      editorInstance: null,
      spanElement: null,
      base64Preview: null,
      processingPreview: false,
      error: false,
      form: {
        formula: DEFAULT_FORMULA,
        numbering: false,
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.insertHTML = this.insertHTML.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onFormulaChanged = this.onFormulaChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchPreview = this.fetchPreview.bind(this);
    this.debouncedFetchPreview = debounce(this.fetchPreview, 500);
    this.submitRef = React.createRef();
    this.formRef = React.createRef();
    this.previewUrl = `${Config.apiHost}documents/${props.document.id}/formula_preview/`;
    this.previewFontSize = 'Huge';
  }

  componentDidMount() {
    const { editor } = this.props;
    editor.on(Events.FORMULA_OPEN, this.openModal);
    window.matchBraces = matchBraces;
  }

  openModal(editorEvent) {
    const spanElement = editorEvent.data;

    let formula = DEFAULT_FORMULA,
      numbering = false;

    if (spanElement) {
      formula = decodeURIComponent(spanElement.getAttribute('data-formula'));
      numbering = spanElement.getAttribute('numbering') === 'true';
    }

    this.setState((state) => ({
      ...state,
      showModal: true,
      spanElement,
      form: {
        ...state.form,
        formula: getTrimedFormula(formula),
        numbering,
      },
    }));
    if (this.state.form.formula) {
      this.fetchPreview(true);
    }
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      spanElement: null,
      base64Preview: null,
      form: {
        formula: DEFAULT_FORMULA,
        numbering: false,
      },
    }));
  }

  insertHTML() {
    const { editor } = this.props;
    const { formula, numbering } = this.state.form;
    const trimedFormula = '\\(' + getTrimedFormula(formula) + '\\)';

    if (this.state.spanElement) {
      // Editing exisintg formula
      editor.fire('saveSnapshot');
      if (numbering) {
        this.state.spanElement.setAttribute('numbering', numbering);
      } else {
        this.state.spanElement.removeAttribute('numbering');
      }
      this.state.spanElement.innerHTML = trimedFormula;
    } else {
      // Inserting new formula
      editor.fire('saveSnapshot');
      let span = null;
      if (numbering) {
        span = `<span class="${WIDGET_CLASS}" numbering="${numbering}">${trimedFormula}</span>`;
      } else {
        span = `<span class="${WIDGET_CLASS}">${trimedFormula}</span>`;
      }
      editor.insertHtml(span);
    }
    editor.fire(Events.FORMULA_PREVIEW);
    this.closeModal();
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  }

  onFormulaChanged(formula) {
    const { formula: oldFormula } = this.state.form;
    this.setState(
      (state) => ({ ...state, form: { ...state.form, formula } }),
      () => {
        if (this.formRef.current) {
          const pre = this.formRef.current.querySelector('pre');
          if (pre) {
            setTimeout(() => matchBraces(pre), 100);
          }
        }

        if (oldFormula.trim() !== this.state.form.formula.trim() || !this.state.base64Preview) {
          this.debouncedFetchPreview();
        }
      },
    );
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.insertHTML();
  }

  fetchPreview(useLocalCache = false) {
    this.focusTextarea();

    if (this.state.processingPreview) {
      return;
    }

    this.setState((s) => ({ ...s, processingPreview: true, error: false }));

    if (useLocalCache && this.previewFromModalCache()) {
      return;
    }

    const { formula } = this.state.form;
    const encoded = encodeURIComponent(formula);
    const useCache = formula === DEFAULT_FORMULA;
    const url = `${this.previewUrl}?formula=${encoded}&cache=${useCache}&size=${this.previewFontSize}`;
    const { i18n } = this.props;

    Ajax.get(url)
      .done((base64Preview) => {
        this.setState((s) => ({
          ...s,
          base64Preview,
        }));
        FORMULA_CACHE.set(this.previewFontSize + formula, base64Preview);
      })
      .fail((jqXHR) => {
        const error =
          jqXHR.responseJSON && jqXHR.responseJSON.detail
            ? jqXHR.responseJSON.detail
            : i18n.t('Não foi possível gerar a visualização da fórmula. Verifique se a mesma está correta.');
        this.setState((s) => ({ ...s, base64Preview: null, error }));
      })
      .always(() => {
        this.setState((s) => ({ ...s, processingPreview: false }));
      });
  }

  previewFromModalCache() {
    const { form } = this.state;
    const base64Preview = FORMULA_CACHE.get(this.previewFontSize + form.formula);
    if (base64Preview) {
      // This setTimeout just simulates a processing state.
      setTimeout(() => {
        this.setState((state) => ({
          ...state,
          base64Preview,
          processingPreview: false,
        }));
      }, 300);
      return true;
    }
    return false;
  }

  focusTextarea() {
    const textArea = document.getElementById(TEXTAREA_ID);
    if (textArea) {
      textArea.focus();
    }
  }

  render() {
    const { i18n, editor, document, showNumbering } = this.props;

    return (
      <>
        <FormulaPreview editor={editor} document={document} />
        <Modal
          title={i18n.t('Fórmula')}
          show={this.state.showModal}
          onCancel={this.closeModal}
          footer={
            <>
              <button className="btn btn-default btn-lg" onClick={this.closeModal}>
                {i18n.t('Cancelar')}
              </button>
              <button
                className="btn btn-primary btn-lg"
                onClick={() => this.submitRef.current.click()}
                disabled={this.state.processingPreview || this.state.error}
              >
                {i18n.t('Confirmar')}
              </button>
            </>
          }
          width="large"
        >
          <form onSubmit={this.onSubmit} ref={this.formRef}>
            <input type="submit" hidden={true} ref={this.submitRef} />
            <FormGroup label={i18n.t('Insira fórmulas utilizando a marcação LaTeX') + ':'} id={TEXTAREA_ID}>
              <Editor
                textareaId={TEXTAREA_ID}
                value={this.state.form.formula}
                required={true}
                padding={10}
                onValueChange={this.onFormulaChanged}
                onKeyUp={(evt) => {
                  evt.persist();
                  evt.ctrlKey && evt.key === 'Enter' && this.fetchPreview();
                }}
                highlight={(code) => Prism.highlight(code, Prism.languages.latex)}
                style={EDITOR_STYLE}
              />
            </FormGroup>

            <div style={{ marginTop: '-10px' }}>
              <a onClick={() => this.fetchPreview()} role="button">
                <i className="mdi mdi-refresh" /> {i18n.t('Visualizar') + ' (Ctrl + Enter)'}
              </a>
              {' | '}
              <FormulaGuide />
            </div>

            <br />
            <br />

            {showNumbering && (
              <Checkbox
                id="numbering"
                name="numbering"
                checked={this.state.form.numbering}
                label={i18n.t('Incluir numeração da fórmula')}
                onChange={this.onInputChange}
              />
            )}

            <Waiting isProcessing={this.state.processingPreview}>
              <center className="formula-modal-preview">
                {this.state.base64Preview && <img src={`data:image/png;base64,${this.state.base64Preview}`} />}
              </center>

              {this.state.error && <AlertError>{this.state.error}</AlertError>}
            </Waiting>
          </form>
        </Modal>
      </>
    );
  }
}

Formula.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  showNumbering: PropTypes.bool,
};

Formula.defaultProps = {
  showNumbering: true,
};

export default withNamespaces()(Formula);
