import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../common/containers/Modal";
import Button from "../../../common/components/Button";
import Config from "../../../config";
import Ajax from "../../../common/ajax";
import AuthorForm from "./AuthorForm";
import Events from "../Events";
import {withNamespaces} from "react-i18next";

class Author extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            showModal: false,
            showForm: false,
            authors: [],
            form: {},
            selectedAuthor: null,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openForm = this.openForm.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.fetchAuthors = this.fetchAuthors.bind(this);
        this.removeAuthor = this.removeAuthor.bind(this);
        this.up = this.up.bind(this);
        this.down = this.down.bind(this);
    }

    openModal() {
        this.fetchAuthors();
        this.setState(state => ({
            ...state,
            showModal: true,
            showForm: false,
            selectedAuthor: null,
        }));
    }

    closeModal() {
        this.setState(state => ({...state, showModal: false}));
    }

    openForm(author) {
        this.setState(state => ({
            ...state,
            showForm: true,
            selectedAuthor: author
        }));
    }

    closeForm(author) {
        this.fetchAuthors();
        this.setState(state => ({
            ...state,
            showForm: false,
            selectedAuthor: null
        }));
        if (author) {
            this.props.editor.fire(Events.COMPILE);
        }
    }

    fetchAuthors() {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}authors/?document=${this.props.document.id}`;
        Ajax.get(url).done(data => {
            this.setState(state => ({...state, authors: data}));
        }).always(() => {
            this.setState(state => ({...state, processing: false}));
        });
    }

    removeAuthor(author) {
        const {i18n} = this.props;
        const confirmed = confirm(`${i18n.t('Deseja realmente excluir o autor')} ${author.name}?`);
        if (confirmed) {
            const url = `${Config.apiHost}authors/${author.id}/`;
            Ajax.delete_(url).done(this.fetchAuthors);
            this.props.editor.fire(Events.COMPILE);
        }
    }

    up(author) {
        this._change_author_order(author, 'up');
    }

    down(author) {
        this._change_author_order(author, 'down');
    }

    _change_author_order(author, method='up') {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}authors/${author.id}/${method}/`;
        Ajax.patch(url).done(() => {
            this.fetchAuthors();
            this.props.editor.fire(Events.COMPILE);
        }).always(() => {
            this.setState(state => ({...state, processing: false}));
        });
    }

    render() {
        const sorterStyle = {fontSize: '35px'};
        const {i18n} = this.props;

        return (
            <React.Fragment>
                <a
                    role="button"
                    onClick={this.openModal}
                    title={i18n.t('Editar informações dos autores do documento')}
                >
                    <span className="mdi mdi-accounts"/> <span>{i18n.t('Autores')}</span>
                </a>

                <Modal title={i18n.t('Autores')}
                       show={this.state.showModal}
                       width="large"
                       isProcessing={this.state.processing}
                       onCancel={this.closeModal}>

                    {!this.state.showForm &&
                    <Button type="primary"
                            size="md"
                            onClick={() => this.openForm()}
                            className="pull-right">
                        {i18n.t('Adicionar')}
                    </Button>}

                    {!this.state.showForm &&
                    <table className="table table-hover" style={{marginTop: '10px'}}>
                        <thead><tr><th colSpan="6"/></tr></thead>
                        <tbody>
                        {this.state.authors.map((a, idx) => {
                            const affiliation = a.affiliation || {};
                            return (
                                <tr key={a.id}>
                                    <td role="button"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => this.openForm(a)}>
                                        {a.name}
                                        <br/>
                                        <i>{a.email}</i>
                                    </td>
                                    <td role="button"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => this.openForm(a)}>
                                        {affiliation.department} <span>&mdash;</span> {affiliation.institution}
                                        <br/>
                                        <i>{affiliation.address}</i>
                                    </td>
                                    <td role="button"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => this.openForm(a)}>
                                        {a.adviser? <i className="icon mdi mdi-face" title={i18n.t('Orientador')}/> : ''}
                                        &nbsp;
                                        {a.correspondent? <i className="icon mdi mdi-email" title={i18n.t('Correspondente')}/> : ''}
                                    </td>
                                    <td width="1%" nowrap="nowrap">
                                        {idx > 0 &&
                                        <a role="button"
                                           title={i18n.t('Alterar ordem')}
                                           onClick={() => this.up(a)}>
                                            <span style={sorterStyle}
                                                  className="icon mdi mdi-caret-up"/>
                                        </a>}
                                        {idx < this.state.authors.length -1 &&
                                        <a role="button"
                                           title={i18n.t('Alterar ordem')}
                                           onClick={() => this.down(a)}>
                                            <span style={sorterStyle}
                                                  className="icon mdi mdi-caret-down"/>
                                        </a>}
                                    </td>
                                    <td width="1%" nowrap="nowrap">
                                        {/*<a role="button"*/}
                                        {/*   className="btn btn-link btn-md"*/}
                                        {/*   onClick={() => this.openForm(a)}>*/}
                                        {/*    <span className="icon mdi mdi-edit"/>*/}
                                        {/*</a>*/}
                                        <a role="button"
                                           className="btn btn-link btn-md"
                                           onClick={() => this.removeAuthor(a)}>
                                            <span className="icon mdi mdi-delete"/>
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>}

                    {this.state.showForm &&
                    <AuthorForm document={this.props.document}
                                editor={this.props.editor}
                                author={this.state.selectedAuthor}
                                onCancel={this.closeForm}
                                onSave={this.closeForm}/>}

                </Modal>
            </React.Fragment>
        );
    }
}

Author.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Author);
