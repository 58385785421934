import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/containers/Modal';
import Button from '../../common/components/Button';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Events from './Events';
import { withNamespaces } from 'react-i18next';
import URLS from '../../urls';
import storage from '../../common/storage';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import TemplateMini from '../../templates/components/TemplateMini';
import Waiting from '../../common/containers/Waiting';
import SearchInput from '../../common/components/SearchInput';
import PaginationWithClick from '../../common/components/PaginationWithClick';
import TemplateRequest from '../../templates/components/TemplateRequest';
import { AlertError } from '../../common/components/Alert';

class ChangeTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      processing: false,
      errorMessage: null,
      templateSearchKey: new Date().getTime(),
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changeTemplate = this.changeTemplate.bind(this);
    this.createPresentation = this.createPresentation.bind(this);
    this.templateChangedKey = 'ff-template-changed';
  }

  componentDidMount() {
    this.compileIfTemplateChanged();
  }

  compileIfTemplateChanged() {
    const templateChanged = storage.getItem(this.templateChangedKey);
    if (templateChanged) {
      this.props.editor.fire(Events.COMPILE);
      storage.removeItem(this.templateChangedKey);
    }
  }

  openModal() {
    this.setState((state) => ({
      ...state,
      showModal: true,
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      processing: false,
    }));
  }

  changeTemplate(template) {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const { id } = this.props.document;
    const url = `${Config.apiHost}documents/${id}/change_template/`;
    Ajax.patch(url, { template: template.id })
      .done(() => {
        storage.setItem(this.templateChangedKey, '1');
        document.location = `${URLS.documentEdit(id)}?aclk=1`;
        this.closeModal();
      })
      .fail((response) => {
        this.setState((state) => ({
          ...state,
          errorMessage: response.responseJSON.detail,
          processing: false,
          templateSearchKey: new Date().getTime(),
        }));
      });
  }

  createPresentation(template) {
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const { id } = this.props.document;
    const url = `${Config.apiHost}documents/${id}/create_presentation/`;
    Ajax.post(url, { template: template.id })
      .done((response) => {
        document.location = URLS.documentEdit(response.copy_id);
      })
      .fail((response) => {
        this.setState((state) => ({
          ...state,
          errorMessage: response.responseJSON.detail,
          processing: false,
          templateSearchKey: new Date().getTime(),
        }));
      });
  }

  render() {
    const { i18n, isCreatePresentation } = this.props;

    const menuItem = isCreatePresentation ? (
      <a role="button" onClick={this.openModal}>
        <i className="icon mdi mdi-slideshow" />
        <span>
          {i18n.t('Criar apresentação de slides')} <span className="label label-success">{i18n.t('novo')}</span>
        </span>
      </a>
    ) : (
      <a role="button" onClick={this.openModal}>
        <i className="icon mdi mdi-refresh" />
        <span>{i18n.t('Alterar modelo de formatação')}</span>
      </a>
    );

    return (
      <>
        {menuItem}

        <Modal
          title={
            isCreatePresentation
              ? i18n.t('Selecione um modelo para a apresentação')
              : i18n.t('Selecione um modelo para o documento')
          }
          subtitle={
            isCreatePresentation ? '' : `(${i18n.t('Modelo atual')}: ${this.props.document.template_meta.name})`
          }
          show={this.state.showModal}
          onCancel={this.closeModal}
          isProcessing={this.state.processing}
          width="full"
          footer={
            <Button size="lg" type="primary" onClick={this.closeModal}>
              {i18n.t('Fechar')}
            </Button>
          }
          bodyMinHeight="calc(70vh)"
        >
          {this.state.showModal && (
            <>
              {this.state.errorMessage && (
                <AlertError>
                  <p>{this.state.errorMessage}</p>
                </AlertError>
              )}
              <TemplateList
                key={this.state.templateSearchKey}
                onTemplateClick={isCreatePresentation ? this.createPresentation : this.changeTemplate}
                document={this.props.document}
                isCreatePresentation={isCreatePresentation}
              />
            </>
          )}
        </Modal>
      </>
    );
  }
}

ChangeTemplate.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  isCreatePresentation: PropTypes.bool,
};

export default withNamespaces()(ChangeTemplate);

const TemplateList = withNamespaces()(
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        processing: false,
        templates: [],
        template: null,
        pagination: {},
      };

      this.fetchTemplates = this.fetchTemplates.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.fetchTemplateByVenue = this.fetchTemplateByVenue.bind(this);
    }

    componentDidMount() {
      this.fetchTemplates();
    }

    fetchTemplateByVenue(venue) {
      const url = `${Config.apiHost}templates/?venue=${venue}`;
      this.fetchTemplates(url);
    }

    fetchTemplates(url) {
      if (!url) {
        url = `${Config.apiHost}templates/?`;
      }

      if (this.props.isCreatePresentation || this.props.document.is_presentation) {
        url = `${url}&template_type=presentation`;
      } else {
        url = `${url}&template_type=document`;
      }

      this.setState((state) => ({
        ...state,
        processing: true,
      }));

      Ajax.get(url).done((pagination) => {
        this.setState((state) => ({
          ...state,
          processing: false,
          pagination: pagination,
          templates: pagination.results,
          template: null,
          searchTerm: null,
        }));
        window.scrollTo(0, 0);
      });
    }

    handleSearch(searchTerm) {
      const url = `${Config.apiHost}templates/?search=${searchTerm}`;
      this.fetchTemplates(url);
    }

    renderTemplateItems() {
      const results = this.state.templates;
      let subArrays = [];

      while (results.length > 0) {
        subArrays.push(results.splice(0, 4));
      }

      const onClick = this.props.onTemplateClick;

      return subArrays.map(function (templates, idx) {
        return (
          <Row key={idx}>
            {templates.map(function (template, idx) {
              return (
                <Col sm={3} md={3} key={idx}>
                  <TemplateMini key={template.id} template={template} onClick={onClick} />
                </Col>
              );
            })}
          </Row>
        );
      });
    }

    render() {
      const { i18n, isCreatePresentation } = this.props;
      const { processing } = this.state;

      return (
        <Waiting isProcessing={processing}>
          <Row>
            <Col md={8}>
              {!isCreatePresentation && !this.props.document.is_presentation && (
                <>
                  <Button type="default" size="lg" onClick={() => this.fetchTemplateByVenue('generic')}>
                    {i18n.t('Genérico')}
                  </Button>{' '}
                  <Button type="default" size="lg" onClick={() => this.fetchTemplateByVenue('university')}>
                    {i18n.t('Universidades')}
                  </Button>{' '}
                  <Button type="default" size="lg" onClick={() => this.fetchTemplateByVenue('journal')}>
                    {i18n.t('Periódicos')}
                  </Button>{' '}
                  <Button type="default" size="lg" onClick={() => this.fetchTemplateByVenue('conference')}>
                    {i18n.t('Conferências')}
                  </Button>{' '}
                  <Button type="default" size="lg" onClick={() => this.fetchTemplateByVenue('other')}>
                    {i18n.t('Outros')}
                  </Button>{' '}
                </>
              )}

              {(isCreatePresentation || this.props.document.is_presentation) && (
                <Button type="default" size="lg" onClick={() => this.fetchTemplateByVenue('presentation')}>
                  {i18n.t('Slides')} <span className="label label-success">{i18n.t('novo')}</span>
                </Button>
              )}
            </Col>
            <Col md={4}>
              <SearchInput handleSearch={this.handleSearch} placeholder={i18n.t('Experimente "USP"')} />
            </Col>
          </Row>

          <br />

          {this.state.templates && this.state.templates.length > 0 && (
            <React.Fragment>
              <Row>
                <Col md={12}>{this.renderTemplateItems()}</Col>
              </Row>

              <Row>
                <Col md={12}>
                  <PaginationWithClick handleClick={this.fetchTemplates} pagination={this.state.pagination} />
                </Col>
              </Row>
            </React.Fragment>
          )}

          <Row>
            <Col md={12}>
              <h4>
                <TemplateRequest />
              </h4>
            </Col>
          </Row>
        </Waiting>
      );
    }
  },
);

TemplateList.propTypes = {
  /**
   * Function to execute after clicking on a single template. If provided,
   * all router Links will be disabled, so this component can be reused
   * without navigating to other pages.
   *
   * Receives the template object as argument.
   */
  onTemplateClick: PropTypes.func,
  document: PropTypes.object,
  isCreatePresentation: PropTypes.bool,
};
