import TUIImageEditor from '@toast-ui/react-image-editor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import 'tui-image-editor/dist/tui-image-editor.css';
import Ajax from '../../../common/ajax';
import ModalAlert from '../../../common/components/ModalAlert';
import Modal from '../../../common/containers/Modal';
import Waiting from '../../../common/containers/Waiting';
import Config from '../../../config';
import './imageEditor.css';
import a4paper from '../../../common/a4paper.png';

class ImageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      processing: true,
      errorMessage: null,
      showConfirmOverride: false,
      enableSave: false,
    };
    this.saveImage = this.saveImage.bind(this);
    this.configureToastEditor = this.configureToastEditor.bind(this);
    this.setStateIfMounted = this.setStateIfMounted.bind(this);
    this.editorRef = React.createRef();
    this.divRef = React.createRef();
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.show && this.state.show) {
      this.configureToastEditor();
    }
  }

  setStateIfMounted(state, callback) {
    if (this._mounted) {
      this.setState(state, callback);
    }
  }

  configureToastEditor() {
    const { current: currentEditor } = this.editorRef;
    const { image, i18n } = this.props;

    if (!currentEditor) {
      setTimeout(() => this.configureToastEditor(), 100);
      return;
    }

    const rootElement = currentEditor.getRootElement();
    const editorInstance = currentEditor.getInstance();

    const header = rootElement.querySelector('.tui-image-editor-header');
    if (header) {
      header.remove();
    }

    rootElement
      .querySelector('.tui-image-editor-wrap')
      .classList.add('ff-scrollbar');

    this.divRef.current.style.display = 'block';

    editorInstance.on('undoStackChanged', (length) => {
      this.setStateIfMounted((s) => ({ ...s, enableSave: length > 0 }));
    });

    /**
     * Carrega imagem como data url (base64) para evitar problema de
     * autenticacao. Pois o TOAST UI Editor não envia os cookies no
     * método loadImageFromURL.
     */
    const url = `${Config.apiHost}document-media/${image.id}/data_url/`;
    Ajax.get(url)
      .then((dataUrl) => {
        editorInstance.loadImageFromURL(dataUrl, image.filename).then(() => {
          this.setStateIfMounted((s) => ({ ...s, processing: false }));
        });
      })
      .fail((jqXHR) => {
        this.setStateIfMounted((state) => ({
          ...state,
          processing: false,
          errorMessage: jqXHR.responseJSON
            ? jqXHR.responseJSON.detail
            : i18n.t('Erro inesperado'),
        }));
      });
  }

  saveImage(asNewImage = false) {
    const { i18n, image, onImageCreated, onImageSaved } = this.props;
    this.setStateIfMounted((state) => ({
      ...state,
      processing: true,
      errorMessage: null,
      showConfirmOverride: false,
    }));
    const formData = new FormData();
    formData.append('image', this.getDataURLAsBlob(), image.filename);
    const url = `${Config.apiHost}document-media/${image.id}/save_edited_image/?new=${asNewImage}`;
    Ajax.postFormData(url, formData)
      .done(() => {
        if (asNewImage) {
          onImageCreated && onImageCreated();
        } else {
          onImageSaved && onImageSaved();
        }
        this.setStateIfMounted((s) => ({ ...s, show: false }));
      })
      .fail((jqXHR) => {
        this.setStateIfMounted((state) => ({
          ...state,
          errorMessage: jqXHR.responseJSON
            ? jqXHR.responseJSON.detail
            : i18n.t('Erro inesperado'),
        }));
      })
      .always(() => {
        this.setStateIfMounted((s) => ({ ...s, processing: false }));
      });
  }

  getDataURLAsBlob() {
    const tuiEditor = this.editorRef.current.getInstance();
    const { media_type: format } = this.props.image;
    const dataurl = tuiEditor.toDataURL({ format });
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  render() {
    const { i18n, image } = this.props;
    const extension = image.media_type.split('/')[1];

    if (['jpeg', 'png'].indexOf(extension) === -1) {
      return null;
    }

    return (
      <>
        <button
          className="btn btn-default"
          onClick={() => this.setStateIfMounted((s) => ({ ...s, show: true }))}
        >
          <span className="icon mdi mdi-image-o" /> {i18n.t('Editar')} (
          <span className="text-success">{i18n.t('novo')}</span>)
        </button>
        <Modal
          show={this.state.show}
          width="full"
          fullScreen={true}
          title={i18n.t('Editor de imagem')}
          onCancel={() =>
            this.setStateIfMounted((s) => ({ ...s, show: false }))
          }
          style={{ zIndex: 10030 }}
          bodyStyle={{ padding: '0px' }}
          footer={
            <>
              <button
                className="btn btn-default"
                onClick={() =>
                  this.setStateIfMounted((s) => ({ ...s, show: false }))
                }
              >
                {i18n.t('Cancelar')}
              </button>
              <button
                className="btn btn-default"
                onClick={() => this.saveImage(true)}
                disabled={!this.state.enableSave}
              >
                {i18n.t('Salvar como nova imagem')}
              </button>
              <button
                className="btn btn-default"
                onClick={() =>
                  this.setStateIfMounted((state) => ({
                    ...state,
                    showConfirmOverride: true,
                  }))
                }
                disabled={!this.state.enableSave}
              >
                {i18n.t('Salvar')}
              </button>
            </>
          }
        >
          <Waiting isProcessing={this.state.processing}>
            <div ref={this.divRef} style={{ display: 'none' }}>
              <TUIImageEditor
                ref={this.editorRef}
                includeUI={{
                  loadImage: {
                    path: a4paper,
                    name: 'a4paper',
                  },
                  theme: whiteTheme,
                  menu: [
                    'resize',
                    'crop',
                    'flip',
                    'rotate',
                    'draw',
                    'shape',
                    'icon',
                    'text',
                    'mask',
                    'filter',
                  ],
                  initMenu: null,
                  uiSize: {
                    width: '100%',
                    height: 'calc(83vh)',
                  },
                  menuBarPosition: 'left',
                }}
                // cssMaxHeight={500}
                // cssMaxWidth={700}
                selectionStyle={{
                  cornerSize: 20,
                  rotatingPointOffset: 70,
                }}
                usageStatistics={false}
              />
            </div>
          </Waiting>
        </Modal>

        {this.state.errorMessage && (
          <ModalAlert
            type="danger"
            show={true}
            title={i18n.t('Erro ao salvar imagem')}
            onCancel={() =>
              this.setStateIfMounted((state) => ({
                ...state,
                errorMessage: null,
              }))
            }
            style={{ zIndex: 10040 }}
          >
            <p>{this.state.errorMessage}</p>
          </ModalAlert>
        )}

        {this.state.showConfirmOverride && (
          <ModalAlert
            show={true}
            type="info"
            style={{ zIndex: 10040 }}
            footer={
              <>
                <button
                  className="btn btn-default btn-lg"
                  onClick={() =>
                    this.setStateIfMounted((state) => ({
                      ...state,
                      showConfirmOverride: false,
                    }))
                  }
                >
                  {i18n.t('Cancelar')}
                </button>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => this.saveImage(false)}
                >
                  {i18n.t('Continuar')}
                </button>
              </>
            }
          >
            <h4>
              {i18n.t(
                'Essa operação irá sobrescrever a imagem original. Deseja continuar?'
              )}
              <br />
              {i18n.t(
                'Para não sobrescrever a imagem original, utilize a opção "Salvar como nova imagem".'
              )}
            </h4>
          </ModalAlert>
        )}
      </>
    );
  }
}

ImageEditor.propTypes = {
  image: PropTypes.object.isRequired,
  onImageSaved: PropTypes.func,
  onImageCreated: PropTypes.func,
};

ImageEditor = withNamespaces()(ImageEditor);

export default ImageEditor;

const whiteTheme = {
  'common.bi.image':
    'https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png',
  'common.bisize.width': '251px',
  'common.bisize.height': '21px',
  'common.backgroundImage': '',
  'common.backgroundColor': '#fff',
  'common.border': '',

  // header
  'header.backgroundImage': 'none',
  'header.backgroundColor': 'transparent',
  'header.border': '0px',

  // load button
  'loadButton.backgroundColor': '#fff',
  'loadButton.border': '1px solid #ddd',
  'loadButton.color': '#222',
  'loadButton.fontFamily': "'Noto Sans', sans-serif",
  'loadButton.fontSize': '12px',

  // download button
  'downloadButton.backgroundColor': '#fdba3b',
  'downloadButton.border': '1px solid #fdba3b',
  'downloadButton.color': '#fff',
  'downloadButton.fontFamily': "'Noto Sans', sans-serif",
  'downloadButton.fontSize': '12px',

  // main icons
  'menu.normalIcon.color': '#8a8a8a',
  'menu.activeIcon.color': '#555555',
  'menu.disabledIcon.color': '#434343',
  'menu.hoverIcon.color': 'black',
  'menu.iconSize.width': '24px',
  'menu.iconSize.height': '24px',

  // submenu icons
  'submenu.normalIcon.color': '#8a8a8a',
  'submenu.activeIcon.color': '#555555',
  'submenu.iconSize.width': '32px',
  'submenu.iconSize.height': '32px',

  // submenu primary color
  'submenu.backgroundColor': 'transparent',
  'submenu.partition.color': '#e5e5e5',

  // submenu labels
  'submenu.normalLabel.color': '#858585',
  'submenu.normalLabel.fontWeight': 'normal',
  'submenu.activeLabel.color': '#000',
  'submenu.activeLabel.fontWeight': 'normal',

  // checkbox style
  'checkbox.border': '1px solid #ccc',
  'checkbox.backgroundColor': '#fff',

  // rango style
  'range.pointer.color': '#333',
  'range.bar.color': '#ccc',
  'range.subbar.color': '#606060',

  'range.disabledPointer.color': '#d3d3d3',
  'range.disabledBar.color': 'rgba(85,85,85,0.06)',
  'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

  'range.value.color': '#000',
  'range.value.fontWeight': 'normal',
  'range.value.fontSize': '11px',
  'range.value.border': '0',
  'range.value.backgroundColor': '#f5f5f5',
  'range.title.color': '#000',
  'range.title.fontWeight': 'lighter',

  // colorpicker style
  'colorpicker.button.border': '0px',
  'colorpicker.title.color': '#000',
};
