import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { AlertSuccess } from '../../common/components/Alert';
import Button from '../../common/components/Button';
import Upload from '../../common/components/upload/Upload';
import Modal from '../../common/containers/Modal';
import Config from '../../config';
import Events from './Events';
import Checkbox from '../../common/components/form/Checkbox';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';

class ImportFromWordButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showConfirmationModal: false,
      agreed: false,
      processing: false,
      successMessage: null,
      uploadUniqueKey: null,
      insertMode: 'replace',
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onInsertModeChange = this.onInsertModeChange.bind(this);
  }

  openModal() {
    console.log(this.state);
    this.setState((state) => ({
      ...state,
      showModal: true,
      showConfirmationModal: false,
      agreed: false,
      uploadUniqueKey: new Date().getMilliseconds(),
    }));
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
      successMessage: null,
    }));
  }

  onSuccess(html) {
    const { i18n } = this.props;

    const msg = i18n.t('O conteúdo atual será sobrescrito pelo conteúdo do arquivo Word. Deseja continuar?');
    if (!confirm(msg)) {
      this.closeModal();
      return;
    }
    this.props.editor.setData(html);

    this.setState((state) => ({
      ...state,
      successMessage: i18n.t('Documento importado com sucesso. O conteúdo foi inserido no editor.'),
    }));

    this.props.editor.fire(Events.SAVE_AND_COMPILE);
  }

  onInsertModeChange(e) {
    e.persist();
    this.setState((s) => ({ ...s, insertMode: e.target.value }));
  }

  render() {
    if (this.props.document && this.props.document.is_presentation) {
      return null;
    }

    if (!this.props.forceShow && this.props.document.days_since_creation > 1) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <>
        <a role="button" onClick={() => this.setState((state) => ({ ...state, showConfirmationModal: true }))}>
          <i className="icon mdi mdi-upload" />
          <span className="hidden-md hidden-sm hidden-xs">
            &nbsp;
            {i18n.t('Importar Word')}
          </span>
        </a>

        <Modal
          title={i18n.t('Avisos e orientações importantes')}
          show={this.state.showConfirmationModal}
          onCancel={() => this.setState((state) => ({ ...state, showConfirmationModal: false }))}
          headerFullColor="warning"
          width="large"
          footer={
            <Row>
              <Col md={8}>
                <Checkbox
                  id="agreed"
                  name="agreed"
                  checked={this.state.agreed}
                  label={<b>Estou ciente das limitações e orientações apresentadas.</b>}
                  onChange={() => this.setState((state) => ({ ...state, agreed: !state.agreed }))}
                  asFormGroup={false}
                />
              </Col>
              <Col md={4}>
                <Button
                  type="default"
                  size="lg"
                  onClick={() => this.setState((state) => ({ ...state, showConfirmationModal: false, agreed: false }))}
                >
                  Fechar
                </Button>
                <Button type="primary" size="lg" onClick={this.openModal} disabled={!this.state.agreed}>
                  Continuar
                </Button>
              </Col>
            </Row>
          }
        >
          <div style={{ fontSize: '11pt', textAlign: 'justify' }}>
            <p>Antes de continuar, é importante saber que a importação de arquivo Word possui limitações, a seguir.</p>

            <p>
              A importação depende de vários fatores, inclusive de como seu documento Word está formatado. Portanto, não
              há garantia de como o texto ficará estruturado no editor.
            </p>

            <p>
              Informações pré-textuais não são importadas de forma estruturada. Assim, recomenda-se remover as páginas
              de Capa, Folha de Rosto, Listas, Sumário e Abstract/Resumo antes de enviar o arquivo. Esses elementos são
              gerados automaticamente pelo FastFormat.
            </p>

            <p>
              Fórmulas não são suportadas na importação; utilize o editor do FastFormat para inserí-las manualmente.
              Imagens com mais de 10Mb não serão importadas.
            </p>

            <p>
              {i18n.t(
                'Após a importação, você precisa fazer alguns ajustes para compatibilizar seu documento no FastFormat',
              )}
              :
            </p>

            <ul>
              <li>
                {i18n.t(
                  'Copiar o título e os autores para os campos específicos, na opção de menu "Capa/Folha de rosto"',
                )}
                ;
              </li>
              <li>
                {i18n.t(
                  'Copiar os textos do abtract/resumo nos campos próprios, na opções de menu "Resumo"/"Abstract"',
                )}
                ;
              </li>
              <li>{i18n.t('Cadastrar/importar a bibliografia na sua conta do FastFormat')};</li>
              <li>
                {i18n.t(
                  'Utilizar o mecanismo de citação automática do FastFormat para que as referências sejam formatadas automaticamente.',
                )}
              </li>
            </ul>

            <p>
              Se tiver dúvidas, assista aos vídeos tutoriais no menu de Ajuda <i className="icon mdi mdi-help" />
            </p>
          </div>
        </Modal>

        <Modal
          title={i18n.t('Importar documento do Word')}
          show={this.state.showModal}
          onCancel={this.closeModal}
          isProcessing={this.state.processing}
          footer={
            <Button type="primary" size="lg" onClick={this.closeModal}>
              Fechar
            </Button>
          }
        >
          <AlertSuccess>{this.state.successMessage}</AlertSuccess>

          {!this.state.successMessage && (
            <>
              <Upload
                key={this.state.uploadUniqueKey}
                url={`${Config.apiHost}documents/${this.props.document.id}/import_word/`}
                onStart={() => this.setState((state) => ({ ...state, processing: true }))}
                onAllFinished={() => this.setState((state) => ({ ...state, processing: false }))}
                onSuccess={this.onSuccess}
                accept={['.doc', '.docx']}
              />
            </>
          )}
        </Modal>
      </>
    );
  }
}

ImportFromWordButton.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  forceShow: PropTypes.bool,
};

export default withNamespaces()(ImportFromWordButton);
