import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CrossrefBase from "./CrossrefBase";
import {withNamespaces} from "react-i18next";
import HtmlUtils from "../../../common/htmlutils";
import MathJaxPreview from "../../../common/components/MathJaxPreview";

class CrossrefFormula extends Component {
    constructor(props) {
        super(props);
        this.getFormulaReferences = this.getFormulaReferences.bind(this);
        this.insertCrossref = this.insertCrossref.bind(this);
    }

    getFormulaReferences(content) {
        const $content = $(`<div>${content}</div>`);
        const references = [];
        const $items = $('span.math-tex[numbering][reference]', $content);
        if ($items.length > 0) {
            $items.each(function (i, el) {
                console.log(el);

                const formulaText = el.innerText.trim();

                if (!formulaText) {
                    return;
                }

                const reference = el.getAttribute('reference');

                references.push({
                    reference,
                    caption: <MathJaxPreview math={HtmlUtils.stripHtmlTags(formulaText)}/>,
                });
            });
        }
        return references;
    }

    insertCrossref(reference) {
        const el = [
            `<span contenteditable="false" class="formula-reference" title="${reference.caption}">`,
            `<ff-formula-reference target-reference="${reference.reference}">{for:${reference.reference}}</ff-formula-reference>`,
            `</span>`
        ].join('');
        this.props.editor.insertHtml(el);
    }

    render() {
        const {i18n} = this.props;
        return (
            <CrossrefBase
                document={this.props.document}
                editor={this.props.editor}
                currentAttribute={this.props.currentAttribute}
                title={i18n.t('Selecione uma fórmula para referenciar')}
                menuName={i18n.t('Inserir referências de fórmulas')}
                commandName="crossRefFormulaCommand"
                icon="header"
                getReferences={this.getFormulaReferences}
                insertCrossref={this.insertCrossref}
            />
        );
    }
}

CrossrefFormula.propTypes = {
    document: PropTypes.object.isRequired,
    editor: PropTypes.object.isRequired,
    currentAttribute: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
};

export default withNamespaces()(CrossrefFormula);
